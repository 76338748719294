/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from 'react';
import { IMember } from '../types/IMember';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';
import { context as AuthContext } from './AuthProvider';
import {
  financialPermissions,
  opportunityPermissions,
  timesheetPermissions,
} from '../enums/permissions';

type CurrentUserState = {
  currentUser?: IMember | null;
  getUserAccess?:
    | ((permissionAll: string, permissionMe: string) => IUserAccess)
    | null;
  canViewOpportunity?: boolean;
  canViewTimesheet?: boolean;
};

export interface IUserAccess {
  userId?: number;
  isPermitted?: boolean;
  verifyOwner?: boolean;
}

const CurrentUserContext = React.createContext<CurrentUserState>({});

const useCurrentUser = () => useContext(CurrentUserContext);

const CurrentUserContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { authenticationState, accountInfo } = useContext(AuthContext);
  const { currentUser, refreshUser } = useGetCurrentUser();

  const getUserAccess = (
    permissionAll: string,
    permissionMe: string
  ): IUserAccess => {
    const all = currentUser?.access_rules?.some(
      (r) => r.role === permissionAll
    );
    const me = currentUser?.access_rules?.some((r) => r.role === permissionMe);
    return {
      userId: currentUser?.id,
      isPermitted: all || me,
      verifyOwner: me && !all,
    };
  };

  const canViewOpportunity = useMemo(() => {
    const access = getUserAccess!(
      opportunityPermissions.editAll,
      opportunityPermissions.editMe
    );
    return access.isPermitted;
  }, [currentUser]);

  const canViewTimesheet = useMemo(() => {
    const access = getUserAccess!(
      timesheetPermissions.editAll,
      timesheetPermissions.editMe
    );
    return access.isPermitted;
  }, [currentUser]);

  useEffect(() => {
    if (!authenticationState) return;
    refreshUser();
  }, [authenticationState, accountInfo]);

  const defaultValue = {
    currentUser,
    getUserAccess,
    canViewOpportunity,
    canViewTimesheet,
  };

  return (
    <CurrentUserContext.Provider value={defaultValue}>
      {currentUser && children}
    </CurrentUserContext.Provider>
  );
};

export { CurrentUserContext, CurrentUserContextProvider, useCurrentUser };
