import React, { useState, useContext } from 'react';
import { IBreadcrumbItem } from '@fluentui/react';

type BreadcrumbsState = {
  breadcrumbs: IBreadcrumbItem[];
  setBreadcrumbs: (items: IBreadcrumbItem[]) => void;
};

const BreadcrumbsContext = React.createContext<BreadcrumbsState>({
  breadcrumbs: [],
  setBreadcrumbs: (_: IBreadcrumbItem[]) => {},
});

const BreadcrumbsContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [currentBreadcumbs, setCurrentBreadcumbs] = useState<IBreadcrumbItem[]>(
    []
  );

  const setBreadcrumbs = (items: IBreadcrumbItem[] = []) => {
    setCurrentBreadcumbs(items);
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs: currentBreadcumbs,
        setBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export { BreadcrumbsContext, BreadcrumbsContextProvider, useBreadcrumbs };
