import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SKILLS_KEYS, SKILL_RATINGS_KEYS } from '../constants/queries';
import { getSkillRatings } from '../resources/getSkillRatings';
import { getSkillRating } from '../resources/getSkillRating';
import { saveSkillRating } from '../resources/saveSkillRating';
import { IMutateOption, IQueryResult } from '../types/IQueryResult';
import { ISaveSkillRating, ISkillRating } from 'types/ISkillRating';

export type ISaveSkillRatingsResult = IQueryResult & {
  saveSkillRatings: (payload: ISaveSkillRating[]) => void;
};

export function useSkillRatings(): IQueryResult & {
  skillRatings: ISkillRating[];
} {
  const {
    data: skillRatings,
    isLoading,
    error,
  } = useQuery(SKILL_RATINGS_KEYS.getAll(), getSkillRatings, {});

  return { skillRatings, isLoading, error };
}

export function useSkillRatingSingle(id: number): IQueryResult & {
  skillRating: any;
} {
  const {
    data: skillRating,
    isLoading,
    error,
  } = useQuery(
    SKILL_RATINGS_KEYS.getSingle(id?.toString()),
    () => getSkillRating(id.toString()!),
    {
      enabled: !!id,
    }
  );
  return { skillRating, isLoading, error };
}

export function useSaveSkillRatings(
  opts?: IMutateOption<ISaveSkillRating[]>
): ISaveSkillRatingsResult {
  const queryClient = useQueryClient();
  const handler = async (payload: ISaveSkillRating[]) => {
    const saveRatings = await Promise.all(payload.map(saveSkillRating));
    if (saveRatings.every((r) => Boolean(r))) {
      return saveRatings;
    }
    throw new Error('Something went wrong');
  };
  const { mutate, isLoading, error } = useMutation(handler, {
    onSuccess: (data: ISkillRating[]) => {
      queryClient.invalidateQueries(SKILL_RATINGS_KEYS.getAll());
      queryClient.invalidateQueries(SKILLS_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });
  return { saveSkillRatings: mutate, isLoading, error };
}
