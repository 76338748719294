import React from 'react';
import { useTeamsFx } from '../hooks/useTeamsFx';

type AppState = {
  isInTeams: boolean;
  teamsTheme: string;
};

const AppContext = React.createContext<AppState>({
  isInTeams: false,
  teamsTheme: 'default',
});

const AppContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isInTeams, error, loading, themeString } = useTeamsFx();

  if (error || loading || isInTeams == null) return null;

  return (
    <AppContext.Provider value={{ isInTeams, teamsTheme: themeString }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
