import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CommandButton,
  Stack,
  Dropdown,
  TextField,
  MessageBar,
  MessageBarType,
  ComboBox,
} from '@fluentui/react';

import { customAlphabet } from 'nanoid';

import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';
import { jobStatusOptions } from '../../enums/jobStatusOptions';
import { businessUnitDropdownOptions } from '../../enums/businessUnit';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useClientSingle } from '../../hooks/useClients';
import {
  useCreateJob,
  useDeleteJob,
  useJobSingle,
  useUpdateJob,
} from '../../hooks/useJobs';
import { useMembersDropdownOptions } from '../../hooks/useMembers';

function generateReference() {
  const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);
  return nanoid();
}

export const ClientJobFormPage: React.FC = () => {
  const { clientId, jobId } = useParams<any>();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);
  const [formState, setFormState] = useState(
    job ?? { reference: generateReference() }
  );
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');
  const { update: updateJob, isLoading: isUpdating } = useUpdateJob({
    onError: () =>
      setGeneralError('An error occurred while trying to update the job.'),
    onSuccess: (data) => {
      history.push(`/clients/${clientId}/jobs/${data.id}`);
    },
  });
  const { create: createJob, isLoading: isCreating } = useCreateJob({
    onError: () =>
      setGeneralError('An error occurred while trying to create a new job.'),
    onSuccess: (data) => {
      history.push(`/clients/${clientId}/jobs/${data.id}`);
    },
  });
  const { delete: deleteJob, isLoading: isDeleting } = useDeleteJob({
    onError: () =>
      setGeneralError('An error occurred while trying to delete the job.'),
    onSuccess: () => history.push(`/clients/${clientId}`),
  });
  const memberOptions = useMembersDropdownOptions();

  const isSubmitting = useMemo(
    () => isUpdating || isCreating || isDeleting,
    [isCreating, isDeleting, isUpdating]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'clients',
        text: 'Clients',
        onClick: () => history.push('/clients'),
      },
      {
        key: 'client',
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      ...(job
        ? [
            {
              key: 'job',
              text: job.name,
              onClick: () =>
                history.push(`/clients/${client.id}/jobs/${job.id}`),
            },
            { key: 'edit', text: 'Edit Job' },
          ]
        : [{ key: 'new', text: 'New Job' }]),
    ]);
  }, [history, client, job]);

  const onSubmit = useCallback(async () => {
    const errors: any = {};
    if (!formState.name) {
      errors.name = 'Job Name is required.';
    }
    if (!formState.status) {
      errors.status = 'Status is required.';
    }
    if (!formState.project_lead_id) {
      errors.project_lead_id = 'Project Lead is required.';
    }
    if (!formState.account_manager_id) {
      errors.account_manager_id = 'Account Manager is required.';
    }
    if (!formState.project_manager_id) {
      errors.project_manager_id = 'Project Manager is required.';
    }

    if (!formState.business_unit) {
      errors.business_unit = 'Business Unit is required.';
    }

    if (!formState.reference) {
      errors.reference = 'Reference is required.';
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    if (!formState.id) {
      createJob({ clientId, payload: formState });
    } else {
      updateJob({ clientId, payload: formState });
    }
  }, [formState, clientId]);

  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      deleteJob(jobId);
    }
  }, [jobId]);

  return (
    <Page>
      <CommandBar>
        {job && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Undo' }}
              text="Discard Changes"
              onClick={() => history.push(`/clients/${clientId}/jobs/${jobId}`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Delete' }}
              text="Delete Job"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Update Job"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!job && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Cancel' }}
              text="Cancel"
              onClick={() => history.push(`/clients/${clientId}`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Create New Job"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>{job ? `Edit Job` : `Create New Job for ${client.name}`}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={4}>
                <TextField
                  autoFocus
                  label="Job Name"
                  value={formState.name}
                  onChange={(_: any, name: any) =>
                    setFormState((state) => ({ ...state, name }))
                  }
                  errorMessage={errors.name}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <Dropdown
                  label="Status"
                  styles={{ dropdown: { width: '100%', minWidth: '165px' } }}
                  selectedKey={formState.status + '' ?? '1'}
                  options={jobStatusOptions}
                  onChange={(_: any, value: any) =>
                    setFormState((state) => ({ ...state, status: value.key }))
                  }
                  errorMessage={errors.status}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={1}>
                <TextField
                  label="Reference"
                  value={formState.reference}
                  onChange={(_: any, name: any) =>
                    setFormState((state) => ({ ...state, reference: name }))
                  }
                  errorMessage={errors.reference}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <Dropdown
                  label="Business Unit"
                  styles={{ dropdown: { width: '100%', minWidth: '165px' } }}
                  selectedKey={formState.business_unit + '' ?? '1'}
                  options={businessUnitDropdownOptions}
                  onChange={(_: any, value: any) =>
                    setFormState((state) => ({
                      ...state,
                      business_unit: parseInt(value.key, 10),
                    }))
                  }
                  errorMessage={errors.status}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={1}>
                <ComboBox
                  label="Project Lead"
                  options={memberOptions}
                  selectedKey={formState.project_lead_id}
                  allowFreeform
                  autoComplete="on"
                  onChange={(_: any, value: any) => {
                    if (!value) return;
                    setFormState((state) => ({
                      ...state,
                      project_lead_id: parseInt(value.key, 10),
                    }));
                  }}
                  errorMessage={errors.project_lead_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <ComboBox
                  label="Account Manager"
                  options={memberOptions}
                  selectedKey={formState.account_manager_id}
                  allowFreeform
                  autoComplete="on"
                  onChange={(_: any, value: any) => {
                    if (!value) return;
                    setFormState((state) => ({
                      ...state,
                      account_manager_id: parseInt(value.key, 10),
                    }));
                  }}
                  errorMessage={errors.account_manager_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <ComboBox
                  label="Project Manager"
                  options={memberOptions}
                  selectedKey={formState.project_manager_id}
                  allowFreeform
                  autoComplete="on"
                  onChange={(_: any, value: any) => {
                    if (!value) return;
                    setFormState((state) => ({
                      ...state,
                      project_manager_id: parseInt(value.key, 10),
                    }));
                  }}
                  errorMessage={errors.project_manager_id}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientJobFormPage;
