import { IDAColumn } from 'types/IDADetailsList';

export const skillsFields: IDAColumn[] = [
  {
    key: 'level1',
    fieldName: 'level1',
    name: 'Level 1',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'level2',
    fieldName: 'level2',
    name: 'Level 2',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'level3',
    fieldName: 'level3',
    name: 'Level 3',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'skill',
    fieldName: 'skill',
    name: 'Skill',
    minWidth: 300,
    maxWidth: 350,
  },
  {
    key: 'timeline',
    fieldName: 'timeline',
    name: 'Timeline',
    minWidth: 100,
    maxWidth: 150,
  },
  {
    key: 'wiki_url',
    fieldName: 'wiki_url',
    name: 'Wiki URL',
    minWidth: 300,
    maxWidth: 800,
  },
];
