import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IOpportunityNew, IOpportunity } from '../types/IOpportunity';

export async function updateOpportunity(
  opportunity: IOpportunityNew
): Promise<IOpportunity> {
  const response = await fetch(
    `${apiEndpoint}/opportunities/${opportunity.id}`,
    {
      method: 'PUT',
      headers: await standardHeaders(),
      mode: 'cors',
      body: JSON.stringify(opportunity),
    }
  );
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
