import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  CommandButton,
  Pivot,
  PivotItem,
  SelectionMode,
  ComboBox,
  Stack,
  PrimaryButton,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { Page } from '../Page';
import { Paper } from '../Paper';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { roleMemberFields } from '../../enums/roleMembersFields';
import { useClientSingle } from '../../hooks/useClients';
import {
  useJobRole,
  useJobRoleMembers,
  useUpdateRoleMember,
} from '../../hooks/useJobs';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';

export const ClientJobRolePage: React.FC = () => {
  const { clientId, jobId, roleId } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { client } = useClientSingle(clientId);
  const { jobRole: role } = useJobRole(jobId, roleId);
  const { unassignedMembers: availableMembers } = useJobRoleMembers(
    jobId,
    roleId
  );
  const [selectedMember, setSelectedMember] = useState<any>();
  const [generalError, setGeneralError] = useState('');
  const { update: addRoleMember, isLoading: isAddingMember } =
    useUpdateRoleMember({
      jobId,
      roleId,
      onSuccess: (data) => setSelectedMember(data),
      onError: () =>
        setGeneralError(
          'An error occurred while trying to add the member to the job role.'
        ),
    });

  const [activeTab, setActiveTab] = useState('details');

  useEffect(() => {
    if (!role.id && role.job) {
      history.push(`/clients/${client.id}/jobs/${role.job.id}`);
    } else if (!role.job && client) {
      history.push(`/clients/${client.id}`);
    } else if (!role.job && !client) {
      history.push(`/clients`);
    }
  }, [role, client, history]);

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'clients',
        text: 'Clients',
        onClick: () => history.push('/clients'),
      },
      {
        key: 'client',
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      {
        key: 'job',
        text: role.job?.name ?? '',
        onClick: () =>
          history.push(`/clients/${client.id}/jobs/${role.job?.id}`),
      },
      { key: 'role', text: role.name ?? '' },
    ]);
  }, [history, client, role]);

  const addMember = useCallback(() => {
    addRoleMember({
      jobId,
      payload: {
        member_id: selectedMember,
        job_role_id: roleId,
        active: true,
      },
    });
  }, [selectedMember]);

  if (!role.id) {
    return null;
  }

  return (
    <Page>
      <CommandBar>
        <CommandButton
          iconProps={{ iconName: 'Edit' }}
          text="Edit Details"
          onClick={() => history.push(`${location.pathname}/edit`)}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>{role.name}</h2>
        </header>
        <div className="da-tabs">
          <Pivot
            headersOnly
            selectedKey={activeTab}
            onLinkClick={(item) => setActiveTab(item!.props.itemKey!)}
          >
            <PivotItem headerText="General" itemKey="details" />
            <PivotItem headerText="Members" itemKey="members" />
            {/* <PivotItem headerText='Budget' itemKey='budget' /> */}
          </Pivot>
        </div>
        {activeTab === 'details' && (
          <Paper>
            <ul className="da-unordered-list">
              <li>
                <h4>ID</h4>
                <p>{role.id}</p>
              </li>
              <li>
                <h4>Sell Rate</h4>
                <p>{role.sell_rate}</p>
              </li>
              <li>
                <h4>Active</h4>
                <p>{role.active + ''}</p>
              </li>
            </ul>
          </Paper>
        )}
        {activeTab === 'members' && (
          <div className="da-details-list-container">
            <DetailsListWithContextMenu
              items={role?.members ?? []}
              columns={roleMemberFields}
              selectionMode={SelectionMode.none}
            />
            <div className="da-form padded">
              {generalError && (
                <MessageBar
                  messageBarType={MessageBarType.error}
                  isMultiline={false}
                >
                  {generalError}
                </MessageBar>
              )}
              <Stack
                horizontal
                verticalAlign="end"
                tokens={{ childrenGap: 10 }}
              >
                <Stack.Item grow={7}>
                  <ComboBox
                    label={`Add Member to ${role.name}`}
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={availableMembers}
                    selectedKey={selectedMember}
                    onChange={(_: any, option: any) =>
                      option?.key && setSelectedMember(option.key)
                    }
                    disabled={isAddingMember}
                  />
                </Stack.Item>
                <Stack.Item grow={1}>
                  <PrimaryButton
                    iconProps={{ iconName: 'Add' }}
                    styles={{ root: { width: '100%' } }}
                    disabled={!selectedMember || isAddingMember}
                    onClick={addMember}
                  >
                    Add Member
                  </PrimaryButton>
                </Stack.Item>
              </Stack>
            </div>
          </div>
        )}
      </Container>
    </Page>
  );
};

export default ClientJobRolePage;
