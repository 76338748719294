import { ComponentType, useMemo } from 'react';
import { IUserAccess, useCurrentUser } from '../providers/CurrentUserProvider';
import NoAccess from '../components/NoAccess';

type IWithAccessOptions =
  | {
      permisionAll: string;
      permissionMe: string;
    }
  | string;

export default function withAccess<T>(
  opts: IWithAccessOptions,
  Component: ComponentType<T>
) {
  return (props: T) => {
    const { currentUser, getUserAccess } = useCurrentUser();
    const userAccess = useMemo<IUserAccess>(() => {
      if (!currentUser) return { isPermitted: false };
      if (typeof opts === 'string') {
        return {
          isPermitted: currentUser.access_rules?.some((r) => r.role === opts),
        };
      }
      return getUserAccess!(opts.permisionAll, opts.permissionMe);
    }, [currentUser, getUserAccess]);
    return (
      <>
        {userAccess &&
          (userAccess.isPermitted ? (
            <Component {...props} userAccess={userAccess} />
          ) : (
            <NoAccess />
          ))}
      </>
    );
  };
}
