import { IColumn } from '@fluentui/react';

export const jobsFields: IColumn[] = [
	{
		key: 'name',
		fieldName: 'name',
		name: 'Job Name',
		minWidth: 100,
		maxWidth: 300
	},
	{
		key: 'statusText',
		fieldName: 'statusText',
		name: 'Status',
		minWidth: 100,
    isSorted: true,
		isSortedDescending: true,
	}
];