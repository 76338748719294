import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getClientTimesheets(startDate: string, endDate: string, jobId: number) {

	const params = new URLSearchParams();
	params.set('start_date', startDate);
	params.set('end_date', endDate);
	params.set('job_id', jobId + '');

	const response = await fetch(`${apiEndpoint}/timesheet?${params.toString()}`, {
		method: 'GET',
		headers: await standardHeaders(),
		mode: 'cors'
	});
	if (response.ok) {
		return await response.json();
	}
	return [];
}