import { useMutation, useQuery, useQueryClient } from 'react-query';
import { BUDGETS_QUERY_KEYS } from '../constants/queries';
import { createJobBudget } from '../resources/createJobBudget';
import { getJobBudget, getJobBudgetByJobId } from '../resources/getJobBudget';
import { getJobBudgets } from '../resources/getJobBudgets';
import {
  updateJobBudgetOnly,
  updateJobBudget,
} from '../resources/updateJobBudget';
import { getJobBudgetsWithJobRoles } from '../resources/getJobBudgetsWithJobRoles';
import { getJobRoleBudgets } from '../resources/getJobRoleBudgets';
import { IJobBudget } from '../types/IJobBudget';
import { IJobBudgetOpportunity } from '../types/IJobBudgetOpportunity';
import { IJobRoleBudget } from '../types/IJobRoleBudget';
import {
  ICreateResult,
  IMutateOption,
  IQueryResult,
  IUpdateResult,
} from '../types/IQueryResult';

export function useJobRoleBudgets(
  jobId?: number,
  jobBudgetId?: number
): IQueryResult & { jobRoleBudgets: IJobRoleBudget[] } {
  const {
    data: jobRoleBudgets = [],
    isLoading,
    error,
  } = useQuery(
    BUDGETS_QUERY_KEYS.getJobRoleBudgets(
      jobId?.toString(),
      jobBudgetId?.toString()
    ),
    () => getJobRoleBudgets(jobId!.toString(), jobBudgetId!.toString()),
    {
      enabled: !!jobId && !!jobBudgetId,
      cacheTime: 0,
    }
  );
  return { jobRoleBudgets, isLoading, error };
}

export function useJobBudgetsWithJobRoles(
  jobId: string
): IQueryResult & { budgets: IJobBudgetOpportunity[] | undefined } {
  const {
    data: budgets,
    isLoading,
    error,
  } = useQuery(
    BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(jobId),
    () => getJobBudgetsWithJobRoles(jobId),
    {
      enabled: !!jobId,
    }
  );
  return { budgets, isLoading, error };
}

export function useJobBudgets(
  jobId: string
): IQueryResult & { budgets: IJobBudget[] | undefined } {
  useQuery([]);
  const {
    data: budgets,
    isLoading,
    error,
  } = useQuery(
    BUDGETS_QUERY_KEYS.getJobBudgets(jobId),
    () => getJobBudgets(jobId),
    {
      enabled: !!jobId,
    }
  );
  return { budgets, isLoading, error };
}

export function useJobBudgetSingle(
  jobId: string,
  budgetId: string
): IQueryResult & { budget: IJobBudget | undefined } {
  const {
    data: budget,
    isLoading,
    error,
  } = useQuery(
    BUDGETS_QUERY_KEYS.getJobBudgetSingle(jobId, budgetId),
    () => getJobBudget(jobId, budgetId),
    {
      enabled: !!jobId && !!budgetId,
    }
  );
  return { budget, isLoading, error };
}

export function useJobBudget(
  jobId: string
): IQueryResult & { budget: IJobBudget | undefined } {
  const {
    data: budget,
    isLoading,
    error,
  } = useQuery(
    BUDGETS_QUERY_KEYS.getJobBudget(jobId),
    () => getJobBudgetByJobId(jobId),
    {
      enabled: !!jobId,
    }
  );
  return { budget, isLoading, error };
}

export function useCreateJobBudget(
  opts: IMutateOption<IJobBudget>
): ICreateResult<{ jobId: string; payload: IJobBudget }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobId: string; payload: IJobBudget }) =>
      createJobBudget(data.jobId, data.payload),
    {
      onSuccess: (data: IJobBudget) => {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(data.job_id.toString())
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(data.job_id.toString())
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { create: mutate, isLoading, error };
}

export function useUpdateJobBudget(
  opts: IMutateOption<IJobBudget>
): IUpdateResult<IJobBudget> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: IJobBudget) => updateJobBudgetOnly(data),
    {
      onSuccess: (data: IJobBudget) => {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(data.job_id.toString())
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(data.job_id.toString())
        );
        BUDGETS_QUERY_KEYS.getJobBudgetSingle(
          data.job_id.toString(),
          data.id.toString()
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useUpdateJobBudgetWithRoles(
  opts?: IMutateOption<IJobBudget>
): IUpdateResult<IJobBudget> {
  const queryClient = useQueryClient();

  const { mutate, isLoading, error } = useMutation(
    (data: IJobBudget) => updateJobBudget(data),
    {
      onSuccess: (data: IJobBudget) => {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobRoleBudgets(
            data.job_id.toString(),
            data.id.toString()
          )
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudget(data.job_id.toString())
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}
