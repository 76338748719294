export const solutions: {[key: number]: string} = {
	1: 'Lakeflow',
	2: 'Data governance with Purview',
	3: 'MLOps',
	4: 'Method',
	5: 'Data Migration',
	6: 'Retail CDP',
	7: 'Council Solutions',
	8: 'Citizen on a page',
};

export const solutionsInitals: {[key: number]: string} = {
	1: 'LF',
	2: 'DG',
	3: 'ML',
	4: 'MD',
	5: 'DM',
	6: 'RC',
	7: 'CS',
	8: 'CP',
};

export const solutionsIndexLookup = 
	Object
		.entries(solutions)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const solutionsDropdownOptions = 
	Object
		.entries(solutions)
		.map(([key, value]) => ({ key: key, text: value }));