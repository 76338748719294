import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobBudget } from '../types/IJobBudget';

export async function getJobBudget(jobId: string, budgetId: string) {
  const response = await fetch(
    `${apiEndpoint}/jobs/${jobId}/budgets/${budgetId}`,
    {
      method: 'GET',
      headers: await standardHeaders(),
      mode: 'cors',
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return null;
}

export async function getJobBudgetByJobId(jobId: string): Promise<IJobBudget> {
  const response = await fetch(`${apiEndpoint}/jobs/${jobId}/budget`, {
    method: 'GET',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  throw Error(response.statusText);
}
