import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CommandButton,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { manageSkillsPermission } from 'enums/permissions';
import { skillsFields } from '../../enums/skillsFields';
import { skillsFilterables } from '../../enums/skillsFilterables';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useFilterList } from '../../hooks/useFilterList';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import withAccess from 'hocs/withAccess';

import { getSkillsContextualMenu, useSkills } from 'hooks/useSkills';

export const ManageSkillsPage: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { skills = [] } = useSkills();
  const { toastSuccessMessage } = useToast();
  const menu = getSkillsContextualMenu();
  const [searchQuery, setSearchQuery] = useState('');
  const { filteredList } = useFilterList(skills, searchQuery);

  useEffect(() => {
    setBreadcrumbs([]);
  }, [history]);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Manage Skills</h2>
          <CommandButton
            iconProps={{ iconName: 'Add' }}
            text="New Skill"
            onClick={() => history.push('/manageskills/new')}
          />
        </header>
        <Stack horizontal style={{ marginBottom: 16 }}>
          <StackItem>
            <TextField
              value={searchQuery}
              style={{ minWidth: 300 }}
              onChange={(_: any, value: any) => {
                setSearchQuery(value);
              }}
            />
          </StackItem>
        </Stack>
        {toastSuccessMessage && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
            >
              {toastSuccessMessage}
            </MessageBar>
          </div>
        )}
        <div className="da-details-list-container">
          {skills && (
            <DetailsListWithContextMenu
              columns={skillsFields}
              items={filteredList}
              selectionMode={SelectionMode.none}
              onActiveItemChanged={(item) =>
                item && history.push(`/manageskills/${item.id}/edit`)
              }
              contextMenu={menu}
              filterableColumns={skillsFilterables}
              layoutMode={DetailsListLayoutMode.fixedColumns}
            />
          )}
        </div>
      </Container>
    </Page>
  );
};

export default withAccess(manageSkillsPermission, ManageSkillsPage);
