export const NAVIGATION_LINKS = {
  HOME: {
    label: 'Home',
    url: '/',
    key: 'home',
  },
  CLIENTS: {
    label: 'Clients',
    url: '/clients',
    key: 'clients',
  },
  TIMESHEET: {
    label: 'Timesheet',
    url: '/timesheet',
    key: 'timesheet',
  },
  BOOKINGS_TIMELINE: {
    label: 'Bookings Timeline',
    url: '/bookingstimeline',
    key: 'bookingstimeline',
  },
  OPPORTUNITIES: {
    label: 'Opportunities',
    url: '/opportunities',
    key: 'opportunities',
  },
  JOBS: {
    label: 'Jobs',
    url: '/jobs',
    key: 'jobs',
  },
  INVOICES: {
    label: 'Invoices',
    url: '/invoices',
    key: 'invoices',
  },
  SKILLRATINGS: {
    label: 'Skill Ratings',
    url: '/skillratings',
    key: 'skillratings',
  },
  SKILLPRIORITIES: {
    label: 'Skill Priorities',
    url: '/skillpriorities',
    key: 'skillpriorities',
  },
};
