import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { INVOICES_QUERY_KEYS } from '../constants/queries';
import { getJobInvoices } from '../resources/getJobInvoices';
import { updateInvoiceJobId } from '../resources/updateInvoiceJobId';
import {
  getJobInvoice,
  getJobInvoiceByJobId,
} from '../resources/getJobInvoice';
import { getJobInvoiceLines } from '../resources/getJobInvoiceLines';

import { IInvoice } from '../types/IInvoice';
import { IInvoiceLine } from '../types/IInvoiceLine';
import { IMutateOption, IQueryResult, IUpdateResult } from '../types/IQueryResult';

export function useInvoices(): IQueryResult & {
  invoices: IInvoice[];
} {
  const {
    data: invoices,
    isLoading,
    error,
  } = useQuery(INVOICES_QUERY_KEYS.getAll(), getJobInvoices, {});

  return { invoices, isLoading, error };
}

export function useInvoiceSingle(jobInvoiceId: string): IQueryResult & {
  invoice: IInvoice;
} {
  const { data, isLoading, error } = useQuery(
    INVOICES_QUERY_KEYS.getSingle(jobInvoiceId),
    () => getJobInvoice(jobInvoiceId),
    {}
  );

  const { invoiceLines } = useInvoiceLines(jobInvoiceId);
  const invoice = useMemo(() => {
    if (data) return { ...data, lines: invoiceLines ?? [] };
    return null;
  }, [data, invoiceLines]);
  return { invoice, isLoading, error };
}

export function useInvoiceSingleByJobId(jobId: string): IQueryResult & {
  invoice: IInvoice;
} {
  const { invoice: data, isLoading, error } = useInvoiceByJobId(jobId);
  const { invoiceLines } = useInvoiceLines(data.job_id);
  const invoice = useMemo(() => {
    if (data) return { ...data, lines: invoiceLines ?? [] };
    return null;
  }, [data, invoiceLines]);
  return { invoice, isLoading, error };
}

export function useInvoiceLines(jobInvoiceId: string): IQueryResult & {
  invoiceLines: IInvoiceLine[];
} {
  const {
    data: invoiceLines,
    isLoading,
    error,
  } = useQuery(
    INVOICES_QUERY_KEYS.getInvoiceLines(jobInvoiceId),
    () => getJobInvoiceLines(jobInvoiceId),
    {}
  );
  return { invoiceLines, isLoading, error };
}

function useInvoiceByJobId(jobId: string) {
  const {
    data: invoice,
    isLoading,
    error,
  } = useQuery(
    INVOICES_QUERY_KEYS.getSingleByJobId(jobId),
    () => getJobInvoiceByJobId(jobId),
    {}
  );
  return { invoice, isLoading, error };
}

export function useUpdateInvoiceJobId(
  opts?: IMutateOption<IInvoice>
): IUpdateResult<{ jobInvoiceId: number; jobId: number }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobInvoiceId: number; jobId: number }) =>
    updateInvoiceJobId(data.jobInvoiceId, data.jobId),
    {
      onSuccess: (data: IInvoice) => {
        if (!data || !data?.job_id) return;

        queryClient.invalidateQueries(
          INVOICES_QUERY_KEYS.updateInvoiceJobId(data.id, data.job_id)
        );
        queryClient.invalidateQueries(INVOICES_QUERY_KEYS.getAll());
        queryClient.invalidateQueries(INVOICES_QUERY_KEYS.getSingle(data.id.toString()));
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}