import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobRoleBudget } from 'types/IJobRoleBudget';

export async function createJobStatus(status: Partial<IJobRoleBudget>) {
	const response = await fetch(`${apiEndpoint}/jobs/${status.job_id}/statuses`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(status)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}