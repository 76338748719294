import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CommandButton,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { feedbackFields } from '../../enums/feedbackFields';
import { feedbackFilterables } from '../../enums/feedbackFilterables';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useFilterList } from '../../hooks/useFilterList';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import {
  useFeedbackList,
  getFeedbacksContextualMenu,
} from '../../hooks/useFeedback';
import withAccess from '../../hocs/withAccess';
import { opportunityPermissions } from '../../enums/permissions';
import './OpportunitiesPage.css';

export const OpportunitiesPage: React.FC = () => {
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { feedbacks = [] } = useFeedbackList();
  const { toastSuccessMessage } = useToast();
  const menu = getFeedbacksContextualMenu();
  const [searchQuery, setSearchQuery] = useState('');
  const { filteredList } = useFilterList(feedbacks, searchQuery);

  useEffect(() => {
    setBreadcrumbs([]);
  }, [history]);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Feedback</h2>
          <CommandButton
            iconProps={{ iconName: 'Add' }}
            text="New Feedback"
            onClick={() => history.push('/feedback/new')}
          />
        </header>
        <Stack horizontal style={{ marginBottom: 16 }}>
          <StackItem>
            <TextField
              value={searchQuery}
              style={{ minWidth: 300 }}
              onChange={(_: any, value: any) => {
                setSearchQuery(value);
              }}
            />
          </StackItem>
        </Stack>
        {toastSuccessMessage && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
            >
              {toastSuccessMessage}
            </MessageBar>
          </div>
        )}
        <div className="da-details-list-container">
          {feedbacks && (
            <DetailsListWithContextMenu
              columns={feedbackFields}
              items={filteredList}
              selectionMode={SelectionMode.none}
              onActiveItemChanged={(item) =>
                item && history.push(`/feedback/${item.id}/edit`)
              }
              contextMenu={menu}
              filterableColumns={feedbackFilterables}
              layoutMode={DetailsListLayoutMode.fixedColumns}
            />
          )}
        </div>
      </Container>
    </Page>
  );
};

export default withAccess(
  {
    permisionAll: opportunityPermissions.editAll,
    permissionMe: opportunityPermissions.editMe,
  },
  OpportunitiesPage
);
