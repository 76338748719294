import React, { useContext } from 'react';
import {
  Persona,
  PersonaSize,
  IconButton,
  IDropdownOption,
  Dropdown,
} from '@fluentui/react';
import { useAzureAD } from '../hooks/useAzureAD';
import './NavHeader.css';
import { FluentThemeContext } from '../providers/FluentThemeProvider';

const options: IDropdownOption[] = [
  { key: 'default', text: 'Default' },
  { key: 'dark', text: 'Dark' },
  { key: 'contrast', text: 'Contrast' },
];

const publicUrl = process.env.PUBLIC_URL;
const IS_DEBUG = process.env.REACT_APP_DEBUG === 'true';

function NavHeader(props: { onMenuClick: (value: boolean) => void }) {
  const { accountInfo } = useAzureAD();
  const { changeTheme } = useContext(FluentThemeContext);
  const handleMenuClick = (value: boolean) => {
    if (props?.onMenuClick) {
      props.onMenuClick(value);
    }
  };

  return (
    <nav className="da-navheader">
      <IconButton
        className="da-nav-menu"
        primary
        iconProps={{ iconName: 'CollapseMenu' }}
        title="Menu"
        ariaLabel="Menu"
        onClick={() => handleMenuClick(true)}
      />
      <div className="da-spacer da-mobile-only" />
      <div className="da-logo-container">
        <img src={`${publicUrl}/logo-small-white.png`} alt="DataAddiction" />
      </div>
      <div className="da-vertical-separator" />
      <div className="da-product-title">Starscream</div>
      {IS_DEBUG && (
        <Dropdown
          placeholder="Theme"
          options={options}
          onChange={(_, item) => changeTheme(item?.key?.toString())}
        />
      )}
      <div className="da-spacer" />
      <div className="da-persona">
        <Persona
          text={accountInfo?.name}
          size={PersonaSize.size32}
          hidePersonaDetails={true}
        />
      </div>
    </nav>
  );
}

export default NavHeader;
