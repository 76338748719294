import { DAAligmentType, IDAColumn } from 'types/IDADetailsList';

const verticalColDefault = {
  isResizable: true,
  columnAlign: DAAligmentType.CENTER,
  rowAlign: DAAligmentType.CENTER,
};

export const opportunitiesFields: IDAColumn[] = [
  {
    key: 'name',
    fieldName: 'name',
    name: 'Name',
    minWidth: 140,
    maxWidth: 140,
    cellTooltip: {
      getContent: (item?: any) => item.name,
    },
    onRender: (item?: any) => item.name,
  },
  {
    key: 'client',
    fieldName: 'client',
    name: 'Client',
    minWidth: 110,
    maxWidth: 110,
    cellTooltip: {
      getContent: (item?: any) => item.client,
    },
    onRender: (item?: any) => item.client,
  },
  {
    key: 'value',
    fieldName: 'value_currency',
    name: 'Value',
    minWidth: 60,
    maxWidth: 80,
  },
  {
    key: 'stage',
    fieldName: 'stage',
    name: 'Stage',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'forecast',
    fieldName: 'forecast',
    name: 'Forecast',
    minWidth: 50,
    maxWidth: 50,
    isResizable: true,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'start_date',
    fieldName: 'start_date',
    name: 'Start Date',
    minWidth: 60,
    maxWidth: 60,
    ...verticalColDefault,
  },
  {
    key: 'close_date',
    fieldName: 'close_date',
    name: 'Close Date',
    minWidth: 60,
    maxWidth: 60,
    ...verticalColDefault,
  },
  {
    key: 'account_manager_name',
    fieldName: 'account_manager_name',
    name: 'AM',
    minWidth: 45,
    maxWidth: 45,
    ...verticalColDefault,
  },
  {
    key: 'business_unit_name',
    fieldName: 'business_unit_name',
    name: 'BU',
    minWidth: 45,
    maxWidth: 45,
    ...verticalColDefault,
  },
  {
    key: 'solution_names',
    fieldName: 'solution_names',
    name: 'Solution(s)',
    minWidth: 50,
    maxWidth: 50,
    ...verticalColDefault,
  },
  {
    key: 'industry_name',
    fieldName: 'industry_name',
    name: 'Industry',
    minWidth: 50,
    maxWidth: 50,
    ...verticalColDefault,
  },
];
