import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ISkill } from 'types/ISkill';

export async function updateSkill(skillId: string, skill: ISkill) {
  const { id: _, ...rest } = skill;
  const response = await fetch(`${apiEndpoint}/skills/${skillId}`, {
    method: 'PUT',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...rest,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
