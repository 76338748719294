import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SKILLS_KEYS } from '../constants/queries';
import { getSkills } from '../resources/getSkills';
import { ICreateResult, IDeleteResult, IMutateOption, IQueryResult, IUpdateResult } from '../types/IQueryResult';
import { getSkill } from 'resources/getSkill';
import { ISkill } from 'types/ISkill';
import { updateSkill } from 'resources/updateSkill';
import { deleteSkill } from 'resources/deleteSkill';
import { createSkill } from 'resources/createSkill';

export function useSkills(): IQueryResult & {
  skills: any[];
} {
  const {
    data: skills,
    isLoading,
    error,
  } = useQuery(SKILLS_KEYS.getAll(), getSkills, {});

  return { skills, isLoading, error };
}

export const getSkillsContextualMenu = () => ({
  checkboxFilters: {},
  filters: {},
  sortStack: ['level1'],
  menuProps: undefined,
});


export function useSkillSingle(id: number): IQueryResult & {
  skill: any;
} {
  const {
    data: skill,
    isLoading,
    error,
  } = useQuery(
    SKILLS_KEYS.getSingle(id?.toString()),
    () => getSkill(id.toString()!),
    {
      enabled: !!id,
    }
  );
  return { skill, isLoading, error };
}

export function useCreateSkill(
  opts: IMutateOption<ISkill>
): ICreateResult<{ payload: ISkill }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { payload: ISkill }) =>
      createSkill(data.payload),
    {
      onSuccess: (data: ISkill) => {
        queryClient.invalidateQueries(SKILLS_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateSkill(
  opts?: IMutateOption<ISkill>
): IUpdateResult<{ skillId: string; payload: ISkill }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { skillId: string; payload: ISkill }) =>
      updateSkill(data.skillId, data.payload),
    {
      onSuccess: (data: ISkill) => {
        queryClient.invalidateQueries(
          SKILLS_KEYS.getSingle(data.id.toString())
        );
        queryClient.invalidateQueries(SKILLS_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteSkill(opts: IMutateOption<ISkill>): IDeleteResult<string> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(deleteSkill, {
    onSuccess: (data: ISkill) => {
      queryClient.removeQueries(
        SKILLS_KEYS.getSingle(data?.id?.toString())
      );
      queryClient.invalidateQueries(SKILLS_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}