import { IOpportunity } from '../types/IOpportunity';
import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function deleteOpportunity(
  opportunityId: string
): Promise<IOpportunity> {
  const response = await fetch(
    `${apiEndpoint}/opportunities/${opportunityId}`,
    {
      method: 'DELETE',
      headers: await standardHeaders(),
      mode: 'cors',
    }
  );
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
