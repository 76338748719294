import { useMemo } from "react";
import { IColumn } from "@fluentui/react";
import moment from "moment";
import { IQueryResult } from "../types/IQueryResult";
import { useMembers } from "./useMembers";
import { useClients } from "./useClients";
import { useTimesheetsOnly } from "./useTimesheets";
import { useJobs } from "./useJobs";
import {
  IBookingsTimeline,
  IBookingsTimelineData,
  IBookingTimelineMember,
} from "../types/IBookingsTimeline";
import { IJob } from "../types/IJob";
import { isWeekend } from "../components/utils/DateHelper";

export interface BookingsTimelineFilter {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  hasCapacity: boolean | undefined;
  active?: boolean | undefined;
  roles?: string[] | undefined;
  capabilities?: string[] | undefined;
  jobs?: string[] | undefined;
  isWeekly?: boolean | undefined;
}

const persistFilters = (filter: BookingsTimelineFilter) => {
  if (!window.localStorage) return;
  window.localStorage.removeItem("BOOKINGS_TIMELINE_FILTER");
  const { startDate, endDate, isWeekly, ...rest } = filter;
  window.localStorage.setItem("BOOKINGS_TIMELINE_FILTER", JSON.stringify(rest));
};

export const getDefaultTimelineFilter = (): BookingsTimelineFilter => {
  const defaultValue = {
    startDate: moment().startOf("isoWeek").toDate(),
    endDate: moment(moment().startOf("isoWeek")).add(6, "weeks").toDate(),
    hasCapacity: false,
    active: true,
    roles: [],
    capabilities: [],
    jobs: [],
    isWeekly: false,
  };
  try {
    if (!window.localStorage) return defaultValue;
    const persisted = window.localStorage.getItem("BOOKINGS_TIMELINE_FILTER");
    if (persisted) {
      const result = JSON.parse(persisted) as BookingsTimelineFilter;
      return {
        ...defaultValue,
        ...result,
      };
    }
    return defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export function useBookingTimeline(opts: {
  timelineFilter: BookingsTimelineFilter;
}): IQueryResult & IBookingsTimeline {
  const { startDate, endDate, hasCapacity, active, roles, capabilities, jobs, isWeekly } =
    opts?.timelineFilter;

  const {
    members,
    isLoading: membersLoading,
    error: membersError,
  } = useMembers();

  const {
    clients,
    isLoading: clientsLoading,
    error: clientsError,
  } = useClients();

  const {
    timesheets = [],
    isLoading: timesheetLoading,
    error: timeSheetError,
  } = useTimesheetsOnly({
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(isWeekly ? moment(moment().startOf("isoWeek")).add(12, "weeks").toDate() :  endDate).format("YYYY-MM-DD"),
  });

  const { jobs: jobList } = useJobs();

  const isLoading = useMemo(
    () => membersLoading || clientsLoading || timesheetLoading,
    [clientsLoading, membersLoading, timesheetLoading]
  );

  const error = useMemo(
    () => membersError || clientsError || timeSheetError,
    [clientsError, membersError, timeSheetError]
  );

  const mappedClient = useMemo(() => {
    const result = new Map();
    clients?.forEach((client) => {
      result.set(client.id, client);
    });
    return result;
  }, [clients]);

  const mappedTimesheets = useMemo(() => {
    const result = new Map();

    let filteredTimesheets =  timesheets;

    if (jobs?.length) {
      filteredTimesheets = filteredTimesheets.filter(({ job_id }) => jobs.some(job => +job === job_id))
    }

    filteredTimesheets.forEach((timesheet) => {
      const clientInitials = mappedClient.get(timesheet.client_id)
        ? mappedClient.get(timesheet.client_id).initials
        : "";

      if (!result.has(timesheet.date)) {
        result.set(
          timesheet.date,
          new Map([
            [
              timesheet.member_id,
              [
                {
                  ...timesheet,
                  clientInitials,
                  job_name: jobList.find((j: IJob) => j.id === timesheet.job_id)
                    ?.name,
                },
              ],
            ],
          ])
        );
      } else {
        const date = result.get(timesheet.date);
        if (!date.has(timesheet.member_id)) {
          date.set(timesheet.member_id, [
            {
              ...timesheet,
              clientInitials,
              job_name: jobList.find((j: IJob) => j.id === timesheet.job_id)?.name,
            },
          ]);
        } else {
          const memberTimesheets = date.get(timesheet.member_id);
          date.set(timesheet.member_id, [
            ...memberTimesheets,
            {
              ...timesheet,
              clientInitials,
              job_name: jobList.find((j: IJob) => j.id === timesheet.job_id)?.name,
            },
          ]);
        }
      }
    });

    return result;
  }, [jobList, jobs, mappedClient, timesheets]);

  const consultants = useMemo(() => {
    let filteredMembers = members?.filter((member) => member.id !== 2);
    // If active is selected as filter, only show active members, else show all
    if (active) filteredMembers = members?.filter((member) => member.active);
    // If there are roles selected, then filter by roles
    if (roles?.length) {
      filteredMembers = filteredMembers?.filter(
        (m) => !!m.role && roles.includes(m.role)
      );
    }
    // If there are capabilities selected, then filter by roles
    if (capabilities?.length) {
      filteredMembers = filteredMembers?.filter(
        (m) => !!m.capability && capabilities.includes(m.capability)
      );
    }
    
    return filteredMembers?.map((member) => ({
      ...member,
    })) as IBookingsTimelineData;
  }, [members, active, roles, capabilities]);

  const datesFields = useMemo(() => {
    const fields: IColumn[] = [
      {
        key: "consultant",
        fieldName: "name",
        name: "Consultant",
        minWidth: 100,
      },
    ];
    const duration = isWeekly ? "weeks" : "days";
    // loop per day/week
    for (
      let m = moment(startDate);
      m.diff(endDate, duration) < 0;
      m.add(1, duration)
    ) {
      const dateStr = m.format("YYYY-MM-DD");
      fields.push({
        key: dateStr,
        fieldName: dateStr,
        name: m.format("ddd DD MMM"),
        minWidth: 20,
      });
    }
    return fields;
  }, [endDate, isWeekly, startDate]);

  const data = useMemo(() => {
    let consultantsCopy = [...consultants];
    
    if (jobs?.length) {
      const filteredTimesheets: any[] = timesheets
        .filter(({ job_id }) => (
          jobs.some(job => +job === job_id))
        )

      const filteredMemberIds: any[] = Object.values(
        filteredTimesheets
          .reduce((acc, obj) => (
            { ...acc, [obj.member_id]: obj }), {}
          )
      );
      
      consultantsCopy = consultantsCopy
        .filter(({ id }) => (
          filteredMemberIds?.some(({ member_id }) => (
            member_id === id
          ))
        ))
    }

    // loop per day
    for (
      let m = moment(startDate);
      m.diff(endDate, "days") < 0;
      m.add(1, "days")
    ) {
      const dateStr = m.format("YYYY-MM-DD");
      const hasRecord = mappedTimesheets.has(dateStr);

      consultantsCopy.forEach((consultant) => {
        if (!hasRecord) {
          consultant[dateStr] = "";
        } else {
          consultant[dateStr] = JSON.stringify(
            mappedTimesheets.get(dateStr).get(consultant.id)
          );
        }
      });
    }

    // Filter by capacity, meaning members that has an open slot for a weekday
    if (hasCapacity) {
      const memberFields = ["id", "name", "email", "active"];
      consultantsCopy = consultantsCopy.filter((c) => {
        const items = Object.keys(c).filter(
          (s) => !memberFields.includes(s) && !isWeekend(s)
        );
        return items.some((m) => !c[m]);
      });
    }

    consultantsCopy = consultantsCopy.sort(
      (a: IBookingTimelineMember, b: IBookingTimelineMember) =>
        a.name > b.name ? 1 : -1
    );

    return consultantsCopy;
  }, [consultants, endDate, hasCapacity, jobs, mappedTimesheets, startDate, timesheets]);

  // Persist filters if any
  persistFilters({
    startDate,
    endDate,
    hasCapacity,
    active,
    capabilities,
    roles,
    jobs,
    isWeekly,
  });

  return {
    data: data ?? [],
    datesFields: datesFields ?? [],
    members,
    isLoading,
    error,
  };
}
