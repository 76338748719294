import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useCourses } from './useCourses';
import { useMembers } from './useMembers';

import { getCourseStatuses } from '../resources/getCourseStatuses';
import { getCourseStatus } from '../resources/getCourseStatus';
import { saveCourseStatus } from '../resources/saveCourseStatus';

import { COURSE_STATUSES_KEYS } from '../constants/queries';
import { IMutateOption, IQueryResult } from '../types/IQueryResult';
import {
  ICourseStatus,
  ICourseStatusSummary,
  ISaveCourseStatus,
} from 'types/ICourseStatus';

export type ISaveCourseStatusesResult = IQueryResult & {
  saveCourseStatuses: (payload: ISaveCourseStatus[]) => void;
};

export function useCourseStatuses(): IQueryResult & {
  courseStatuses: ICourseStatus[];
} {
  const {
    data: courseStatuses,
    isLoading,
    error,
  } = useQuery(COURSE_STATUSES_KEYS.getAll(), getCourseStatuses, {});

  return { courseStatuses, isLoading, error };
}

export function useCourseStatusSummary(): IQueryResult & {
  courseStatuses: ICourseStatusSummary[];
} {
  const { data, isLoading, error } = useQuery(
    COURSE_STATUSES_KEYS.getAll(),
    getCourseStatuses,
    {}
  );

  const { courses } = useCourses();
  const { members } = useMembers();

  const courseStatuses = useMemo(() => {
    if (data?.length) {
      return data.map((courseStatuses: ICourseStatus) => {
        const course: ICourseStatusSummary = courses.find(
          (course) => course.id === courseStatuses.course_id
        );

        let status;

        if (courseStatuses.booked_date && !courseStatuses.completed_date) {
          status = 'Booked';
        } else if (courseStatuses.completed_date) {
          status = 'Completed';
        }

        return {
          ...courseStatuses,
          level_course: `${course.level1} / ${course.level2} / ${course.course}`,
          level1: course.level1,
          level2: course.level2,
          course: course.course,
          wiki_url: course.wiki_url,
          member_name:
            members.find((members) => members.id === courseStatuses.member_id)
              ?.name ?? 'Unknown',
          status,
        };
      });
    }
    return null;
  }, [data]);

  return { courseStatuses, isLoading, error };
}

export function useCourseStatusSingle(id: number): IQueryResult & {
  courseStatus: ICourseStatus;
} {
  const {
    data: courseStatus,
    isLoading,
    error,
  } = useQuery(
    COURSE_STATUSES_KEYS.getSingle(id?.toString()),
    () => getCourseStatus(id.toString()!),
    {
      enabled: !!id,
    }
  );
  return { courseStatus, isLoading, error };
}

export function useCourseStatusSummarySingle(id: number): IQueryResult & {
  courseStatus: ICourseStatusSummary;
} {
  const { data, isLoading, error } = useQuery(
    COURSE_STATUSES_KEYS.getSingle(id?.toString()),
    () => getCourseStatus(id.toString()!),
    {
      enabled: !!id,
    }
  );

  const { courses } = useCourses();
  const { members } = useMembers();

  const courseStatus = useMemo(() => {
    if (data) {
      const course: ICourseStatusSummary = courses.find(
        (course) => course.id === data.course_id
      );

      let status;

      if (data.booked_date) {
        status = 'Booked';
      } else if (data.completed_date) {
        status = 'Completed';
      }

      return {
        ...data,
        level_course: `${course.level1} / ${course.level2} / ${course.course}`,
        member_name:
          members.find((members) => members.id === data.member_id)?.name ??
          'Unknown',
        status,
      };
    }
    return null;
  }, [data]);

  return { courseStatus, isLoading, error };
}

export function useSaveCourseStatuses(
  opts?: IMutateOption<ICourseStatus[]>
): ISaveCourseStatusesResult {
  const queryClient = useQueryClient();
  const handler = async (payload: ISaveCourseStatus[]) => {
    const savedItems = await Promise.all(payload.map(saveCourseStatus));
    if (savedItems.every((r) => Boolean(r))) {
      return savedItems;
    }
    throw new Error('Something went wrong');
  };
  const { mutate, isLoading, error } = useMutation(handler, {
    onSuccess: (data: ICourseStatus[]) => {
      queryClient.invalidateQueries(COURSE_STATUSES_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });
  return { saveCourseStatuses: mutate, isLoading, error };
}

export const getCourseStatusesContextualMenu = () => ({
  checkboxFilters: {},
  filters: {},
  sortStack: ['-status'],
  menuProps: undefined,
});
