import React, { useContext } from "react";
import { AppContext } from "../../providers/AppContextProvider";
import TeamsContainer from "./TeamsContainer";
import WebContainer from "./WebContainer";
import "./Main.css";

const Main: React.FC = () => {
  const { isInTeams } = useContext(AppContext);
  if (isInTeams) return <TeamsContainer />;
  return <WebContainer />;
};

export default Main;
