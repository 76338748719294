import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getCourseStatuses() {
  const response = await fetch(`${apiEndpoint}/course_statuses`, {
    method: 'GET',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
