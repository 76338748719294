import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getJobMilestone(jobId: string, milestoneId: string) {
  const response = await fetch(
    `${apiEndpoint}/jobs/${jobId}/milestones/${milestoneId}`,
    {
      method: 'GET',
      headers: await standardHeaders(),
      mode: 'cors',
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return null;
}
