import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobStatus } from 'types/IJobStatus';

export async function updateJobStatus(status: Partial<IJobStatus>) {
	const response = await fetch(`${apiEndpoint}/jobs/${status.job_id}/statuses/${status.id}`, {
		method: 'PUT',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(status)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}