import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CommandButton,
  ComboBox,
  Stack,
  Spinner,
  SpinnerSize,
  SelectionMode,
  TextField,
  DatePicker,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import { Paper } from '../Paper';
import { TimesheetGrid } from '../timesheet/TimesheetGrid';
import { IMember } from '../../types/IMember';
import { useMembers } from '../../hooks/useMembers';
import {
  getTimesheetDateOptions,
  useTimesheetDataGrid,
  useTimesheets,
  useSaveTimesheet,
  useSaveTimesheets,
} from '../../hooks/useTimesheets';
import { IUserAccess } from '../../providers/CurrentUserProvider';
import withAccess from '../../hocs/withAccess';
import { timesheetPermissions } from '../../enums/permissions';
import './TimesheetPage.css';
import DetailsListWithContextMenu from 'components/DetailsListWithContextMenu';
import { timesheetBudgetFields } from 'enums/timesheetBudgetFields';
import { IJobRole } from 'types/IJobRole';
import { daysDropdownOptions } from 'enums/days';
import moment from 'moment';

const comboBoxStyles = {
  callout: {
    minWidth: 200,
  },
};

export const TimesheetPage: React.FC<{ userAccess: IUserAccess }> = (props: {
  userAccess: IUserAccess;
}) => {
  const location = useLocation();
  const { members } = useMembers();
  const dateOptions = getTimesheetDateOptions();
  const {
    dirty,
    errors,
    rows,
    setRows,
    cols,
    setCols,
    gridData,
    setGridData,
    timesheetState,
    timesheetsState,
    setTimesheetState,
    setTimesheetsState,
  } = useTimesheetDataGrid();
  const { data, isLoading, refetch } = useTimesheets(timesheetState);
  const { update: saveTimesheet, isLoading: isTimesheetSubmitting } = useSaveTimesheet({
    onSuccess: () => {
      refetch();
    },
  });
  const { update: saveTimesheets, isLoading: isTimesheetsSubmitting } = useSaveTimesheets({
    onSuccess: () => {
      refetch();
    },
  });

  const isSubmitting = isTimesheetSubmitting || isTimesheetsSubmitting;

  const [isUpdatingTimesheets, setIsUpdatingTimesheets] = useState<boolean>(false);

  const availableJobRoles = useMemo(() => {
    return gridData.job_roles
      .filter(
        (jobRole: any) =>
          jobRole.status === 1 && 
          jobRole.client_id !== 0 && 
          !jobRole.name?.includes('Data Addiction')
      )
      .map((job: IJobRole) => ({ key: job.job_role_id, text: job.name }))
      .sort(
        (
          a: { key: number; text: string },
          b: { key: number; text: string }
        ) => {
          return a.text > b.text ? 1 : -1;
        }
      );
  }, [gridData.job_roles]);

  const availableMembers = useMemo(() => {
    return members
      .filter(
        (member: IMember) =>
          member.active &&
          (!props.userAccess?.verifyOwner ||
            props.userAccess?.userId === member.id)
      )
      .map((member: IMember) => ({ key: member.id, text: member.name }))
      .sort(
        (
          a: { key: number; text: string },
          b: { key: number; text: string }
        ) => {
          return a.text > b.text ? 1 : -1;
        }
      );
  }, [members, props.userAccess?.userId, props.userAccess?.verifyOwner]);

  const convertToDays = (hours: number) => {
    const days = (hours / 8).toFixed(1).toString().replace(/^0+/, '');
    if (days === '.0') return '0';
    return days;
  }

  const validJobRoles = useMemo(() => {
    if (!gridData.job_roles) return [];

    const filteredJobRoles = gridData.job_roles
      .filter(
        (jobRole: any) =>
          jobRole.status === 1 && 
          jobRole.client_id !== 0 && 
          !jobRole.name?.includes('Data Addiction')
      );
      
    return filteredJobRoles.map(role => {
        return {
          client_id: role.client_id,
          hours_budget: convertToDays(role.hours_budget),
          hours_consumed: convertToDays(role.hours_consumed),
          hours_remaining: convertToDays(role.hours_remaining),
          job_id: role.job_id,
          name: role.name,
          status: role.status,
        }
      })
  }, [gridData.job_roles]);

  const statuses = [
    { id: 1, name: 'Committed' },
    { id: 2, name: 'Tentative' },
  ];

  const save = useCallback(async () => {
    saveTimesheet({ rows, cols, jobRoles: gridData.job_roles, statuses });
  }, [rows, cols]);

  const saveMultipleTimesheet = () => {    
    setIsUpdatingTimesheets(true);

    const endDate = moment(timesheetsState.startDate).weekday(5).add(timesheetsState.numberOfWeeks, 'weeks').format("YYYY MMM");
    
    setTimesheetState({ endDate });

    const timesheets: any[] = []
    const numberOfWeeks = timesheetsState.numberOfWeeks || 0;

    timesheetsState.daysOfWeek?.sort().forEach((day) => {
      let dayOfWeek = moment(timesheetsState.startDate).startOf("isoWeek").toDate();

      if (+day !== 1) {
        dayOfWeek = moment(dayOfWeek).isoWeekday(+day).toDate();
      }

      const intialRowValue = {
        "hours": 8,
        "comment": "",
        "status": 1,
      }

      if (numberOfWeeks === 0) {
        timesheets.push({
          ...intialRowValue,
          "date": moment(dayOfWeek).format("YYYY-MM-DD"),
          "job_role_id": timesheetsState.roleId,
          "job_role": availableJobRoles.find(role => role.key === timesheetsState.roleId)?.text ?? '',
          "member_id": timesheetState.memberId
        });
      } else {
        Array.from({ length: numberOfWeeks}, (_, i) => (
          timesheets.push({
            ...intialRowValue,
            "date": moment(dayOfWeek).add(i * 7, 'days').format("YYYY-MM-DD"),
            "job_role_id": timesheetsState.roleId,
            "job_role": availableJobRoles.find(role => role.key === timesheetsState.roleId)?.text ?? '',
            "member_id": timesheetState.memberId
          })
        ))
      }
    });

    saveTimesheets({ rows, timesheets });
  } 

  useEffect(() => {
    if (data) {
      setGridData(data);
    }
  }, [data, setGridData]);

  useEffect(() => {
    if (data && isUpdatingTimesheets) {
      setGridData(data);
      setIsUpdatingTimesheets(false);
    }
  }, [data]);

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      const memberId = searchParams.get('memberId');
      if (memberId) {
        setTimesheetState({ memberId: parseInt(memberId, 10) });
        setTimesheetsState({ memberId: parseInt(memberId, 10) });
      }
    }
  }, [location, setTimesheetState, setTimesheetsState]);

  const handleListSelectedChanged = (
    items: string[] | undefined,
    item: any,
  ) => {
    let result: string[] = [];
    if (items) result.push(...items);
    const index = result.indexOf(item.key);
    if (index !== -1) result.splice(index, 1);
    if (item.selected) result.push(item.key);
    setTimesheetsState({daysOfWeek: result});
  };

  const isApplyBtnDisabled = useMemo(() => {
    const { roleId, daysOfWeek, numberOfWeeks = 0 } = timesheetsState;
    return !(roleId !== 0 && daysOfWeek?.length && numberOfWeeks > 0);
  }, [timesheetsState])

  return (
    <Page>
      <CommandBar noBreadcrumbs>
        <Spacer />
        {errors.length > 0 && (
          <div className="da-error-messages-command-bar">
            There are errors in the timesheet. Please see the messages below.
          </div>
        )}
        <CommandButton
          iconProps={{ iconName: 'Save' }}
          text="Save Timesheet"
          onClick={save}
          disabled={isSubmitting || !dirty || errors.length > 0}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>Timesheet</h2>
        </header>
        <Paper>
          <Stack horizontal tokens={{ childrenGap: '1em' }}>
            <Stack.Item grow>
              <ComboBox
                label="Consultant"
                useComboBoxAsMenuWidth
                allowFreeform={true}
                autoComplete={'on'}
                options={availableMembers}
                selectedKey={timesheetState.memberId}
                onChange={(_: any, option: any) =>
                  option?.key && setTimesheetState({ memberId: option.key })
                }
                disabled={isSubmitting}
              />
            </Stack.Item>
            <Stack.Item>
              <ComboBox
                label="Start Date"
                allowFreeform={true}
                autoComplete={'on'}
                options={dateOptions}
                selectedKey={timesheetState.startDate}
                onChange={(_: any, option: any) =>
                  option?.key && setTimesheetState({ startDate: option.key })
                }
                disabled={isSubmitting}
                styles={comboBoxStyles}
              />
            </Stack.Item>
            <Stack.Item>
              <ComboBox
                label="End Date"
                allowFreeform={true}
                autoComplete={'on'}
                options={dateOptions}
                selectedKey={timesheetState.endDate}
                onChange={(_: any, option: any) =>
                  option?.key && setTimesheetState({ endDate: option.key })
                }
                disabled={isSubmitting}
                styles={comboBoxStyles}
              />
            </Stack.Item>
          </Stack>
          {timesheetState.memberId && !gridData.id && isLoading && (
            <div className="da-spinner-container">
              <Spinner size={SpinnerSize.large} />
            </div>
          )}
          <div className="da-grid-wrapper">
            {gridData.id && (
              <TimesheetGrid
                data={gridData}
                rows={rows}
                setRows={setRows}
                cols={cols}
                setCols={setCols}
                timesheetState={timesheetState}
              />
            )}
            {(timesheetState.memberId !== gridData.id || isSubmitting) && (
              <div className="da-grid-overlay">
                <Spinner size={SpinnerSize.large} />
              </div>
            )}
          </div>
          {errors.length > 0 && (
            <div className="da-error-messages">
              {errors.map((error) => (
                <span>{error}</span>
              ))}
            </div>
          )}
        </Paper>
        {gridData.id && (
          <>
            <Paper style={{ marginTop: 15 }}>
              <Stack horizontal tokens={{ childrenGap: '1em' }}>
                <Stack.Item grow>
                  <ComboBox
                    label="Role"
                    useComboBoxAsMenuWidth
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={availableJobRoles}
                    onChange={(_: any, option: any) =>
                      option?.key && setTimesheetsState({
                        roleId: option.key,
                      })
                    }
                    disabled={isSubmitting}
                  />
                </Stack.Item>
                <Stack.Item>
                  <DatePicker
                    label="Start date"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    style={{ minWidth: 250 }}
                    onSelectDate={(startDate) => setTimesheetsState({ startDate })}
                    value={timesheetsState.startDate ?? moment().startOf("isoWeek").toDate()}
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <ComboBox
                    label="Days of week"
                    multiSelect
                    options={daysDropdownOptions}
                    onChange={(_: any, value: any) => {
                      value && handleListSelectedChanged(
                        timesheetsState.daysOfWeek, 
                        value,
                      )
                    }}
                    disabled={isSubmitting}
                  />
                </Stack.Item>
                <Stack.Item>
                  <TextField
                    type="number"
                    label='Number of Weeks'
                    defaultValue={timesheetsState.numberOfWeeks?.toString()}
                    onChange={(_: any, value: any) => {
                      value && setTimesheetsState({
                        numberOfWeeks: value,
                      });
                    }
                    }
                  />
                </Stack.Item>
              </Stack>
              <Stack>
                <Stack.Item align="end">
                  <CommandButton
                    iconProps={{ iconName: 'Save' }}
                    text="Apply Changes"
                    onClick={saveMultipleTimesheet}
                    disabled={isSubmitting || isApplyBtnDisabled}
                  />
                </Stack.Item>
              </Stack>
            </Paper>
            <div className="da-details-list-container">
              <DetailsListWithContextMenu
                items={validJobRoles}
                columns={timesheetBudgetFields}
                selectionMode={SelectionMode.none}
              />
            </div>
          </>
          )}
      </Container>
    </Page>
  );
};

export default withAccess(
  {
    permisionAll: timesheetPermissions.editAll,
    permissionMe: timesheetPermissions.editMe,
  },
  TimesheetPage
);
