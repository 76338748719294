import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getTimesheetOptions(member_id: number) {
	const response = await fetch(`${apiEndpoint}/timesheet/options?member_id=${member_id}`, {
		method: 'GET',
		headers: await standardHeaders(),
		mode: 'cors'
	});
	if (response.ok) {
		return await response.json();
	}
	return [];
}