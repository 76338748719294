import { DAAligmentType, IDAColumn, IDAColumnHrefOptions } from "types/IDADetailsList";

export const timesheetBudgetFields: IDAColumn[] = [
  {
    key: 'name',
    fieldName: 'name',
    name: 'Role',
    minWidth: 450,
    isSorted: true,
    isSortedDescending: true,
    onRender: (item?: any) => (
      <a 
        className="job-link"
        href={`clients/${item.client_id}/jobs/${item.job_id}`}
        target="_blank"
        rel="noreferrer"
        style={{
          color: '#605e5c',
          textDecoration: 'none',
        }}
      >
        {item.name}
      </a>
    ),
  },
  {
    key: 'hours_budget',
    fieldName: 'hours_budget',
    name: 'Budget (Days)',
    minWidth: 150,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_consumed',
    fieldName: 'hours_consumed',
    name: 'Consumed (Days)',
    minWidth: 150,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_remaining',
    fieldName: 'hours_remaining',
    name: 'Remaining',
    minWidth: 150,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
];
