import { IColumn } from "@fluentui/react";

export const clientsFields: IColumn[] = [
  {
    key: "name",
    fieldName: "name",
    name: "Client Name",
    minWidth: 100,
  },
  {
    key: "active_jobs_count",
    fieldName: "active_jobs_count",
    name: "Active Jobs",
    minWidth: 130,
    isSorted: true,
    isSortedDescending: true,
    data: "number",
  },
  {
    key: "total_jobs_count",
    fieldName: "total_jobs_count",
    name: "Total Jobs",
    minWidth: 130,
    data: "number",
  },

];
