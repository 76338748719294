import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ICreateResult, IDeleteResult, IMutateOption, IQueryResult, IUpdateResult } from '../types/IQueryResult';
import { COURSES_KEYS } from '../constants/queries';
import { getCourses } from '../resources/getCourses';
import { getCourse } from 'resources/getCourse';
import { ICourse } from 'types/ICourse';
import { updateCourse } from 'resources/updateCourse';
import { deleteCourse } from 'resources/deleteCourse';
import { createCourse } from 'resources/createCourse';

export function useCourses(): IQueryResult & {
  courses: any[];
} {
  const {
    data: courses,
    isLoading,
    error,
  } = useQuery(COURSES_KEYS.getAll(), getCourses, {});

  return { courses, isLoading, error };
}

export const getCoursesContextualMenu = () => ({
  checkboxFilters: {},
  filters: {},
  sortStack: ['level1'],
  menuProps: undefined,
});

export function useCourseSingle(id: number): IQueryResult & {
  course: any;
} {
  const {
    data: course,
    isLoading,
    error,
  } = useQuery(
    COURSES_KEYS.getSingle(id?.toString()),
    () => getCourse(id.toString()!),
    {
      enabled: !!id,
    }
  );
  return { course, isLoading, error };
}

export function useCreateCourse(
  opts: IMutateOption<ICourse>
): ICreateResult<{ payload: ICourse }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { payload: ICourse }) =>
      createCourse(data.payload),
    {
      onSuccess: (data: ICourse) => {
        queryClient.invalidateQueries(COURSES_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateCourse(
  opts?: IMutateOption<ICourse>
): IUpdateResult<{ courseId: string; payload: ICourse }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { courseId: string; payload: ICourse }) =>
      updateCourse(data.courseId, data.payload),
    {
      onSuccess: (data: ICourse) => {
        queryClient.invalidateQueries(
          COURSES_KEYS.getSingle(data.id.toString())
        );
        queryClient.invalidateQueries(COURSES_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteCourse(opts: IMutateOption<ICourse>): IDeleteResult<string> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(deleteCourse, {
    onSuccess: (data: ICourse) => {
      queryClient.removeQueries(
        COURSES_KEYS.getSingle(data?.id?.toString())
      );
      queryClient.invalidateQueries(COURSES_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}