import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ISkill } from 'types/ISkill';

export async function createSkill(
  skill: ISkill
): Promise<ISkill> {
  const response = await fetch(`${apiEndpoint}/skills`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...skill,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
