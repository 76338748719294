import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobBudget } from '../types/IJobBudget';

export async function updateJobBudget(state: IJobBudget) {
  const headers = await standardHeaders();
  const responses = await Promise.all([
    fetch(`${apiEndpoint}/jobs/${state.job_id}/budgets/${state.id}`, {
      method: 'PUT',
      headers,
      mode: 'cors',
      body: JSON.stringify({
        job_id: +state.job_id,
        name: state.name,
        comments: state.comments,
        budget_total: state.budget_total ? +state.budget_total : null,
        opporunity_id: state.opporunity_id,
      }),
    }),
    ...(state.job_role_budgets ?? []).map((jrb) => {
      return fetch(
        `${apiEndpoint}/jobs/${state.job_id}/roles/${jrb.job_role_id}/budgets/${jrb.job_role_budget_id}`,
        {
          method: 'PUT',
          headers,
          mode: 'cors',
          body: JSON.stringify({
            job_role_id: jrb.job_role_id,
            name: jrb.name,
            hours_total: jrb.hours_total ? +jrb.hours_total : null,
            active: jrb.active,
          }),
        }
      );
    }),
  ]);
  if (responses.every((response) => response.ok)) {
    const budgetResponse = responses.splice(0, 1);
    const budget = await budgetResponse[0].json();
    const job_role_budgets = await Promise.all(responses.map((r) => r.json()));
    return {
      ...budget,
      job_role_budgets,
    };
  }
  return null;
}

export async function updateJobBudgetOnly(budget: IJobBudget) {
  const response = await fetch(
    `${apiEndpoint}/jobs/${budget.job_id}/budgets/${budget.id}`,
    {
      method: 'PUT',
      headers: await standardHeaders(),
      mode: 'cors',
      body: JSON.stringify(budget),
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return null;
}
