export const opportunityForecastTypes: {[key: number]: string} = {
	1: 'Commit',
	2: 'Upside',
	3: 'Pipeline'
};

export const opportunityForecastTypesIndexLookup = 
	Object
		.entries(opportunityForecastTypes)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const opportunityForecastTypesDropdownOptions = 
	Object
		.entries(opportunityForecastTypes)
		.map(([key, value]) => ({ key: key, text: value }));