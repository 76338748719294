import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  Pivot,
  PivotItem,
  CommandButton,
  SelectionMode,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Paper } from "../Paper";
import { jobsFields } from "../../enums/jobsFields";
import DetailsListWithContextMenu from "../DetailsListWithContextMenu";
import { ClientIndustry } from "../../enums/clientIndustry";
import { useClientSingle, useClientJobs } from "../../hooks/useClients";
import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";

export const ClientPage: React.FC = () => {
  const { clientId } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { client } = useClientSingle(clientId);
  const { clientJobs: jobs } = useClientJobs(clientId);
  const [activeTab, setActiveTab] = useState("jobs");

  useEffect(() => {
    if (!setBreadcrumbs) return;
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      { key: "client", text: client.name },
    ]);
  }, [history, client.name]);

  return (
    <Page>
      <CommandBar>
        <CommandButton
          iconProps={{ iconName: "Edit" }}
          text="Edit Details"
          onClick={() => history.push(`${location.pathname}/edit`)}
        />
        <CommandButton
          iconProps={{ iconName: "InternalInvestigation" }}
          text="Add Job"
          onClick={() => history.push(`${location.pathname}/jobs/new`)}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>
            {client.name} ({client.initials})
          </h2>
        </header>
        <div className="da-tabs">
          <Pivot
            headersOnly
            selectedKey={activeTab}
            onLinkClick={(item) => setActiveTab(item!.props.itemKey!)}
          >
            <PivotItem headerText="General" itemKey="details" />
            <PivotItem headerText="Jobs" itemKey="jobs" />
          </Pivot>
        </div>
        {activeTab === "details" && (
          <Paper>
            <ul className="da-unordered-list">
              <li>
                <h4>ID</h4>
                <p>{client.id}</p>
              </li>
              <li>
                <h4>Address</h4>
                <p>
                  {client.address} {client.suburb} {client.state}{" "}
                  {client.postcode}
                </p>
              </li>
              <li>
                <h4>ABN</h4>
                <p>{client.abn}</p>
              </li>
              <li>
                <h4>Industry</h4>
                <p>
                  {client.industry_id
                    ? ClientIndustry[client.industry_id]?.label
                    : ""}
                </p>
              </li>
            </ul>
          </Paper>
        )}
        {activeTab === "jobs" && (
          <>
            {jobs && jobs?.length > 0 ? (
              <div className="da-details-list-container">
                <DetailsListWithContextMenu
                  items={jobs}
                  columns={jobsFields}
                  selectionMode={SelectionMode.none}
                  onActiveItemChanged={(job) =>
                    history.push(`${location.pathname}/jobs/${job.id}`)
                  }
                  contextMenu={{
                    sortStack: ["+statusText"],
                    filters: {},
                    checkboxFilters: {},
                  }}
                />
              </div>
            ) : (
              <p>No jobs at the moment</p>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default ClientPage;
