const timesheetPermissions = {
  editAll: 'timesheet-edit-all',
  editMe: 'timesheet-edit-me',
};

const opportunityPermissions = {
  editAll: 'opportunities-edit-all',
  editMe: 'opportunities-edit-me',
};

const financialPermissions = {
  editAll: 'financial-edit-all',
  editMe: 'financial-edit-me',
};

const manageSkillsPermission = 'manage-skills';
const manageCoursesPermission = 'manage-courses';

export {
  timesheetPermissions,
  opportunityPermissions,
  financialPermissions,
  manageSkillsPermission,
  manageCoursesPermission,
};
