export const FeedbackStatus: {[key: number]: { label: string, shortName: string }} = {
  1: { label: 'Requested', shortName: 'RQD' },
  2: { label: 'Awaiting Approval', shortName: 'PVD' },
  3: { label: 'Rejected', shortName: 'RJT' },
  4: { label: 'Complete', shortName: 'CPL' },
};

export const FeedbackStatusDropdownOptions =
  Object
    .entries(FeedbackStatus)
    .map(([key, value]) => ({ key: key, text: value.label }));
