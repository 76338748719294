import { IColumn } from '@fluentui/react';

export const invoicesPageFields: IColumn[] = [
  {
    key: 'date',
    fieldName: 'date',
    name: 'Date',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    isSorted: true,
    isSortedDescending: true,
  },
  {
    key: 'invoice_number',
    fieldName: 'invoice_number',
    name: 'Inv #',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'contact_name',
    fieldName: 'contact_name',
    name: 'Contact',
    minWidth: 150,
    isResizable: true,
  },
  {
    key: 'reference',
    fieldName: 'reference',
    name: 'Reference',
    minWidth: 200,
    isResizable: true,
  },
  {
    key: 'status',
    fieldName: 'status',
    name: 'Status',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'job_id',
    fieldName: 'job_id',
    name: 'Job',
    minWidth: 100,
    isResizable: true,
  },
  {
    key: 'amount_subtotal',
    fieldName: 'amount_subtotal',
    name: 'Total (no GST)',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'amount_due',
    fieldName: 'amount_due',
    name: 'Due (with GST)',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
];
