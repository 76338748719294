import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  CommandButton,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
} from '@fluentui/react';
import moment from 'moment';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import { Paper } from '../Paper';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useOpportunitySingle } from '../../hooks/useOpportunities';
import { IOpportunityView } from '../../types/IOpportunity';
import withAccess from '../../hocs/withAccess';
import { opportunityPermissions } from '../../enums/permissions';

export const OpportunityPage: React.FC = () => {
  const { opportunityId } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { opportunity } = useOpportunitySingle(opportunityId);
  const data = opportunity as IOpportunityView;

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'opportunities',
        text: 'Opportunities',
        onClick: () => history.push('/opportunities'),
      },
      { key: 'opportunities', text: data.name! },
    ]);
  }, [history, data.name]);

  return (
    <Page>
      <CommandBar>
        <Spacer />
        <CommandButton
          iconProps={{ iconName: 'Edit' }}
          text="Edit Details"
          onClick={() => history.push(`${location.pathname}/edit`)}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>{data.name}</h2>
        </header>
        <div className="da-tabs">
          <Pivot>
            <PivotItem headerText="General" itemKey="details">
              <Paper>
                <Stack horizontal tokens={{ childrenGap: 80 }}>
                  <StackItem grow>
                    <ul className="da-unordered-list">
                      <li>
                        <h4>ID</h4>
                        <p>{data.id}</p>
                      </li>
                      <li>
                        <h4>Name</h4>
                        <p>{data.name}</p>
                      </li>
                      <li>
                        <h4>Comments</h4>
                        <p>{data.comments}</p>
                      </li>
                      <li>
                        <h4>Duration</h4>
                        <p>{data.id}</p>
                      </li>
                      <li>
                        <h4>Value</h4>
                        <p>{data.value}</p>
                      </li>
                    </ul>
                  </StackItem>
                  <StackItem grow>
                    <ul className="da-unordered-list">
                      <li>
                        <h4>Client</h4>
                        <p>{data.client}</p>
                      </li>
                      <li>
                        <h4>Close Date</h4>
                        <p>
                          {moment(data.close_date, 'YYYY-MM-DD').format(
                            'ddd D MMM YYYY'
                          )}
                        </p>
                      </li>
                      <li>
                        <h4>Stage</h4>
                        <p>{data.stage}</p>
                      </li>
                      <li>
                        <h4>Job Type</h4>
                        <p>{data.job_type_name}</p>
                      </li>
                    </ul>
                  </StackItem>
                </Stack>
              </Paper>
            </PivotItem>
          </Pivot>
        </div>
      </Container>
    </Page>
  );
};

export default withAccess(
  {
    permisionAll: opportunityPermissions.editAll,
    permissionMe: opportunityPermissions.editMe,
  },
  OpportunityPage
);
