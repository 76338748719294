export const days: {[key: number]: string} = {
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
};

export const daysIndexLookup = 
	Object
		.entries(days)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const daysDropdownOptions = 
	Object
		.entries(days)
		.map(([key, value]) => ({ key: key, text: value }));