import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getMembers(activeOnly: boolean = true) {
  let url = `${apiEndpoint}/members`;	

  if (activeOnly) {
    url += '?active_only=true';
  } else {
    url += '?active_only=false';
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  return [];
}

export async function getMember(id: number) {
  const response = await fetch(`${apiEndpoint}/members/${id}`, {
    method: 'GET',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}

export async function getMe() {
  const response = await fetch(`${apiEndpoint}/members/me`, {
    method: 'GET',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
