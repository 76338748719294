import moment from 'moment';
import { saveRow } from './saveRow';
import { deleteRow } from './deleteRow';

export const saveTimesheet = (
  rows: any[],
  cols: any[],
  jobRoles: any[],
  statuses: any[]
) => {
  //const jobRoles = cols.find((col) => col.key === 'job_role').options;
  //   const status = cols.find((col) => col.key === 'status').options;
  const dirtyRows = rows.filter((row: any) => row.dirty && !row.forDeletion);
  const forDeletions = rows.filter((row: any) => row.forDeletion);
  console.log(jobRoles);
  console.log(dirtyRows);
  return Promise.all([
    ...dirtyRows.map((row: any) => {
      return saveRow({
        timesheet_id: row.id,
        hours: Boolean(row.hours) ? +row.hours : undefined,
        comment: row.comments ?? '',
        date: moment(row.date, 'D MMM YYYY').format('YYYY-MM-DD'),
        status: row.status
          ? +statuses.find((status: any) => status.name === row.status).id
          : +statuses[0].id,
        job_role_id: row.job_role
          ? +jobRoles.find((jobRole: any) => jobRole.name === row.job_role)
              .job_role_id
          : +jobRoles[0].job_role_id,
        member_id: row.member_id,
      });
    }),
    ...forDeletions.map((row: any) => {
      return deleteRow(row.id);
    }),
  ]);
};
