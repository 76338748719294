import { IColumn } from '@fluentui/react';

export const milestonesFields: IColumn[] = [
	{
		key: 'name',
		fieldName: 'name',
		name: 'Name',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'status',
		fieldName: 'status',
		name: 'Status',
		minWidth: 100,
		maxWidth: 100
	},
	{
		key: 'estimated_date_complete',
		fieldName: 'estimated_date_complete',
		name: 'Estimated Date Completed',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'estimated_hours_spent',
		fieldName: 'estimated_hours_spent',
		name: 'Estimated Hours Spent',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'actual_date_complete',
		fieldName: 'actual_date_complete',
		name: 'Actual Date Completed',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'comments',
		fieldName: 'comments',
		name: 'Comments',
		minWidth: 100,
		maxWidth: 150
	},
];