import { useState } from 'react';
import { ITextFieldProps, TextField } from '@fluentui/react';
import { EditorProps } from './types';

type TextEditorProps = { textFieldProps?: ITextFieldProps } & EditorProps;

const TextEditor = (props: TextEditorProps) => {
  const [text, setText] = useState<string | undefined>();
  const handleChange = (_: any, newValue?: string) => {
    setText(newValue);
  };

  const publishChange = () => {
    props.onRowChange({ ...props.row, [props.column.key]: text }, true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key !== 'Enter') return;
    publishChange();
  };

  return (
    <TextField
      value={props.row[props.column.key]}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={props.onClose}
      {...props.textFieldProps}
    />
  );
};

export default TextEditor;
