export const jobTypes: { [key: number]: string } = {
	1: 'IP',
	2: 'ECIF',
	3: 'Solution Assessments',
};

export const jobTypesInitals: { [key: number]: string } = {
	1: 'IP',
	2: 'ECIF',
	3: 'SA',
};

export const jobTypesIndexLookup =
	Object
		.entries(jobTypes)
		.reduce((acc: { [key: string]: number }, [key, value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const jobTypesDropdownOptions = [
	{ key: 0, text: 'None' },
	...Object.entries(jobTypes).map(([key, value]) => ({ key: key, text: value })),
];
