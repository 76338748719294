import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CommandButton,
  Stack,
  TextField,
  MessageBar,
  MessageBarType,
  Toggle,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Paper } from "../Paper";
import { useClientSingle } from "../../hooks/useClients";
import {
  useJobSingle,
  useJobRole,
  useCreateJobRole,
  useUpdateJobRole,
  useDeleteJobRole,
} from "../../hooks/useJobs";

import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";

export const ClientJobRoleFormPage: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { clientId, jobId, roleId } = useParams<any>();
  const history = useHistory();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);
  const { jobRole: role } = useJobRole(jobId, roleId);
  const { create, isLoading: isCreating } = useCreateJobRole({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/roles/${r.id}`);
    },
    onError: () =>
      setGeneralError(
        "An error occurred while trying to create a new job role."
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateJobRole({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/roles/${r.id}`);
    },
    onError: () =>
      setGeneralError("An error occurred while trying to update the job role."),
  });
  const { delete: deleteJobRole, isLoading: isDeleting } = useDeleteJobRole({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${jobId}`);
    },
    onError: () =>
      setGeneralError("An error occurred while trying to delete the job role."),
  });
  const [formState, setFormState] = useState(
    role ?? { active: true, sell_rate: 0 }
  );
  console.log(role);
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState("");

  const isSubmitting = useMemo(
    () => isUpdating || isCreating || isDeleting,
    [isCreating, isDeleting, isUpdating]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      {
        key: "client",
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      {
        key: "job",
        text: job.name,
        onClick: () => history.push(`/clients/${client.id}/jobs/${job.id}`),
      },
      ...(role.name && role.id
        ? [
            {
              key: "role",
              text: role.name,
              onClick: () =>
                history.push(
                  `/clients/${client.id}/jobs/${job.id}/roles/${role.id}`
                ),
            },
            { key: "edit", text: "Edit Job Role" },
          ]
        : [{ key: "new", text: "New Job Role" }]),
    ]);
  }, [history, client, job, role]);

  const onSubmit = useCallback(async () => {
    const errors: any = {};
    if (!formState.name) {
      errors.name = "Job Name is required.";
    }
    if (
      typeof formState.sell_rate === "undefined" ||
      formState.sell_rate === null
    ) {
      errors.sell_rate = "Sell Rate is required.";
    } else if (isNaN(parseFloat(formState.sell_rate + ""))) {
      errors.sell_rate = "Sell Rate is not a valid number.";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    const payload: any = {
      name: formState.name,
      active: formState.active ?? true,
      sell_rate: parseFloat(formState.sell_rate + ""),
      job_id: +jobId,
    };

    if (!formState.id) {
      create(payload);
    } else {
      update({ ...payload, id: formState.id });
    }
  }, [setErrors, setGeneralError, history, formState, clientId, jobId]);

  const onDelete = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete this job role?")) {
      deleteJobRole({ jobId, roleId });
    }
  }, [jobId, roleId]);

  return (
    <Page>
      <CommandBar>
        {role.id && (
          <>
            <CommandButton
              iconProps={{ iconName: "Undo" }}
              text="Discard Changes"
              onClick={() =>
                history.push(
                  `/clients/${clientId}/jobs/${jobId}/roles/${role.id}`
                )
              }
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Delete" }}
              text="Delete Job Role"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Update Job Role"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!role.id && (
          <>
            <CommandButton
              iconProps={{ iconName: "Cancel" }}
              text="Cancel"
              onClick={() => history.push(`/clients/${clientId}/jobs/${jobId}`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Create New Job Role"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>
            {role.id ? `Edit Job Role` : `Create New Job Role for ${job.name}`}
          </h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={4}>
                <TextField
                  autoFocus
                  label="Job Role Name"
                  value={formState.name}
                  onChange={(_: any, name: any) =>
                    setFormState((state) => ({ ...state, name }))
                  }
                  errorMessage={errors.name}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <TextField
                  label="Sell Rate"
                  value={formState.sell_rate ? formState.sell_rate + "" : ""}
                  onChange={(_: any, sell_rate: any) =>
                    setFormState((state) => ({ ...state, sell_rate }))
                  }
                  errorMessage={errors.sell_rate}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <Toggle
                  label="Active"
                  checked={formState.active ?? true}
                  onChange={(_: any, active: any) =>
                    setFormState((state) => ({ ...state, active }))
                  }
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientJobRoleFormPage;
