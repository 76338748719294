/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useReducer } from "react";
import { standardHeaders } from "../resources/standardHeaders";

type State<T> = {
  data?: T;
  loading: boolean;
  error?: any;
};

type Action<T> =
  | { type: "loading" }
  | { type: "result"; result: T }
  | { type: "error"; error: any };

export type FetchOptions<T> = {
  url: string;
  type: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
  payload?: any;
  callback?: (data: T[]) => Promise<void>;
};

export type FetchResult<T> = {
  isLoading: boolean;
  error?: string | undefined | null;
  data: T | undefined;
  refresh: () => Promise<void>;
};

export type CrudUrl = {
  create?: string;
  update?: string;
  get?: string;
  delete?: string;
};

export type CrudOptions<T> = {
  url: string | CrudUrl;
  callback?: (data: T) => Promise<void>;
};

export interface CrudResult<T> extends FetchResult<T> {
  update: (payload: any) => Promise<{ result: boolean }>;
  create: (payload: any) => Promise<{ result: boolean }>;
}

type GetDataOptions<T> = {
  setData: (data: T) => void;
  setIsLoading: (value: boolean) => void;
  setError: (value: string | null | undefined) => void;
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  callback?: (data: T) => Promise<void>;
  payload?: any;
};

const createReducer =
  <T>() =>
  (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case "loading":
        return { data: state.data, loading: true };
      case "result":
        return { data: action.result, loading: false };
      case "error":
        return { loading: false, error: action.error };
    }
  };

/**
 * Generic function to invoke remote http and sets loading/error states
 * @param opts GetDataOptions<T>
 * @returns { result: boolean }
 */
async function invokeData<T>(
  opts: GetDataOptions<T>
): Promise<{ result: boolean }> {
  if (!opts.url) return { result: false };
  opts.setIsLoading(true);
  opts.setError(null);

  try {
    const headers = await standardHeaders();
    const response = await fetch(opts.url, {
      method: opts.method,
      headers,
      mode: "cors",
      body: opts.payload ? JSON.stringify(opts.payload) : undefined,
    });
    if (response.ok) {
      const resultData = (await response.json()) as unknown as T;
      opts.setData(resultData);
      if (opts.callback) await opts.callback(resultData);
      return { result: true };
    } else {
      opts.setError("Failed to invoke remote action");
      return { result: false };
    }
  } catch (err: any) {
    opts.setError(err?.message ?? "Failed to invoke remote action");
    return { result: false };
  } finally {
    opts.setIsLoading(false);
  }
}

export function useData<T>(
  asyncFn: () => Promise<T>,
  options?: { auto: boolean }
) {
  const { auto } = { auto: true, ...options };
  const [{ data, loading, error }, dispatch] = useReducer(createReducer<T>(), {
    loading: !!auto,
  });
  function reload() {
    if (!loading) dispatch({ type: "loading" });
    if (typeof asyncFn != "function") {
      throw new Error("invalid argument to useData, a function is required");
    }
    asyncFn()
      .then((data) => dispatch({ type: "result", result: data }))
      .catch((error) => dispatch({ type: "error", error }));
  }
  useEffect(() => {
    if (auto) reload();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return { data, loading, error, reload };
}

/**
 * Generic hook to get list of items via http call
 * @param opts FetchOptions<T>
 * @returns FetchResult<T[]>
 */
export function useFetchList<T>(opts: FetchOptions<T>): FetchResult<T[]> {
  const [data, setData] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined | null>();

  const getData = async () => {
    await invokeData<T[]>({
      url: opts.url,
      method: "GET",
      callback: opts.callback,
      setData,
      setIsLoading,
      setError,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    error,
    data,
    refresh: getData,
  };
}

/**
 * Generic hook that provides fetch and updating of a resource
 * @param opts CrudOptions<T>
 * @returns CrudResult<T>
 */
export function useCrudItem<T>(opts: CrudOptions<T>): CrudResult<T> {
  const [data, setData] = useState<T | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined | null>();

  const getData = async () => {
    const url =
      typeof opts.url === "string" ? opts.url : (opts.url as CrudUrl).get;

    if (!url) {
      setIsLoading(false);
      return;
    }

    await invokeData<T>({
      url,
      method: "GET",
      callback: opts.callback,
      setData,
      setIsLoading,
      setError,
    });
  };

  const update = async (payload: any): Promise<{ result: boolean }> => {
    const url =
      typeof opts.url === "string" ? opts.url : (opts.url as CrudUrl).update;
    if (!url) return { result: false };
    return invokeData<T>({
      url,
      method: "PUT",
      payload,
      setData,
      setIsLoading,
      setError,
    });
  };

  const create = async (payload: any): Promise<{ result: boolean }> => {
    const url =
      typeof opts.url === "string" ? opts.url : (opts.url as CrudUrl).create;
    if (!url) return { result: false };
    return invokeData<T>({
      url,
      method: "POST",
      payload,
      setData,
      setIsLoading,
      setError,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    isLoading,
    error,
    data,
    refresh: getData,
    update,
    create,
  };
}
