export const businessUnit: {[key: number]: string} = {
	1: 'Data & AI',
	2: 'Managed Services',
	3: 'Dynamics 365 CE',
	4: 'Azure App & Infra',
	5: 'Strategy',
};

export const businessUnitInitals: {[key: number]: string} = {
	1: 'D&A',
	2: 'MS',
	3: 'D365',
	4: 'AZ',
	5: 'ST',
};

export const businessUnitIndexLookup = 
	Object
		.entries(businessUnit)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const businessUnitDropdownOptions = 
	Object
		.entries(businessUnit)
		.map(([key, value]) => ({ key: key, text: value }));