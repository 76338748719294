/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CommandButton,
  MessageBar,
  MessageBarType,
  TextField,
  Stack,
  Dropdown,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';

import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import { ISkill } from 'types/ISkill';
import { useCreateSkill, useDeleteSkill, useSkillSingle, useUpdateSkill } from 'hooks/useSkills';
import { timelineDropdownOptions } from 'enums/manageSkills';

const getFormErrors = (form: ISkill): any => {
  const errors: any = {};

  if (!form.level1) {
    errors.level1 = 'Level 1 is required.';
  }
  if (!form.level2) {
    errors.level2 = 'Level 2 is required.';
  }
  if (!form.level3) {
    errors.level3 = 'Level 3 is required.';
  }
  if (!form.skill) {
    errors.skill = 'Skill is required.';
  }
  if (!form.wiki_url) {
    errors.wiki_url = 'Wiki URL is required.';
  }
  if (!form.timeline) {
    errors.wiki_url = 'Timeline is required.';
  }

  return errors;
};

export const SkillFormPage: React.FC = () => {
  const history = useHistory();
  const { skillId } = useParams<any>();
  const { skill } = useSkillSingle(skillId);
  const { setToastSuccessMessage } = useToast();

  const [data, setData] = useState(skill);
  
  const { setBreadcrumbs } = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');

  const { create, isLoading: isCreating } = useCreateSkill({
    onSuccess: () => {
      history.push(`/manageskills`);
      setToastSuccessMessage('Skill has been created.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to create a new skill.'
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateSkill({
    onSuccess: () => {
      history.push(`/manageskills`);
      setToastSuccessMessage('Skill has been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update the skill.'
      ),
  });
  const { delete: deleteSkill, isLoading: isDeleting } =
    useDeleteSkill({
      onSuccess: () => {
        history.push(`/manageskills`);
      },
      onError: () =>
        setGeneralError(
          'An error occurred while trying to delete the skill.'
        ),
    });

  const isSubmitting = useMemo(
    () => isCreating || isUpdating || isDeleting,
    [isCreating, isUpdating, isDeleting]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'skills',
        text: 'Skills',
        onClick: () => history.push('/manageskills'),
      },
      ...(skillId
        ? [{ key: 'skill', text: data?.skill ?? 'Unknown' }]
        : [{ key: 'new', text: 'New' }]),
    ]);
  }, [history, data, skillId]);

  const onSubmit = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = form;
    if (!form.id) {
      create({ payload });
    } else {
      update({ skillId, payload  });
    }
  };

  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this skill?')) {
      deleteSkill(skillId);
    }
  }, [data]);

  return (
    <Page>
      <CommandBar>
        {data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Undo' }}
              text="Discard Changes"
              onClick={() => history.push(`/manageskills`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Delete' }}
              text="Delete Skill"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Update Skill"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!data?.id && (
          <>
            <CommandButton
              iconProps={{ iconName: 'Cancel' }}
              text="Cancel"
              onClick={() => history.push(`/manageskills`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: 'Save' }}
              text="Create Skill"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>{data?.id ? 'Edit Skill' : 'Create New Skill'}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Level 1"
                  value={data?.level1 ? data.level1 + '' : ''}
                  onChange={(_: any, level1: any) =>
                    setData((state: ISkill) => ({ ...state, level1 }))
                  }
                  errorMessage={errors.level1}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Level 2"
                  value={data?.level2 ? data.level2 + '' : ''}
                  onChange={(_: any, level2: any) =>
                    setData((state: ISkill) => ({ ...state, level2 }))
                  }
                  errorMessage={errors.level2}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Level 3"
                  value={data?.level3 ? data.level3 + '' : ''}
                  onChange={(_: any, level3: any) =>
                    setData((state: ISkill) => ({ ...state, level3 }))
                  }
                  errorMessage={errors.level3}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Skill"
                  value={data?.skill ? data.skill + '' : ''}
                  onChange={(_: any, skill: any) =>
                    setData((state: ISkill) => ({ ...state, skill }))
                  }
                  errorMessage={errors.skill}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item>
                <Dropdown
                  label="Timeline"
                  options={timelineDropdownOptions}
                  selectedKey={data?.timeline}
                  style={{ width: 300 }}
                  onChange={(_: any, timeline: any) =>
                    setData((state: ISkill) => ({ ...state, timeline: timeline.text }))
                  }
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Wiki URL"
                  value={data?.wiki_url ? data.wiki_url + '' : ''}
                  onChange={(_: any, wiki_url: any) =>
                    setData((state: ISkill) => ({ ...state, wiki_url }))
                  }
                  errorMessage={errors.wiki_url}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default SkillFormPage;
