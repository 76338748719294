export const opportunityStageTypes: {[key: number]: string} = {
	1: 'Qualify',
	2: 'Qualified',
	3: 'Qualified Out',
	4: 'Proposal Submitted',
	5: 'Shortlisted',
	6: 'Verbally Accepted',
	7: 'Won',
	8: 'Lost',
	9: 'Abandoned'
};

export const opportunityStageTypesIndexLookup = 
	Object
		.entries(opportunityStageTypes)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const opportunityStageTypesDropdownOptions = 
	Object
		.entries(opportunityStageTypes)
		.map(([key, value]) => ({ key: key, text: value }));