import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CommandButton,
  Stack,
  TextField,
  MessageBar,
  MessageBarType,
  DatePicker,
  Label,
  Dropdown,
  ISelectableOption,
  FontIcon,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Paper } from "../Paper";
import { useClientSingle } from "../../hooks/useClients";
import {
  useCreateJobStatus,
  useDeleteJobStatus,
  useJobSingle,
  useJobStatus,
  useJobStatuses,
  useUpdateJobStatus,
} from "../../hooks/useJobs";

import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";
import { useToast } from "providers/ToastContextProvider";
import { jobRagStatusTypesDropdownOptions } from "enums/jobRagStatus";

export const ClientJobStatuseFormPage: React.FC = () => {
  const getLastStatus = (prop: string) => {
    const statuses: any = [...jobStatuses];
    if (statuses?.length > 0) {
      return statuses[statuses.length - 1][prop]
    }
    return '';
  }

  const { setBreadcrumbs } = useBreadcrumbs();
  const { setToastSuccessMessage } = useToast();
  const { statusId, clientId, jobId } = useParams<any>();
  const { jobStatus } = useJobStatus(jobId, statusId);
  const { jobStatuses } = useJobStatuses(jobId);
  const [formState, setFormState] = useState(
    jobStatus ?? {
      overall_rag: getLastStatus('overall_rag'),
      scope_rag: getLastStatus('scope_rag'),
      schedule_rag: getLastStatus('schedule_rag'),
      budget_rag: getLastStatus('budget_rag'),
      status_date: new Date(),
      comments: getLastStatus('comments'),
    }
  );

  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState("");

  const history = useHistory();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);

  const { create, isLoading: isCreating } = useCreateJobStatus({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/statuses`);
      setToastSuccessMessage('Status has been created.')
    },
    onError: () =>
      setGeneralError(
        "An error occurred while trying to create a new job status."
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateJobStatus({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/statuses`);
      setToastSuccessMessage('Status has been updated.')
    },
    onError: () =>
      setGeneralError("An error occurred while trying to update the job status."),
  });
  const { delete: deleteStatus, isLoading: isDeleting } = useDeleteJobStatus({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${jobId}/statuses`);
      setToastSuccessMessage('Status has been deleted.')
    },
    onError: () =>
      setGeneralError("An error occurred while trying to delete the job status."),
  });

  const isSubmitting = useMemo(
    () => isUpdating || isCreating || isDeleting,
    [isCreating, isDeleting, isUpdating]
  );
  
  const isEdit = formState.id || statusId;

  useEffect(() => {
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      {
        key: "client",
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      {
        key: "job",
        text: job.name,
        onClick: () => history.push(`/clients/${client.id}/jobs/${job.id}`),
      },
      ...(isEdit
        ? [{ key: "edit", text: "Edit Status" }]
        : [{ key: "new", text: "New Status" }]),
    ]);
  }, [history, client, job]);

  const onSubmit = useCallback(async () => {
    const errors: any = {};
    if (!formState.overall_rag) {
      errors.overall_rag = "Field is required.";
    }

    if (!formState.scope_rag) {
      errors.scope_rag = "Field is required.";
    }

    if (!formState.schedule_rag) {
      errors.schedule_rag = "Field is required.";
    }

    if (!formState.budget_rag) {
      errors.budget_rag = "Field is required.";
    }

    if (!formState.comments) {
      errors.comments = "Field is required.";
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    const payload: any = {
      overall_rag: +formState.overall_rag,
      scope_rag: +formState.scope_rag,
      schedule_rag: +formState.schedule_rag,
      budget_rag: +formState.budget_rag,
      status_date: onFormatDate(formState.status_date || new Date()),
      comments: formState.comments,
      job_id: +jobId,
    };

    if (!isEdit) {
      create(payload);
    } else {
      update({
        ...payload,
        id: statusId,
      });
    }
  }, [setErrors, setGeneralError, history, formState, clientId, jobId]);

  const onDelete = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete this job status?")) {
      deleteStatus({ jobId, statusId });
    }
  }, [jobId, statusId]);

  const onFormatDate = (date?: Date): string => {
    if (!date) return '';

    const d1 = new Date((date));

    const year = d1.getFullYear();
    const month = ("0" + (d1.getMonth() + 1)).slice(-2)
    const day = ("0" + d1.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  const onRenderTitle = (option: ISelectableOption[]) => {
    const color = option[0].text;
      return (
        <>
          <FontIcon aria-label="CompletedSolidIcon" iconName="Info" style={{ color }}/>
          <span style={{ textTransform: 'capitalize', margin: '3px 0 4px 6px' }}>{color}</span>
        </>
      )
  };

  const onRenderOption = (option: ISelectableOption) => {
    const color = option.text;
      return (
        <>
          <FontIcon aria-label="CompletedSolidIcon" iconName="Info" style={{ 
            color,
            position: 'absolute',
            top: '1px',
          }}/>
          <span style={{ textTransform: 'capitalize', margin: '3px 0px 4px 20px' }}>{color}</span>
        </>
      )
  };

  return (
    <Page>
      <CommandBar>
        {isEdit && (
          <>
            <CommandButton
              iconProps={{ iconName: "Delete" }}
              text="Delete Status"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Update Status"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!isEdit && (
          <>
            <CommandButton
              iconProps={{ iconName: "Cancel" }}
              text="Cancel"
              onClick={() => {
                setToastSuccessMessage('');
                history.push(`/clients/${clientId}/jobs/${jobId}/statuses`)
              }}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Create New Status"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>
            {isEdit ? `Edit Status` : `Create New Status for ${job.name}`}
          </h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item>
                <Dropdown
                  label="Overall RAG"
                  defaultSelectedKey={formState.overall_rag.toString()}
                  options={jobRagStatusTypesDropdownOptions}
                  disabled={isSubmitting}
                  onRenderTitle={(option: any) => onRenderTitle(option)}
                  onRenderOption={(option: any) => onRenderOption(option)}
                  onChange={(_: any, status: any) =>
                    setFormState((state) => ({ ...state, overall_rag: status.key }))
                  }
                  style={{ minWidth: 500 }}
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <Label> Status Date </Label>
                <DatePicker
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  formatDate={onFormatDate}
                  disabled={isSubmitting}
                  value={formState.status_date ? new Date(formState.status_date) : new Date()}
                  onSelectDate={(status_date: any) =>
                    setFormState((state) => ({ ...state, status_date }))
                  }
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={3}>
                <Dropdown
                  label="Scope RAG"
                  defaultSelectedKey={formState.scope_rag.toString()}
                  options={jobRagStatusTypesDropdownOptions}
                  disabled={isSubmitting}
                  onRenderTitle={(option: any) => onRenderTitle(option)}
                  onRenderOption={(option: any) => onRenderOption(option)}
                  onChange={(_: any, status: any) =>
                    setFormState((state) => ({ ...state, scope_rag: status.key }))
                  }
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <Dropdown
                  label="Schedule RAG"
                  defaultSelectedKey={formState.schedule_rag.toString()}
                  options={jobRagStatusTypesDropdownOptions}
                  disabled={isSubmitting}
                  onRenderTitle={(option: any) => onRenderTitle(option)}
                  onRenderOption={(option: any) => onRenderOption(option)}
                  onChange={(_: any, status: any) =>
                    setFormState((state) => ({ ...state, schedule_rag: status.key }))
                  }
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <Dropdown
                  label="Budget RAG"
                  defaultSelectedKey={formState.budget_rag.toString()}
                  options={jobRagStatusTypesDropdownOptions}
                  disabled={isSubmitting}
                  onRenderTitle={(option: any) => onRenderTitle(option)}
                  onRenderOption={(option: any) => onRenderOption(option)}
                  onChange={(_: any, status: any) =>
                    setFormState((state) => ({ ...state, budget_rag: status.key }))
                  }
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={1}>
                <TextField
                  label="Comments"
                  disabled={isSubmitting}
                  value={formState?.comments}
                  onChange={(_: any, comments: any) =>
                    setFormState((state) => ({ ...state, comments }))
                  }
                  errorMessage={errors.comments}
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientJobStatuseFormPage;
