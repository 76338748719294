import { IJobBudget } from "../types/IJobBudget";
import { apiEndpoint } from "./apiEndpoint";
import { standardHeaders } from "./standardHeaders";

export async function createJobBudget(
  jobId: string,
  payload: Partial<IJobBudget>
) {
  const response = await fetch(`${apiEndpoint}/jobs/${jobId}/budgets`, {
    method: "POST",
    headers: await standardHeaders(),
    mode: "cors",
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
