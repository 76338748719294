import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJob } from '../types/IJob';

export async function createJob(
  clientId: string,
  job: Omit<IJob, 'id'>
): Promise<IJob> {
  const response = await fetch(`${apiEndpoint}/jobs`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...job,
      client_id: clientId,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
