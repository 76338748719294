import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobRole } from '../types/IJobRole';

export async function createJobRole(role: Partial<IJobRole>) {
	const response = await fetch(`${apiEndpoint}/jobs/${role.job_id}/roles`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(role)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}