import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getJobInvoiceLines(jobInvoiceId: string) {
  const response = await fetch(
    `${apiEndpoint}/invoices/${jobInvoiceId}/lines`,
    {
      method: 'GET',
      headers: await standardHeaders(),
      mode: 'cors',
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return [];
}
