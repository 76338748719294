import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IFeedback } from 'types/IFeedback';

export async function createFeedback(
  feedback: IFeedback
): Promise<IFeedback> {
  const response = await fetch(`${apiEndpoint}/feedback`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...feedback,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
