import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobRole } from '../types/IJobRole';

export async function updateJobRole(role: Partial<IJobRole>) {
  const response = await fetch(
    `${apiEndpoint}/jobs/${role.job_id}/roles/${role.id}`,
    {
      method: 'PUT',
      headers: await standardHeaders(),
      mode: 'cors',
      body: JSON.stringify(role),
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return null;
}

export async function updateJobRoles(role: Partial<IJobRole>[]) {
  const jobRoles = await Promise.all(role.map((r) => updateJobRole(r)));
  if (jobRoles.every((i) => !!i)) {
    return jobRoles;
  }
  return null;
}
