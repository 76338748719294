import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getSkillRating(memberId: string) {
	const response = await fetch(`${apiEndpoint}/skill_ratings/${memberId}`, {
		method: 'GET',
		headers: await standardHeaders(),
		mode: 'cors'
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}