import React, { useEffect, useState } from 'react';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { jobsFilterables } from '../../enums/jobsFilterables';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useFilterList } from '../../hooks/useFilterList';
import { getJobsContextualMenu, useJobsSummary } from 'hooks/useJobs';
import { jobsPageFields } from 'enums/jobsPageFields';
import { useClients } from 'hooks/useClients';
import { useMembers } from 'hooks/useMembers';
import './JobsPage.css';

export const JobsPage: React.FC = () => {
  const { jobs = [] } = useJobsSummary();
  const { clients = [] } = useClients();
  const { members = [] } = useMembers();
  const [searchQuery, setSearchQuery] = useState('');
  const [menu, setMenu] = useState<any>(null);
  const { filteredList } = useFilterList(jobs, searchQuery);
  const jobsList = filteredList.filter((i) => i.client_id !== 1);

  const isDetailsListVisible = jobs?.length && clients?.length;

  useEffect(() => {
    if (isDetailsListVisible) {
      setMenu(getJobsContextualMenu(jobs, clients, members));
    }
  }, [clients, members, isDetailsListVisible, jobs]);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Jobs</h2>
        </header>
        <Stack horizontal style={{ marginBottom: 16 }}>
          <StackItem>
            <TextField
              value={searchQuery}
              style={{ minWidth: 300 }}
              onChange={(_: any, value: any) => {
                setSearchQuery(value);
              }}
            />
          </StackItem>
        </Stack>
        {(isDetailsListVisible && menu) ? (
          <div className="da-details-list-container jobs-summary-grid">
            <DetailsListWithContextMenu
              items={jobsList}
              columns={jobsPageFields}
              selectionMode={SelectionMode.none}
              contextMenu={menu}
              filterableColumns={jobsFilterables}
              layoutMode={DetailsListLayoutMode.fixedColumns}
            />
          </div>
        ): <></>}
      </Container>
    </Page>
  );
};

export default JobsPage;
