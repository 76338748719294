import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ComboBox,
  DetailsListLayoutMode,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
} from '@fluentui/react';

import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';

import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';

import {
  getCourseStatusesContextualMenu,
  useCourseStatusSummary,
} from 'hooks/useCourseStatuses';

import { courseStatusesFields } from '../../enums/courseStatusesFields';
import { courseStatusesFilterables } from '../../enums/courseStatusesFilterables';
import { Paper } from 'components/Paper';
import { useSubordinateMembers } from 'hooks/useMembers';
import { IMember } from 'types/IMember';
import { IUserAccess } from 'providers/CurrentUserProvider';
import { ICourseStatusSummary } from 'types/ICourseStatus';

import './CoursesPage.css';

export const CoursesPage: React.FC<{ userAccess: IUserAccess }> = (props: {
  userAccess: IUserAccess;
}) => {
  const history = useHistory();
  const { memberId: memberIdParam } = useParams<any>();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { courseStatuses = [], isLoading } = useCourseStatusSummary();
  const { members } = useSubordinateMembers();
  const { toastSuccessMessage } = useToast();
  const menu = getCourseStatusesContextualMenu();

  const [memberId, setMemberId] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const [nullLabel, setNullLabel] = useState<string>('');

  useEffect(() => {
    setBreadcrumbs([]);
  }, [history]);

  useEffect(() => {
    if (!memberIdParam) return;
    setMemberId(parseInt(memberIdParam));
  }, [memberIdParam]);

  useEffect(() => {
    if (!memberId || !courseStatuses || !courseStatuses.length) return;

    const filteredItems = courseStatuses.filter(
      (course: ICourseStatusSummary) => memberId === course.member_id
    );

    if (filteredItems?.length) {
      setItems(filteredItems);
    } else {
      setNullLabel('No Course set.');
    }
  }, [courseStatuses, memberId, memberIdParam]);

  const availableMembers = useMemo(() => {
    return members
      .filter(
        (member: IMember) =>
          member.active &&
          (!props.userAccess?.verifyOwner ||
            props.userAccess?.userId === member.id)
      )
      .map((member: IMember) => ({ key: member.id, text: member.name }))
      .sort(
        (
          a: { key: number; text: string },
          b: { key: number; text: string }
        ) => {
          return a.text > b.text ? 1 : -1;
        }
      );
  }, [members, props.userAccess?.userId, props.userAccess?.verifyOwner]);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Courses</h2>
        </header>
        {toastSuccessMessage && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
            >
              {toastSuccessMessage}
            </MessageBar>
          </div>
        )}
        <Paper>
          <Stack horizontal tokens={{ childrenGap: '1em' }}>
            <Stack.Item style={{ width: '50%' }}>
              <ComboBox
                label="Consultant"
                useComboBoxAsMenuWidth
                allowFreeform={true}
                autoComplete={'on'}
                options={availableMembers}
                selectedKey={memberId}
                onChange={(_: any, option: any) => {
                  setMemberId(option.key);
                }}
                disabled={isLoading}
              />
            </Stack.Item>
          </Stack>
        </Paper>
        <br />
        {items.length ? (
          <div className="da-details-list-container courses-table-wrapper">
            <DetailsListWithContextMenu
              columns={courseStatusesFields}
              items={items}
              selectionMode={SelectionMode.none}
              onActiveItemChanged={(item) =>
                item && history.push(`/courses/${item.id}/edit`)
              }
              contextMenu={menu}
              filterableColumns={courseStatusesFilterables}
              layoutMode={DetailsListLayoutMode.fixedColumns}
            />
          </div>
        ) : (
          nullLabel
        )}
      </Container>
    </Page>
  );
};

export default CoursesPage;
