export const ClientIndustry: {[key: number]: { label: string, shortName: string }} = {
  1: { label: 'Not for profit', shortName: 'NFP' },
  2: { label: 'Retail trade', shortName: 'RET' },
  3: { label: 'Local Government', shortName: 'LGV' },
  4: { label: 'Agriculture, forestry and fishing', shortName: 'AGR' },
  5: { label: 'Manufacturing', shortName: 'MFG' },
  6: { label: 'Mining', shortName: 'MNG' },
  7: { label: 'Electricity Gas Water And Waste Services', shortName: 'EGW' },
  8: { label: 'Construction', shortName: 'CON' },
  9: { label: 'Wholesale Trade', shortName: 'WTD' },
  10: { label: 'Accommodation And Food Services', shortName: 'AFS' },
  11: { label: 'Transport Postal And Warehousing', shortName: 'TPW' },
  12: { label: 'Information Media And Telecommunications', shortName: 'ITM' },
  13: { label: 'Financial And Insurance Services', shortName: 'FSI' },
  14: { label: 'Rental Hiring And Real Estate Services', shortName: 'RES' },
  15: { label: 'Professional Scientific And Technical Services', shortName: 'PST' },
  16: { label: 'Administrative And Support Services', shortName: 'ADS' },
  17: { label: 'Public Administration And Safety', shortName: 'PAS' },
  18: { label: 'Education And Training', shortName: 'EDT' },
  19: { label: 'Health Care And Social Assistance', shortName: 'HCS' },
  20: { label: 'Arts And Recreation Services', shortName: 'ATR' },
  21: { label: 'Other Services', shortName: 'OSS' },
};

export const ClientIndustryDropdownOptions =
  Object
    .entries(ClientIndustry)
    .map(([key, value]) => ({ key: key, text: value.label }));
