import { useState } from 'react';
import { IMember } from '../types/IMember';
import { getMe } from '../resources/getMembers';

export function useGetCurrentUser() {
  const [currentUser, setCurrentUser] = useState<IMember | null>();
  const [loading, setLoading] = useState<boolean>();

  const refreshUser = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const result = await getMe();
      setCurrentUser(result);
    } catch (_) {
      setCurrentUser(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    currentUser,
    refreshUser,
  };
}
