import React, { useContext, useState } from 'react';

type ToastState = {
  milestoneSuccessMessage: string;
  setMilestoneSuccessMessage: any;
  toastSuccessMessage: string;
  setToastSuccessMessage: any;
};

const ToastContext = React.createContext<ToastState>({
  milestoneSuccessMessage: '',
  setMilestoneSuccessMessage: () => {},
  toastSuccessMessage: '',
  setToastSuccessMessage: () => {},
});

const ToastContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [milestoneSuccessMessage, setMilestoneSuccessMessage] = useState('');
  const [toastSuccessMessage, setToastSuccessMessage] = useState('');

  return (
    <ToastContext.Provider
      value={{
        milestoneSuccessMessage,
        setMilestoneSuccessMessage,
        toastSuccessMessage,
        setToastSuccessMessage,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export { ToastContext, ToastContextProvider, useToast };
