import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorker from './serviceWorker';

import { LocalStorageProvider } from './providers/LocalStorageProvider';
import { initializeTheme } from './theme';
import AuthProvider from './providers/AuthProvider';
import { FluentThemeContextProvider } from './providers/FluentThemeProvider';
import ErrorBoundary from './components/utils/ErrorBoundary';
import Maintenance from './components/Maintenance';
import Main from './components/apps/Main';
import { UrlListener } from './components/utils/UrlListener';
import './index.css';
import { AppContextProvider } from './providers/AppContextProvider';
import { BreadcrumbsContextProvider } from './providers/BreadcrumbsProvider';
import { CurrentUserContextProvider } from './providers/CurrentUserProvider';
import { ToastContextProvider } from './providers/ToastContextProvider';

initializeTheme();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 900000,
      suspense: true,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <ErrorBoundary errorComponent={Maintenance}>
        <Router>
          <UrlListener />
          <QueryClientProvider client={queryClient}>
            <FluentThemeContextProvider>
              <LocalStorageProvider>
                <AuthProvider>
                  <BreadcrumbsContextProvider>
                    <CurrentUserContextProvider>
                      <ToastContextProvider>
                        <Switch>
                          <Route path={'/'} component={Main} />
                          <Redirect to={'/'} />
                        </Switch>
                      </ToastContextProvider>
                    </CurrentUserContextProvider>
                  </BreadcrumbsContextProvider>
                </AuthProvider>
              </LocalStorageProvider>
            </FluentThemeContextProvider>
          </QueryClientProvider>
        </Router>
      </ErrorBoundary>
    </AppContextProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
