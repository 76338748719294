import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IClient } from '../types/IClient';

export async function updateClient(client: IClient) {
	const response = await fetch(`${apiEndpoint}/clients/${client.id}`, {
		method: 'PUT',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(client)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}