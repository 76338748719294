import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ICourse } from 'types/ICourse';

export async function createCourse(
  course: ICourse
): Promise<ICourse> {
  const response = await fetch(`${apiEndpoint}/courses`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...course,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
