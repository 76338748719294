import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ISaveRowInput } from '../types/ISaveRowInput';

export async function saveRow(payload: ISaveRowInput) {
	const response = await fetch(`${apiEndpoint}/timesheet`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(payload)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}