import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getTimesheets(start_date: string, end_date: string, member_id?: number) {

	const params = new URLSearchParams();
	params.set('start_date', start_date);
	params.set('end_date', end_date);

	if (typeof member_id !== 'undefined') {
		params.set('member_id', member_id + '');
	}

	const response = await fetch(`${apiEndpoint}/timesheet?${params.toString()}`, {
		method: 'GET',
		headers: await standardHeaders(),
		mode: 'cors'
	});
	if (response.ok) {
		return await response.json();
	}
	return [];
}