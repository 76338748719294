/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { IDropdownOption } from '@fluentui/react';
import moment from 'moment';
import { TIMESHEETS_QUERY_KEYS } from '../constants/queries';
import {
  IMutateOption,
  IQueryResult,
  IUpdateResult,
} from '../types/IQueryResult';
import { ITimesheetGridData } from '../types/ITimesheetGridData';
import { ITimesheetOptions } from '../types/ITimesheetOptions';
import { getTimesheets } from '../resources/getTimesheets';
import { getTimesheetOptions } from '../resources/getTimesheetOptions';
import { saveTimesheet } from '../resources/saveTimesheet';
import { ITimesheet } from 'types/ITimesheet';
import { ITimesheetsOptions } from 'types/ITimesheetsOptions';
import { saveTimesheets } from 'resources/saveTimesheets';

export function useTimesheetsOnly(filter: {
  startDate: string;
  endDate: string;
  memberId?: number;
}): IQueryResult & {
  timesheets: ITimesheet[];
} {
  const {
    data: timesheets,
    isLoading,
    error,
  } = useQuery(
    TIMESHEETS_QUERY_KEYS.getTimesheets(filter),
    async ({ queryKey }) => {
      const [_, filter] = queryKey;
      const options = filter as {
        startDate: string;
        endDate: string;
        memberId?: number;
      };
      if (options.startDate && options.endDate) {
        const result = await getTimesheets(
          options.startDate,
          options.endDate,
          options.memberId
        );
        return result;
      }
      return [];
    },
    {
      enabled: !!filter,
    }
  );

  return { timesheets, isLoading, error };
}

export function useTimesheets(filter: ITimesheetOptions): IQueryResult & {
  data: ITimesheetGridData | undefined;
  refetch: () => void;
} {
  const { data, isLoading, error, refetch } = useQuery(
    TIMESHEETS_QUERY_KEYS.getData(filter),
    async ({ queryKey }) => {
      const [_, filter] = queryKey;
      const options = filter as ITimesheetOptions;
      if (options.memberId && options.startDateVal && options.endDateVal) {
        const [timesheets, job_roles] = await Promise.all([
          getTimesheets(
            options.startDateVal,
            options.endDateVal,
            options.memberId
          ),
          getTimesheetOptions(options.memberId),
        ]);
        const filteredJobRoles = job_roles.map((role: any) => {
          const { hours_budget, hours_consumed } = role || {};
          let hours_remaining = hours_budget || 0;

          if (hours_budget && hours_budget !== -1) {
            hours_remaining = hours_budget - hours_consumed;
          }

          return {
            ...role,
            hours_budget: hours_budget || 0,
            hours_remaining,
          };
        });

        return {
          id: options.memberId,
          timesheets,
          job_roles: filteredJobRoles,
        };
      }
      return {
        id: null,
        timesheets: [],
        job_roles: [],
      };
    },
    {
      enabled: !!filter,
    }
  );

  return { data, isLoading, error, refetch };
}

export function getTimesheetDateOptions(): IDropdownOption[] {
  const current = moment();
  const currentYear = current.year();
  const currentMonth = current.month();
  const dates: any = [];
  for (let i = currentYear + 2; i >= 2019; i--) {
    const startingMonth = i === currentYear + 2 ? currentMonth + 1 : 12;
    for (let j = startingMonth; j > 0; j--) {
      const formatted = moment(i + '-' + j, 'YYYY-M').format('YYYY MMM');
      dates.push({
        key: formatted,
        text: formatted,
      });
    }
  }
  return dates;
}

export function useTimesheetDataGrid() {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [gridData, setGridData] = useState<ITimesheetGridData>({
    id: null,
    timesheets: [],
    job_roles: [],
  });

  const [timesheetState, setTimesheetState] = useState<ITimesheetOptions>({
    memberId: undefined,
    startDate: moment().format('YYYY MMM'),
    endDate: moment().add(2, 'months').format('YYYY MMM'),
  });

  const [timesheetsState, setTimesheetsState] = useState<ITimesheetsOptions>({
    roleId: undefined,
    startDate: moment().startOf("isoWeek").toDate(),
    daysOfWeek: [],
    numberOfWeeks: 1,
  });

  const timesheetStateInternal = useMemo(() => {
    return {
      ...timesheetState,
      startDateVal: moment(timesheetState.startDate, 'YYYY MMM')
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDateVal: moment(timesheetState.endDate, 'YYYY MMM')
        .endOf('month')
        .format('YYYY-MM-DD'),
    };
  }, [timesheetState]);

  const setTimesheetStateInternal = (newState: ITimesheetOptions) => {
    setTimesheetState((prev) => ({ ...prev, ...newState }));
  };

  const setTimesheetsStateInternal = (newState: ITimesheetsOptions) => {
    setTimesheetsState((prev) => ({ ...prev, ...newState }));
  };

  const dirty = useMemo(() => {
    return rows?.some((r: any) => r.dirty || r.forDeletion);
  }, [rows]);

  const errors = useMemo(() => {
    return rows
      .filter((row: any) => {
        return (
          row.dirty &&
          (!row.job_role ||
            !row.hours ||
            !row.status ||
            isNaN(parseFloat(row.hours)))
        );
      })
      .map((row: any) => {
        let output = [`${row.date}:`];
        if (!row.job_role) {
          output.push('Job is required.');
        }
        if (!row.status) {
          output.push('Status is required.');
        }
        if (!row.hours) {
          output.push('Hours is required.');
        } else if (isNaN(parseFloat(row.hours))) {
          output.push('Hours is not a valid number.');
        }
        return output.join(' ');
      });
  }, [rows]);

  return {
    dirty,
    errors,
    rows,
    setRows,
    cols,
    setCols,
    gridData,
    setGridData,
    timesheetState: timesheetStateInternal,
    timesheetsState,
    setTimesheetState: setTimesheetStateInternal,
    setTimesheetsState: setTimesheetsStateInternal,
  };
}

export function useSaveTimesheet(opts: IMutateOption<any>): IUpdateResult<any> {
  const { mutate, isLoading, error } = useMutation(
    (payload: { rows: any[]; cols: any[]; jobRoles: any[]; statuses: any[] }) =>
      saveTimesheet(
        payload.rows,
        payload.cols,
        payload.jobRoles,
        payload.statuses
      ),
    {
      onSuccess: (data: any) => {
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { update: mutate, isLoading, error };
}

export function useSaveTimesheets(opts: IMutateOption<any>): IUpdateResult<any> {
  const { mutate, isLoading, error } = useMutation(
    (payload: { rows: any[]; timesheets: any[] }) =>
      saveTimesheets(
        payload.rows,
        payload.timesheets,
      ),
    {
      onSuccess: (data: any) => {
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { update: mutate, isLoading, error };
}
