import React, { useContext, useEffect, useState } from 'react';
import { AccountInfo, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import * as microsoftTeams from '@microsoft/teams-js';
import Splash from '../components/Splash';
import jwtDecode from 'jwt-decode';
import { AppContext } from './AppContextProvider';

export const msalConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY_URL ?? '',
    clientId: process.env.REACT_APP_CLIENT_ID ?? '',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['openid', 'User.Read'],
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.enableAccountStorageEvents();

enum AuthenticationState {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

type AuthStateProps = {
  accountInfo?: AccountInfo | null;
  authenticationState: AuthenticationState;
  error?: string;
};

export const context = React.createContext<AuthStateProps>({
  authenticationState: AuthenticationState.Unauthenticated,
});

type TokenInfo = {
  name: string;
  preferred_username: string;
};

const loginToTeams = async (): Promise<string> => {
  return new Promise((resolve) => {
    microsoftTeams.authentication.getAuthToken({
      successCallback: (token: string) => {
        microsoftTeams.appInitialization.notifySuccess();
        resolve(token);
      },
      failureCallback: (message: string) => {
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          message,
        });
        resolve('');
      },
    });
  });
};

const removeTeamsToken = (): boolean => {
  if (!window?.localStorage) return false;
  if (window.localStorage.getItem('TEAMS_TOKEN')) {
    window.localStorage.removeItem('TEAMS_TOKEN');
  }
  return true;
};

const persistTeamsToken = (token: string) => {
  if (removeTeamsToken()) {
    window.localStorage.setItem('TEAMS_TOKEN', token);
  }
};

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [teamsToken, setTeamsToken] = useState('');
  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const { isInTeams } = useContext(AppContext);

  useEffect(() => {
    if (isInTeams) {
      loginToTeams().then((token) => {
        if (!token) return;
        persistTeamsToken(token);
        setTeamsToken(token);
        const tokenInfo = jwtDecode<TokenInfo>(token);
        if (!tokenInfo) return;
        setAccountInfo({
          username: tokenInfo.preferred_username,
          name: tokenInfo.name,
        } as any);
      });
    }
  }, [isInTeams]);

  if (!isInTeams) {
    removeTeamsToken();
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: any) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event?.payload?.account
      ) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        setAccountInfo(account);
      }
    });

    msalInstance.handleRedirectPromise().then(() => {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        msalInstance.loginRedirect();
      }
    });

    return (
      <MsalProvider instance={msalInstance}>
        {!accounts?.[0] && <Splash />}
        <context.Provider
          value={{
            accountInfo: msalInstance.getActiveAccount(),
            authenticationState: AuthenticationState.Authenticated,
          }}
        >
          {children}
        </context.Provider>
      </MsalProvider>
    );
  }

  if (!accountInfo || !teamsToken) return null;

  const value = {
    accountInfo,
    authenticationState: AuthenticationState.Authenticated,
    error: undefined,
  };
  return <context.Provider value={value}>{children}</context.Provider>;
};

export default AuthProvider;
