export const jobsFilterables = [
  'name',
  'client_id',
  'project',
  'project_lead_id',
  'statusText',
  'days_this_month',
  'days_next_month',
  'overall_rag',
  'status_date',
];
