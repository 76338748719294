import { useLayoutEffect, useRef } from 'react';
import { FillEvent, PasteEvent } from 'react-data-grid';
import { TreeViewRow } from './types';

export const defaultFillHandler = (event: FillEvent<any>) => {
  const value = event.sourceRow[event.columnKey];
  return event.targetRows.map((tr) => ({ ...tr, [event.columnKey]: value }));
};

export function defaultPasteHandler<T = {}>({
  sourceColumnKey,
  sourceRow,
  targetColumnKey,
  targetRow,
}: PasteEvent<T>) {
  return {
    ...targetRow,
    [targetColumnKey]: sourceRow[sourceColumnKey as keyof T],
  };
}

export function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (!isSelected) return;
    ref.current?.focus({ preventScroll: true });
  }, [isSelected]);

  return {
    ref,
    tabIndex: isSelected ? 0 : -1,
  };
}

export function toggleSubRow(
  rows: TreeViewRow[],
  id: string,
  includeGrandChild = false
): TreeViewRow[] {
  const rowIndex = rows.findIndex((r) => r.id === id);
  const row = rows[rowIndex];
  const { children } = row;
  if (!children?.length) return rows;

  let newRows = [...rows];
  newRows[rowIndex] = { ...row, isExpanded: !row.isExpanded };
  if (!row.isExpanded) {
    if (includeGrandChild) {
      const result = [] as TreeViewRow[];
      expandChildrenNodes(children, result);
      newRows.splice(rowIndex + 1, 0, ...result);
    } else {
      newRows.splice(rowIndex + 1, 0, ...children);
    }
  } else {
    const itemsToRemove = [] as string[];
    for (let i = 0; i < newRows.length; i++) {
      const currentRow = newRows[i];
      if (currentRow.parentId === id) {
        itemsToRemove.push(currentRow.id);
        continue;
      }
      if (itemsToRemove.includes(currentRow.parentId!)) {
        itemsToRemove.push(currentRow.id);
        continue;
      }
    }
    newRows = newRows.filter((r) => !itemsToRemove.includes(r.id));
  }
  return newRows;
}

function expandChildrenNodes(rows: TreeViewRow[], result: TreeViewRow[]) {
  for (const row of rows) {
    result.push({ ...row, isExpanded: true });
    if (row.children?.length) {
      expandChildrenNodes(row.children, result);
    }
  }
}
