import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function deleteJobStatus(jobId: string, statusId: string) {
  const response = await fetch(`${apiEndpoint}/jobs/${jobId}/statuses/${statusId}`, {
    method: 'DELETE',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
