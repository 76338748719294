import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  CommandButton,
  SelectionMode,
  Stack,
  StackItem,
  TextField,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { clientsFields } from "../../enums/clientsFields";
import DetailsListWithContextMenu from "../DetailsListWithContextMenu";
import { useFilterList } from "../../hooks/useFilterList";
import { useClients } from "../../hooks/useClients";
import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";

export const ClientsPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { clients = [] } = useClients();
  const [searchQuery, setSearchQuery] = useState("");
  const { filteredList } = useFilterList(clients!, searchQuery);

  useEffect(() => {
    if (setBreadcrumbs) setBreadcrumbs([]);
  }, []);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Clients</h2>
          <CommandButton
            iconProps={{ iconName: "Add" }}
            text="New Client"
            onClick={() => history.push(`${location.pathname}/new`)}
          />
        </header>
        <Stack horizontal style={{ marginBottom: 16 }}>
          <StackItem>
            <TextField
              value={searchQuery}
              style={{ minWidth: 300 }}
              onChange={(_: any, value: any) => {
                setSearchQuery(value);
              }}
            />
          </StackItem>
        </Stack>
        <div className="da-details-list-container">
          <DetailsListWithContextMenu
            items={filteredList}
            columns={clientsFields}
            selectionMode={SelectionMode.none}
            onActiveItemChanged={(item) =>
              history.push(`${location.pathname}/${item.id}`)
            }
            contextMenu={{
              sortStack: ["-active_jobs_count"],
              filters: {},
              checkboxFilters: {},
            }}
          />
        </div>
      </Container>
    </Page>
  );
};

export default ClientsPage;
