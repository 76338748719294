import { IColumn } from '@fluentui/react';
import React from 'react';

export interface IDAColumnHrefOptions {
  getUrl: (item?: any, _?: number, column?: IColumn) => string;
  /** Override link's child with any React component or as simple as string */
  getChild?: (
    item?: any,
    _?: number,
    column?: IColumn
  ) => string | React.ReactNode;
  linkProps?: any;
}

export interface IDAColumnCellTooltipOptions {
  getContent: (item?: any, _?: number, column?: IColumn) => string;
  tooltipProps?: string;
}

export enum DAAligmentType {
  CENTER = 'center',
  RIGHT = 'right',
  LEFT = 'left',
}

export interface IDAColumn extends IColumn {
  href?: string | IDAColumnHrefOptions;

  /** Column text alignment. Default value is LEFT */
  columnAlign?: DAAligmentType;

  /** Row text alignment. Default value is LEFT */
  rowAlign?: DAAligmentType;

  /** Prevent applying of default value. Default value is false */
  noDefaultStyle?: boolean;

  /** Prevent applying of default column click behavior (mostly for sorting). Default value is false */
  noDefaultColumnClick?: boolean;

  cellTooltip?: IDAColumnCellTooltipOptions;
}
