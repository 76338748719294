import React, { useState, useEffect, useContext } from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { ThemeProvider, Theme } from '@fluentui/react';
import {
  AzureThemeDark,
  AzureThemeHighContrastDark,
} from '@fluentui/azure-themes';
import { AppContext } from './AppContextProvider';
import { initializeTheme } from '../theme';

type ThemeType = {
  default: Theme;
  dark: Theme;
  contrast: Theme;
};

const defaultTheme = initializeTheme();

const THEMES: ThemeType = {
  default: defaultTheme,
  dark: AzureThemeDark,
  contrast: AzureThemeHighContrastDark,
};

type ThemeState = {
  theme: Theme;
  changeTheme: (value: string | null | undefined) => void;
  themeString?: string | null | undefined;
};

const FluentThemeContext = React.createContext<ThemeState>({
  changeTheme: () => {},
  theme: defaultTheme,
});

const FluentThemeContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [theme, setTheme] = useState(defaultTheme);
  const { teamsTheme } = useContext(AppContext);
  const [themeString, setThemeString] = useState<string | null | undefined>(
    'default'
  );

  const changeTheme = (value: string | null | undefined) => {
    switch (value) {
      case 'dark':
        setTheme(THEMES.dark);
        setThemeString('dark');
        break;
      case 'contrast':
        setTheme(THEMES.contrast);
        setThemeString('contrast');
        break;
      default:
        setTheme(THEMES.default);
        setThemeString('default');
        break;
    }
  };

  microsoftTeams.initialize();
  microsoftTeams.registerOnThemeChangeHandler((theme) => {
    changeTheme(theme);
  });

  useEffect(() => {
    changeTheme(teamsTheme);
  }, [teamsTheme]);

  return (
    <FluentThemeContext.Provider value={{ themeString, theme, changeTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </FluentThemeContext.Provider>
  );
};

export { FluentThemeContext, FluentThemeContextProvider };
