import { FontIcon, IColumn } from '@fluentui/react';
import {
  DAAligmentType,
  IDAColumn,
  IDAColumnHrefOptions,
} from 'types/IDADetailsList';
import { jobRagStatus } from './jobRagStatus';
import { HOURS_PER_DAY } from './jobRoleBudgetFields';

const jobsHref: IDAColumnHrefOptions = {
  getUrl: (item?: any, _?: number) =>
    `clients/${item.client_id}/jobs/${item.id}`,
};

const toHoursPerDay = {
  ...jobsHref,
  getChild: (item?: any, _?: number, column?: IColumn) =>
    (item[column?.fieldName as string] ?? 0) / HOURS_PER_DAY,
};

const renderStatus = (status?: number) => {
  if (status === null || status === undefined) return 'N/A';

  const color = jobRagStatus[status];

  if (!color) return 'N/A';

  return (
    <>
      <FontIcon
        aria-label="CompletedSolidIcon"
        iconName="InfoSolid"
        style={{ color }}
      />
    </>
  );
};

export const jobsPageFields: IDAColumn[] = [
  {
    key: 'name',
    fieldName: 'name',
    name: 'Job',
    minWidth: 115,
    maxWidth: 115,
    isResizable: true,
    href: {
      ...jobsHref,
      getChild: (item?: any) => `${item.clientInitials} - ${item.name}`,
    },
    cellTooltip: {
      getContent: (item?: any) => `${item.clientName} - ${item.name}`,
    },
  },
  {
    key: 'project_lead_id',
    fieldName: 'projectLeadInitials',
    name: 'PL',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: jobsHref,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    cellTooltip: {
      getContent: (item?: any) => item.projectLead,
    },
  },
  {
    key: 'account_manager_id',
    fieldName: 'accountManagerInitials',
    name: 'AM',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: jobsHref,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    cellTooltip: {
      getContent: (item?: any) => item.accountManager,
    },
  },
  {
    key: 'project_manager_id',
    fieldName: 'projectManagerInitials',
    name: 'PM',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: jobsHref,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    cellTooltip: {
      getContent: (item?: any) => item.projectManager,
    },
  },
  {
    key: 'statusText',
    fieldName: 'statusText',
    name: 'Status',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    href: jobsHref,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_budget',
    fieldName: 'hours_budget',
    name: 'Budget',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: toHoursPerDay,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_booked',
    fieldName: 'hours_booked',
    name: 'Booked',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: toHoursPerDay,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_consumed',
    fieldName: 'hours_consumed',
    name: 'Consumed',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: toHoursPerDay,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_this_month',
    fieldName: 'hours_this_month',
    name: 'This Month',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    isSorted: true,
    isSortedDescending: true,
    href: toHoursPerDay,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'hours_next_month',
    fieldName: 'hours_next_month',
    name: 'Next Month',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    href: toHoursPerDay,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
  },
  {
    key: 'overall_rag',
    fieldName: 'overall_rag',
    name: 'Overall RAG',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    cellTooltip: {
      getContent: (item?: any) => item.comments || 'N/A',
    },
    onRender: (item?: any) => renderStatus(item.overall_rag),
  },
  {
    key: 'status_date',
    fieldName: 'status_date',
    name: 'Status date',
    minWidth: 47,
    maxWidth: 47,
    isResizable: true,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    cellTooltip: {
      getContent: (item?: any) => item.comments || 'N/A',
    },
    onRender: (item?: any) => {
      return item.status_date || 'N/A';
    },
  },
];
