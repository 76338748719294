import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ICourse } from 'types/ICourse';

export async function updateCourse(courseId: string, course: ICourse) {
  const { id: _, ...rest } = course;
  const response = await fetch(`${apiEndpoint}/courses/${courseId}`, {
    method: 'PUT',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...rest,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
