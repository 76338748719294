import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ILinkProps, Link as FluentLink } from '@fluentui/react';
import './Link.css';

const Link: React.FC<ILinkProps> = ({
  href,
  underline = false,
  children,
  className,
  ...props
}) => {
  return (
    <FluentLink
      as={RouterLink}
      to={href || ''}
      underline={underline}
      className={`da-link ${className || ''}`}
      {...props}
    >
      {children}
    </FluentLink>
  );
};

export default Link;
