import { IDAColumn } from 'types/IDADetailsList';

export const coursesFields: IDAColumn[] = [
  {
    key: 'level1',
    fieldName: 'level1',
    name: 'Level 1',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'level2',
    fieldName: 'level2',
    name: 'Level 2',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'course',
    fieldName: 'course',
    name: 'Course',
    minWidth: 300,
    maxWidth: 350,
  },
  {
    key: 'wiki_url',
    fieldName: 'wiki_url',
    name: 'Wiki URL',
    minWidth: 300,
    maxWidth: 800,
  },
];
