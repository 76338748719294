import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  CommandButton,
  FontIcon,
  IColumn,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  SelectionMode,
} from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { ClientJobBudget } from '../ClientJobBudget';
import { ClientTimesheets } from '../ClientTimesheets';
import { Paper } from '../Paper';
import { jobRolesFields } from '../../enums/jobRolesFields';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useClientSingle } from '../../hooks/useClients';
import { useJobMilestones, useJobSingle, useJobStatuses } from '../../hooks/useJobs';
import { useMemberSingle } from '../../hooks/useMembers';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { financialPermissions } from '../../enums/permissions';
import NoAccess from '../../components/NoAccess';
import { milestonesFields } from 'enums/milestonesFields';
import { milestoneStatus } from 'enums/milestoneStatus';
import { useToast } from 'providers/ToastContextProvider';
import { jobStatusesFields } from 'enums/jobStatusesFields';
import { jobRagStatus } from 'enums/jobRagStatus';

export const ClientJobPage: React.FC = () => {
  const { clientId, jobId } = useParams<any>();
  const {
    milestoneSuccessMessage,
    setMilestoneSuccessMessage,
    toastSuccessMessage,
    setToastSuccessMessage,
  } = useToast();
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);
  const { jobMilestones } = useJobMilestones(jobId);
  const { jobStatuses } = useJobStatuses(jobId);
  const { member: projectLead } = useMemberSingle(job?.project_lead_id);
  const { member: accountManager } = useMemberSingle(job?.account_manager_id);
  const { member: projectManager } = useMemberSingle(job?.project_manager_id);
  const { setBreadcrumbs } = useBreadcrumbs();
  const [activeTab, setActiveTab] = useState('details');

  const canViewFinancial = useMemo(() => {
    if (!currentUser) return false;
    const userPermissions = currentUser.access_rules?.map((r) => r.role) ?? [];
    return (
      userPermissions.includes(financialPermissions.editAll) ||
      (userPermissions.includes(financialPermissions.editMe) &&
        currentUser.id === job?.project_lead_id)
    );
  }, [currentUser, job]);

  useEffect(() => {
    if (!job && client) {
      history.push(`/clients/${client.id}`);
    } else if (!job && !client) {
      history.push(`/clients`);
    }
  }, [job, client, history]);

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'clients',
        text: 'Clients',
        onClick: () => history.push('/clients'),
      },
      {
        key: 'client',
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      { key: 'job', text: job.name },
    ]);
  }, [history, client, job]);

  useEffect(() => {
    if (location) {
      const key = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
      );
      switch (key) {
        case 'roles':
        case 'timesheet':
        case 'financials':
          setActiveTab(key);
          setMilestoneSuccessMessage('');
          setToastSuccessMessage('');
          break;
        case 'milestones':
          setActiveTab(key);
          setToastSuccessMessage('');
          break;
        case 'statuses':
          setActiveTab(key);
          setMilestoneSuccessMessage('');
          break;
        default:
          setActiveTab('details');
          break;
      }
    }
  }, [location]);

  const ColumnRender = (item?: any, column?: IColumn) => {
    if (!column?.fieldName) return;

    const statuses = [
      'status',
      'overall_rag',
      'scope_rag',
      'schedule_rag',
      'budget_rag',
    ]

    if (statuses.includes(column?.fieldName)) {
      const statusColors = activeTab === 'milestones' ? milestoneStatus : jobRagStatus;
      const color = statusColors[item[column?.fieldName]];
      
      return (
        <>
          <FontIcon
            aria-label="CompletedSolidIcon"
            iconName="Info"
            style={{ color }}
          />
          <span
            style={{ textTransform: 'capitalize', margin: '3px 0 4px 6px' }}
          >
            {color}
          </span>
        </>
      );
    }

    return <span>{item[column?.fieldName]}</span>;
  };

  return (
    <Page>
      <CommandBar>
        {
          activeTab === 'details' && (
            <CommandButton
              iconProps={{ iconName: 'Edit' }}
              text="Edit Details"
              onClick={() => history.push(`${location.pathname}/edit`)}
            />
          )
        }
      </CommandBar>
      <Container>
        <header>
          <h2>{job.name}</h2>
        </header>
        <div className="da-tabs">
          <Pivot
            headersOnly
            selectedKey={activeTab}
            onLinkClick={(item) => {
              const url =
                item?.props.itemKey! === 'details'
                  ? `/clients/${client.id}/jobs/${jobId}`
                  : `/clients/${client.id}/jobs/${jobId}/${item?.props
                      .itemKey!}`;
              history.push(url);
            }}
          >
            <PivotItem headerText="General" itemKey="details" />
            <PivotItem headerText="Job Roles" itemKey="roles" />
            <PivotItem headerText="Timesheet" itemKey="timesheet" />
            <PivotItem headerText="Milestones" itemKey="milestones" />
            <PivotItem headerText="Statuses" itemKey="statuses" />
            {canViewFinancial && (
              <PivotItem headerText="Financials" itemKey="financials" />
            )}
          </Pivot>
        </div>
        {activeTab === 'details' && (
          <Paper>
            <ul className="da-unordered-list">
              <li>
                <h4>ID</h4>
                <p>{job.id}</p>
              </li>
              <li>
                <h4>Status</h4>
                <p>{job.statusText}</p>
              </li>
              <li>
                <h4>Reference</h4>
                <p>{job.reference}</p>
              </li>
              <li>
                <h4>Business Unit</h4>
                <p>{job.business_unit_text}</p>
              </li>
              <li>
                <h4>Project Lead</h4>
                <p>{projectLead?.name}</p>
              </li>
              <li>
                <h4>Account Manager</h4>
                <p>{accountManager?.name}</p>
              </li>
              <li>
                <h4>Project Manager</h4>
                <p>{projectManager?.name}</p>
              </li>
            </ul>
          </Paper>
        )}
        {activeTab === 'roles' && (
          <>
            <CommandButton
              iconProps={{ iconName: 'InternalInvestigation' }}
              text="Add Job Role"
              onClick={() =>
                history.push(`/clients/${clientId}/jobs/${jobId}/roles/new`)
              }
              style={{ marginBottom: 16 }}
            />
            <div className="da-details-list-container">
              <DetailsListWithContextMenu
                items={job.roles ?? []}
                columns={jobRolesFields}
                selectionMode={SelectionMode.none}
                onActiveItemChanged={(role) =>
                  history.push(
                    `/clients/${clientId}/jobs/${jobId}/roles/${role.id}`
                  )
                }
              />
            </div>
          </>
        )}
        {activeTab === 'timesheet' && <ClientTimesheets jobId={jobId} />}
        {activeTab === 'financials' && (
          <>{canViewFinancial ? <ClientJobBudget /> : <NoAccess />}</>
        )}
        {activeTab === 'milestones' && (
          <>
            <CommandButton
              iconProps={{ iconName: 'InternalInvestigation' }}
              text="Add Milestone"
              onClick={() =>
                history.push(`/clients/${clientId}/jobs/${jobId}/milestone/new`)
              }
              style={{ marginBottom: 10 }}
            />
            {milestoneSuccessMessage && (
              <div style={{ marginBottom: 16 }}>
                <MessageBar
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  {milestoneSuccessMessage}
                </MessageBar>
              </div>
            )}
            <div className="da-details-list-container">
              <DetailsListWithContextMenu
                items={jobMilestones}
                columns={milestonesFields}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={(
                  item?: any,
                  _?: number,
                  column?: IColumn
                ) => ColumnRender(item, column)}
                onActiveItemChanged={(milestone) =>
                  history.push(
                    `/clients/${clientId}/jobs/${jobId}/milestone/${milestone.id}/edit`
                  )
                }
              />
            </div>
          </>
        )}
        {activeTab === 'statuses' && (
          <>
            <CommandButton
              iconProps={{ iconName: 'InternalInvestigation' }}
              text="Add Status"
              onClick={() =>
                history.push(`/clients/${clientId}/jobs/${jobId}/status/new`)
              }
              style={{ marginBottom: 10 }}
            />
            {toastSuccessMessage && (
              <div style={{ marginBottom: 16 }}>
                <MessageBar
                  messageBarType={MessageBarType.success}
                  isMultiline={false}
                >
                  {toastSuccessMessage}
                </MessageBar>
              </div>
            )}
            <div className="da-details-list-container">
              <DetailsListWithContextMenu
                items={jobStatuses}
                columns={jobStatusesFields}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={(
                  item?: any,
                  _?: number,
                  column?: IColumn
                ) => ColumnRender(item, column)}
                onActiveItemChanged={(status) =>
                  history.push(
                    `/clients/${clientId}/jobs/${jobId}/status/${status.id}/edit`
                  )
                }
              />
            </div>
          </>
        )}
      </Container>
    </Page>
  );
};

export default ClientJobPage;
