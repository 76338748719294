import { Text } from '@fluentui/react';
import './NoAccess.css';

const NoAccess = () => (
  <div className="no-access">
    <Text variant="mega" color="error" nowrap block>
      403
    </Text>
    <Text variant="xxLarge" nowrap block>
      Access Denied
    </Text>
    <Text variant="large" nowrap block>
      Sorry, but you don't have permission to access this page.
    </Text>
  </div>
);

export default NoAccess;
