import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function deleteJobMilestone(jobId: string, milestoneId: string) {
  const response = await fetch(`${apiEndpoint}/jobs/${jobId}/milestones/${milestoneId}`, {
    method: 'DELETE',
    headers: await standardHeaders(),
    mode: 'cors',
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
