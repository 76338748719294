import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CommandButton,
  MessageBar,
  MessageBarType,
  TextField,
  Stack,
  DatePicker,
  defaultDatePickerStrings,
} from '@fluentui/react';
import moment from 'moment';

import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';

import {
  useCourseStatusSummary,
  useSaveCourseStatuses,
} from 'hooks/useCourseStatuses';

import { useBreadcrumbs } from 'providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';

import { ICourseStatusSummary } from 'types/ICourseStatus';

const INITIAL_COURSE_STATUS: ICourseStatusSummary = {
  id: 0,
  member_name: '',
  level_course: '',
  level1: '',
  level2: '',
  course: '',
  wiki_url: '',
  priority: -1,
  booked_date: '',
  expected_date: '',
  completed_date: '',
  course_id: 0,
  member_id: 0,
};

export const CourseFormPage: React.FC = () => {
  const history = useHistory();

  const { courseStatusId } = useParams<any>();
  const { courseStatuses } = useCourseStatusSummary();
  const { setToastSuccessMessage } = useToast();

  const courseStatus = useMemo(() => {
    let initialCourseStatus = INITIAL_COURSE_STATUS;

    if (courseStatuses?.length) {
      const courseStatus = courseStatuses.find(
        (course) => course.id === parseInt(courseStatusId)
      );

      if (courseStatus) {
        initialCourseStatus = courseStatus;
      }
    }

    return initialCourseStatus;
  }, [courseStatusId, courseStatuses]);

  const [initialData] = useState(courseStatus);
  const [data, setData] = useState(courseStatus);

  const { setBreadcrumbs } = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');

  const { saveCourseStatuses, isLoading: isCreating } = useSaveCourseStatuses({
    onSuccess: () => {
      history.push(`/courses/${data.member_id}`);
      setToastSuccessMessage('Course Status has been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update the course status.'
      ),
  });

  const isSubmitting = useMemo(() => isCreating, [isCreating]);

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'courses',
        text: 'Courses',
        onClick: () => history.push(`/courses/${data.member_id}`),
      },
      ...(courseStatusId
        ? [{ key: 'coursestatues', text: data?.level_course ?? 'Unknown' }]
        : [{ key: 'new', text: 'New' }]),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, data, courseStatusId]);

  const getFormErrors = (form: ICourseStatusSummary): any => {
    const errors: any = {};

    return errors;
  };

  const onSubmit = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = {
      course_status_id: form.id,
      course_id: form.course_id,
      member_id: form.member_id,
      priority: form.priority,
      booked_date: form?.booked_date,
      expected_date: form?.expected_date,
      completed_date: form?.completed_date,
    };
    saveCourseStatuses([payload]);
  };

  return (
    <Page>
      <CommandBar>
        <>
          <CommandButton
            iconProps={{ iconName: 'Undo' }}
            text="Discard Changes"
            onClick={() => history.push(`/courses`)}
            disabled={isSubmitting || !!initialData?.completed_date}
          />
          <CommandButton
            iconProps={{ iconName: 'Save' }}
            text="Update Course Status"
            onClick={onSubmit}
            disabled={isSubmitting || !!initialData?.completed_date}
          />
        </>
      </CommandBar>
      <Container>
        <header>
          <h2>Edit Course Status</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Level 1"
                  value={initialData?.level1 ? initialData.level1 : ''}
                  disabled
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Level 2"
                  value={initialData?.level2 ? initialData.level2 : ''}
                  disabled
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Course"
                  value={initialData?.course ? initialData.course : ''}
                  disabled
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Priority"
                  value={
                    initialData?.priority ? initialData.priority.toString() : ''
                  }
                  disabled
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  label="Name"
                  value={
                    initialData?.member_name ? initialData.member_name : ''
                  }
                  disabled
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Wiki Url"
                  value={initialData?.wiki_url ? initialData.wiki_url : ''}
                  disabled
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <DatePicker
                  label="Booked date"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                  value={
                    data?.booked_date ? new Date(data?.booked_date) : undefined
                  }
                  onSelectDate={(date) =>
                    setData({
                      ...data,
                      booked_date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                />
              </Stack.Item>
              <Stack.Item grow>
                <DatePicker
                  label="Expected date"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                  value={
                    data?.expected_date
                      ? new Date(data?.expected_date)
                      : undefined
                  }
                  onSelectDate={(date) =>
                    setData({
                      ...data,
                      expected_date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                />
              </Stack.Item>
              <Stack.Item grow>
                <DatePicker
                  label="Completed date"
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  strings={defaultDatePickerStrings}
                  value={
                    data?.completed_date
                      ? new Date(data?.completed_date)
                      : undefined
                  }
                  onSelectDate={(date) =>
                    setData({
                      ...data,
                      completed_date: moment(date).format('YYYY-MM-DD'),
                    })
                  }
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default CourseFormPage;
