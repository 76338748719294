import React, {
  useEffect,
  Suspense,
  useContext,
  useMemo,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  SelectionMode,
  DetailsList,
  IColumn,
  Panel,
  PanelType,
  Pivot,
  PivotItem,
  Spinner,
  SpinnerSize,
  IRenderFunction,
  IDetailsRowProps,
  DetailsRow,
  IDetailsColumnRenderTooltipProps,
  TooltipHost,
  Sticky,
  StickyPositionType,
  DetailsListLayoutMode,
  ConstrainMode,
} from '@fluentui/react'
import { Box } from '@fluentui/react-northstar';
import moment from 'moment';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { ITimesheet } from '../../types/ITimesheet';
import { TimesheetDetailsCard } from '../TimesheetDetailsCard';
import { FluentThemeContext } from '../../providers/FluentThemeProvider';
import { adjustColor } from '../../resources/colors';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { isWeekend } from '../utils/DateHelper';
import {
  BookingsTimelineFilter,
  useBookingTimeline,
  getDefaultTimelineFilter,
} from '../../hooks/useBookingTimelines';
import BookingsTimelineFilters from '../BookingsTimelineFilters';
import './BookingsTimelinePage.css';
import { IBookingsTimelineSelectedTimesheets } from '../../types/IBookingsTimelineSelectedTimesheets';

const backgroundColors = {
  blue: '#bbd1ee',
  red: '#ffcdcd',
  grey: '#bbbab9',
  yellow: '#f0eec5',
  green: '#d4fcd3',
  purple: '#f4ccfd',
};

const Cell: React.FC<{
  item?: any;
  column?: any;
  setSelectedTimesheets?: (p: IBookingsTimelineSelectedTimesheets) => void;
  isWeekly?: boolean;
}> = ({ item, column, setSelectedTimesheets, isWeekly  }) => {
  const { themeString } = useContext(FluentThemeContext);
  const history = useHistory();
  const fieldContent = item[column?.fieldName ?? ''] as string;
  if (column?.fieldName === 'name') {
    return (
      <span onClick={() => history.push(`/timesheet?memberId=${item.id}`)}>
        {fieldContent}
      </span>
    );
  }
  try {
    // if total hours < 8 hours, make it orange
    // if there is no entries which have the client_id = 1 and total is 8, make it green
    // if there is any entry which is job_id 6, make it yellow
    // if total hours > 8 horus, make it purple
    // if there is any entry which is job_id 6 and hours > 8, make it red
    const style: any =
      themeString === 'default' ? { mixBlendMode: 'multiply' } : {};
    const timesheets = JSON.parse(fieldContent);

    let totalHours = timesheets.reduce(
      (sum: number, timesheet: ITimesheet) => timesheet.hours + sum,
      0
    );

    if (isWeekly) {
      totalHours= column.hours;
    }

    const hasNoClient1 = !timesheets.find(
      (timesheet: ITimesheet) => +timesheet.client_id === 1
    );
    const hasJob6 = !!timesheets.find(
      (timesheet: ITimesheet) => +timesheet.job_id === 6
    );
    const hasJob95 = !!timesheets.find(
      (timesheet: ITimesheet) => +timesheet.job_id === 95
    );

    const hasTenantive = timesheets.find(
      (timesheet: ITimesheet) => +timesheet.status_id === 4
    );

    style.mixBlendMode = 'initial'

    if (hasJob6) {
      style.backgroundColor = backgroundColors.red;
    } else if (hasJob95) {
      style.backgroundColor = backgroundColors.grey;
    } else if (totalHours < (isWeekly ? 40 : 8)) {
      style.backgroundColor = backgroundColors.yellow;
    } else if (hasNoClient1) {
      style.backgroundColor = backgroundColors.green;
    } else if (hasJob95) {
      style.backgroundColor = backgroundColors.grey;
    } else {
      style.backgroundColor = backgroundColors.purple;
    }

    const hasManagedService = timesheets.some((t: ITimesheet) =>
      t.job_name?.toLowerCase().includes('(ms)')
    );
    
    if (hasManagedService) {
      style.backgroundColor = backgroundColors.blue;
    }

    const hasNonWorkingDay = timesheets.some((t: ITimesheet) =>
      t.job_name?.toLowerCase().includes('non working day')
    );
    if (hasNonWorkingDay) {
      style.backgroundColor = backgroundColors.grey;
    }

    if (totalHours > (isWeekly ? 40 : 8)) {
      style.fontWeight = 'bold';
    }

    if (hasTenantive) {
      style.fontStyle = 'italic';
    }

    return (
      <span
        style={style}
        onClick={() =>
          setSelectedTimesheets &&
          setSelectedTimesheets({ timesheets, col: column!, row: item })
        }
      >
        {timesheets.map((t: any) => t.clientInitials).join('/')}
      </span>
    );
  } catch (error) {
    return <span />;
  }
};

const ColumnRender = (
  item?: any,
  column?: IColumn,
  setSelectedTimesheets?: (p: IBookingsTimelineSelectedTimesheets) => void,
  isWeekly?: boolean,
) => {
  const { theme, themeString } = useContext(FluentThemeContext);
  if (!!column) {
    if (isWeekend(column.key)) {
      column.className = 'weekend-col';
      column.styles = {
        cellTitle: {
          backgroundColor: adjustColor(theme.semanticColors.bodyBackground, -5),
        },
      };
    }
    if (column.key === 'consultant') {
      column.className = `consultant-name-col header-${themeString}`;
    }
  }

  if (isWeekly) {
    const startOfWeek = moment(column?.key);
    const endOfWeek = moment(startOfWeek).add(7, "days").toDate();

    let ts: any[] = [];
    let isMaxTimesheet = false;

    if (startOfWeek.isValid()) {
      while (startOfWeek.isBefore(endOfWeek, 'day')) {
        const formattedStartDate = startOfWeek.format('YYYY-MM-DD');
        startOfWeek.add(1, 'days');

        if (item[formattedStartDate]) {
          const validate = JSON.parse(item[formattedStartDate]);

          if (Array.isArray(validate)) {
            const arr = JSON.parse(item[formattedStartDate])[0];
            const job = ts.find(i => i.clientInitials === arr.clientInitials);
            if (job) {
              job.hours = job.hours + arr.hours;
              job.timesheets.push(arr);
            } else{
              ts.push({
                ...arr,
                timesheets: [arr],
              })
            }
          }
        } 

        if (ts?.length === 5) {
          isMaxTimesheet = true;
          break;
        }
      }

      if (ts.length && column) {
        return (
          <div className={isMaxTimesheet ? 'timesheet-warning' : '' }>
            {ts.map(t => (
              <Cell
                item={item}
                column={{
                  ...t,
                  key: t.date,
                  fieldName: t.date,
                }}
                setSelectedTimesheets={setSelectedTimesheets}
                isWeekly={isWeekly}
              />
            ))}
          </div>
        )
      }
      
    }

  }
  
  return (
    <>
      <Cell
        item={item}
        column={column}
        setSelectedTimesheets={setSelectedTimesheets}
      />
    </>
  );
};

const getColumnHeaders = (columns: IColumn[], theme: any): IColumn[] => {
  const result: IColumn[] = [];
  columns.forEach((col, i) => {
    const c: IColumn = { ...col };
    c.styles = {
      cellTitle: i === 0 ? undefined : { padding: 0 },
      cellName: !isWeekend(c.key)
        ? undefined
        : {
            backgroundColor: adjustColor(
              theme.semanticColors.bodyBackground,
              -5
            ),
          },
    };
    result.push(c);
  });
  return result;
};

export const BookingsTimelinePage: React.FC = () => {
  const { theme, themeString } = useContext(FluentThemeContext);
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [timelineFilter, setTimelineFilter] = useState<BookingsTimelineFilter>(
    getDefaultTimelineFilter()
  );
  const timeline = useBookingTimeline({ timelineFilter });
  const [selectedTimesheets, setSelectedTimesheets] =
    useState<IBookingsTimelineSelectedTimesheets | null>();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const gridEl = useRef<HTMLDivElement>(null);
  const [gridHeight, setGridHeight] = useState(1);

  useEffect(() => {
    setBreadcrumbs([]);
  }, [history]);

  const totalHours = (selectedTimesheets?.timesheets ?? []).reduce(
    (sum: number, timesheet: ITimesheet) => timesheet.hours + sum,
    0
  );

  const renderFixedDetailsHeader = (props: any, defaultRender: any) => {
    if (!props) return null;
    const onRenderColumnHeaderTooltip: IRenderFunction<
      IDetailsColumnRenderTooltipProps
    > = (tooltipHostProps) => <TooltipHost {...tooltipHostProps} />;
    props.className = `${timelineFilter.isWeekly ? 'weeklyHeader' : ''}`;
    return (
      <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
        {defaultRender!({
          ...props,
          onRenderColumnHeaderTooltip,
        })}
      </Sticky>
    );
  };

  const onRenderRow: IRenderFunction<IDetailsRowProps> = (props) => {
    if (!props) return null;
    props.className = `header-${themeString} ${timelineFilter.isWeekly ? 'weeklyRow' : ''}`;
    return <DetailsRow {...props} />;
  };

  const columns = useMemo(
    () => getColumnHeaders(timeline.datesFields, theme),
    [timeline.datesFields, theme]
  );

  const scrollableStyle = {
    overflow: 'auto',
    height: '100%',
  };

  useLayoutEffect(() => {
    if (!gridEl?.current) return;
    const offset =
      window.pageYOffset + gridEl.current.getBoundingClientRect().top;
    setGridHeight(offset + 20);
  }, []);

  const timesheetHours = () => {
    if (!selectedTimesheets) return '';

    const name = selectedTimesheets.row.name;
    let date = moment(
      selectedTimesheets.col.fieldName,
      'YYYY-MM-DD'
    ).format('ddd DD MMM');
    let hours = totalHours;

    if (timelineFilter.isWeekly) {
      date = `${moment(
        selectedTimesheets.col.fieldName).format('ddd DD MMM')} - ${
          moment(selectedTimesheets.col.fieldName).add(6, "days").format('ddd DD MMM')
        }`
      hours= selectedTimesheets.col.hours;
    }

    return selectedTimesheets
    ? `${name}, ${date} (${hours} hour${
      hours > 1 ? 's' : ''
      })`
    : ''
  };

  return (
    <Page>
      <CommandBar />
      <Container mt={-5} scrollable={false}>
        <header>
          <h2>Bookings Timeline</h2>
        </header>
        <BookingsTimelineFilters
          open={isFiltersOpen}
          members={timeline.members}
          onClose={() => setIsFiltersOpen(false)}
          filter={timelineFilter}
          setFilter={setTimelineFilter}
        />
        <div
          ref={gridEl}
          className={`da-details-list-container da-bookings-timeline grid-${themeString}`}
          style={{
            height: `calc(100vh - ${gridHeight}px)`,
          }}
        >
          <Box style={scrollableStyle}>
            <DetailsList
              columns={columns}
              items={timeline.data}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              constrainMode={ConstrainMode.unconstrained}
              onRenderItemColumn={(item?: any, _?: number, column?: IColumn) =>
                ColumnRender(item, column, setSelectedTimesheets, timelineFilter?.isWeekly)
              }
              onRenderRow={onRenderRow}
              onRenderDetailsHeader={renderFixedDetailsHeader}
            />
            <div className={`da-legend ${themeString}`}>
              <span
                className="dot"
                style={{ backgroundColor: backgroundColors.blue }}
              />
              <span>Managed Services</span>
              <span
                className="dot"
                style={{ backgroundColor: backgroundColors.grey }}
              />
              <span>Not Working Day</span>
              <span className="dot" style={{ backgroundColor: backgroundColors.red }} />
              <span>Unassigned</span>
              <span
                className="dot"
                style={{ backgroundColor: backgroundColors.green }}
              />
              <span>Client Work</span>
              <span
                className="dot"
                style={{ backgroundColor: backgroundColors.yellow }}
              />
              <span>Partial Day</span>
              <span
                className="dot"
                style={{ backgroundColor: backgroundColors.purple }}
              />
              <span>Leave/Other</span>
              &nbsp;&nbsp;
              <span style={{ fontStyle: 'italic' }}>Tenative</span>
              &nbsp;
              <span style={{ fontWeight: 'bold' }}> &gt; {timelineFilter?.isWeekly ? 40 : 8} hours</span>
            </div>
          </Box>
        </div>
      </Container>
      <Panel
        type={PanelType.medium}
        isOpen={!!selectedTimesheets}
        onDismiss={() => setSelectedTimesheets(null)}
        isLightDismiss={true}
        headerText={timesheetHours()}
      >
        <Pivot selectedKey={'timesheets'}>
          <PivotItem headerText="Timesheets" itemKey="timesheets">
            <Suspense
              fallback={
                <div className="da-general-loading">
                  <Spinner size={SpinnerSize.large} />
                </div>
              }
            >
              
              {timelineFilter.isWeekly ? ((selectedTimesheets?.col?.timesheets ?? []).map((timesheet: any) => (
                <TimesheetDetailsCard
                  key={'timesheet_' + timesheet.id}
                  data={timesheet}
                />
              ))) :
              (selectedTimesheets?.timesheets ?? []).map((timesheet) => (
                <TimesheetDetailsCard
                  key={'timesheet_' + timesheet.id}
                  data={timesheet}
                />
              ))}
            </Suspense>
          </PivotItem>
        </Pivot>
      </Panel>
    </Page>
  );
};

export default BookingsTimelinePage;
