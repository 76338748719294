import React, { useEffect, useMemo, useState } from 'react';
import {
  ComboBox,
  CommandButton,
  MessageBar,
  MessageBarType,
  Stack,
} from '@fluentui/react';
import DataGrid, { Column, Row, RowRendererProps } from 'react-data-grid';
import { nanoid } from 'nanoid';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import { Paper } from 'components/Paper';
import {
  DropdownEditor,
  CellExpanderFormatter,
  TreeViewRow,
  toggleSubRow,
} from 'components/DataGrid';
import { useSaveSkillRatings, useSkillRatingSingle } from 'hooks/useSkillRatings';
import { useSkills } from 'hooks/useSkills';
import { useSubordinateMembers } from 'hooks/useMembers';
import { useToast } from 'providers/ToastContextProvider';
import { IUserAccess } from 'providers/CurrentUserProvider';
import { IMember } from 'types/IMember';
import { ISkill } from 'types/ISkill';
import { ISaveSkillRating, ISkillRating } from 'types/ISkillRating';
import { OPTIONAL_SKILL_RATING, prioritiesDropdownOptions, skillRatingsDropdownOptions } from 'enums/manageSkills';

import './SkillsPage.css';

type DirtyState = {
  skillId: number;
  rating: number;
};

type SkillRatingRow = TreeViewRow & {
  name: string;
  level: number;
  memberId: number;
  rating: number;
  priority: number;
  skillId?: number;
  initialRating?: number;
  skill?: string;
  wikiUrl?: string;
  isEditable?: boolean;
};

const distinct = (arr: string[]) => {
  return Array.from(new Set(arr)).filter((a) => Boolean(a?.trim()));
}

const buildRows = (
  skills: ISkill[],
  ratings: ISkillRating[],
  priorityId: number,
): SkillRatingRow[] => {
  const skillRatings = skills.map((skill) => {
    const selectedSkill = ratings.find((rating) => (
      skill.id === rating.skill_id
    ));
    return { 
      ...selectedSkill,
      skill_id: skill.id,
      level1: skill.level1,
      level2: skill.level2,
      level3: skill.level3,
      skill: skill.skill,
      wiki_url: skill.wiki_url,
      initialRating: selectedSkill?.rating ?? -1,
      rating: selectedSkill?.rating ?? -1,
    };
  });

  const filteredRatings = skillRatings.filter((rating) => {
    let result;

    if (priorityId === 0) {
      result = true;
    } else if (priorityId === -1) {
      result =  rating.priority === null || rating.priority === undefined
    } else {
      result =  priorityId === rating.priority
    }
    
    return result;
  });

  const level1 = distinct(filteredRatings.map((s) => s.level1));
  return level1.map((lv1) => {
    const lv1Id = nanoid();
    const colLevel1 = {
      id: lv1Id,
      name: lv1,
      isExpanded: false,
      level: 0,
    } as SkillRatingRow;
    const level2 = distinct(
      filteredRatings.filter((s) => s.level1 === lv1).map((s) => s.level2)
    );

    colLevel1.children = level2.map((lv2) => {
      const lv2Id = nanoid();
      const colLevel2 = {
        parentId: colLevel1.id,
        id: lv2Id,
        name: lv2,
        isExpanded: false,
        level: 1,
      } as SkillRatingRow;
      const level3 = distinct(
        filteredRatings
          .filter((s) => s.level1 === lv1 && s.level2 === lv2)
          .map((s) => s.level3)
      );
      colLevel2.children = level3.map((lv3) => {
        const lv3Id = nanoid();
        const colLevel3 = {
          parentId: colLevel2.id,
          id: lv3Id,
          name: lv3,
          isExpanded: false,
          level: 2,
        } as SkillRatingRow;
        const level3Ratings = filteredRatings.filter(
          (s) => s.level1 === lv1 && s.level2 === lv2 && s.level3 === lv3
        );
        colLevel3.children = level3Ratings.map((rating) => {
          const skillId = nanoid();
          const colSkills = {
            parentId: colLevel3.id,
            id: skillId,
            name: rating.skill,
            skillId: rating.skill_id,
            wikiUrl: rating.wiki_url,
            rating: rating.rating,
            initialRating: rating.rating,
            isExpanded: false,
            isEditable: true,
            level: 3,
          } as SkillRatingRow;

          return colSkills;
        });

        return colLevel3;
      });

      return colLevel2;
    });

    return colLevel1;
  });
}

const CustomRowFormatter = (
  isDirty: boolean,
  rendererProps: RowRendererProps<SkillRatingRow>
) => {
  const classNames = [];
  if (isDirty) {
    classNames.push('row-dirty');
  }
  return (
    <div className={classNames?.join(' ')}>
      <Row {...rendererProps} />
    </div>
  );
};

export const SkillsPage: React.FC<{ userAccess: IUserAccess }> = (props: {
  userAccess: IUserAccess;
}) => {
  const [rows, setRows] = useState<any>([]);
  const [rowText, setRowText] = useState<any>('');
  const [generalError, setGeneralError] = useState('');
  const [memberId, setMemberId] = useState<any>();
  const [priorityId, setPriorityId] = useState<number>(1);
  const [modifiedItems, setModifiedItems] = useState<DirtyState[]>([]);

  const { skills } = useSkills();
  const { members } = useSubordinateMembers();
  const { skillRating, isLoading: isSkillRatingLoading } = useSkillRatingSingle(memberId);
  const {
    toastSuccessMessage,
    setToastSuccessMessage,
  } = useToast();
  const { saveSkillRatings, isLoading: isSaving } = useSaveSkillRatings({
    onSuccess: () => 
      setToastSuccessMessage('Skill has been updated.'),
    onError: () => 
      setGeneralError(
        'An error occurred while trying to update skills.'
      ),
  });

  const noRatingLabel = `${OPTIONAL_SKILL_RATING.score} - ${OPTIONAL_SKILL_RATING.short}`

  const isDirty = useMemo(
    () => Boolean(modifiedItems?.length),
    [modifiedItems?.length]
  );

  useEffect(() => {
    if (!memberId) return;

    const buildedRows = buildRows(skills ?? [], skillRating ?? [], priorityId);

    if (buildedRows?.length) {
      setRowText('')
      setRows(buildRows(skills ?? [], skillRating ?? [], priorityId))
    }
    else {
      setRows([]);
      setRowText('No priority set.')
    }
  }, [skills, skillRating, memberId, priorityId]);

  const availableMembers = useMemo(() => {
    return members
      .filter(
        (member: IMember) =>
          member.active &&
          (!props.userAccess?.verifyOwner ||
            props.userAccess?.userId === member.id)
      )
      .map((member: IMember) => ({ key: member.id, text: member.name }))
      .sort(
        (
          a: { key: number; text: string },
          b: { key: number; text: string }
        ) => {
          return a.text > b.text ? 1 : -1;
        }
      );
  }, [members, props.userAccess?.userId, props.userAccess?.verifyOwner]);

  const handleRowsChange = (
    currentRows: SkillRatingRow[],
    { indexes, column }: { indexes: number[]; column: any }
  ) => {
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      const row = currentRows[index];
      if (row) {
        setModifiedItems((prev) => {
          let newValue = [...prev];
          const modifiedItem = newValue.find(
            (i) => i.skillId === row.skillId
          );
          const newRating = row.rating;
          if (modifiedItem) {
            modifiedItem.rating = newRating;
          } else {
            newValue.push({
              skillId: row.skillId!,
              rating: newRating,
            });
          }
          return newValue;
        });
      }
    }
    setRows(currentRows);
  };

  const handleSave = () => {
    if (!isDirty) return;
    const payload = modifiedItems.map((item) => {
      const filteredSkillRating = skillRating.find(
        (sr: ISkillRating) =>
          sr.skill_id.toString() === item.skillId.toString()
      );
      return {
        skill_rating_id: filteredSkillRating?.id,
        skill_id: item.skillId,
        member_id: memberId,
        rating: item.rating,
      } as ISaveSkillRating;
    });
    saveSkillRatings(payload);
    const updatedRow = rows.map((row: any) => {
      const filteredItem = modifiedItems.find((item) => item.skillId === row.skillId);
      if (filteredItem) {
        return {
          ...row,
          initialRating: filteredItem.rating,
        }
      }
      return row;
    });
    setRows(updatedRow);
    setModifiedItems([]);
  };

  const columnsTree: Column<SkillRatingRow>[] = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 450,
        resizable: true,
        formatter: ({ row, isCellSelected }) => {
          const hasChildren = Boolean(row.children?.length!);
          return (
            <>
              {hasChildren && (
                <CellExpanderFormatter
                  isCellSelected={isCellSelected}
                  expanded={row.isExpanded === true}
                  onCellExpand={() => {
                    const res = toggleSubRow(rows as TreeViewRow[], row.id);
                    setRows(res as SkillRatingRow[]);
                  }}
                  onCellExpandAll={() => {
                    const res = toggleSubRow(
                      rows as TreeViewRow[],
                      row.id,
                      true
                    );
                    setRows(res as SkillRatingRow[]);
                  }}
                />
              )}
              <div className="rdg-cell-value">
                <div style={{ paddingLeft: `${(row.level + 1)  * 15}px` }}>
                  {
                    row.level === 3 ? (
                      <a href={row.wikiUrl} target="_blank" rel="noreferrer">
                        {row.name}
                      </a>
                    ) : row.name
                  }
                </div>
              </div>
            </>
          );
        },
      },
      {
        key: 'rating',
        name: 'Confidence',
        formatter({ row }) {
          let rowValue;

          if (row.level === 3) {
            if (!row.rating || row.rating < 0) {
              rowValue = noRatingLabel
            }

            if (row.rating >= 0) {
              rowValue = skillRatingsDropdownOptions[row.rating].text
            } 
          }

          return <>{rowValue}</>;
        },
        editor: (props: any) => {
          const row = props.row as SkillRatingRow;
          if (!row?.isEditable) return null;

          let options = skillRatingsDropdownOptions;

          if (row.initialRating === null || row.initialRating === undefined || row.initialRating < 0) {
            options = [
              {
                key: '-1',
                text: noRatingLabel,
              },
              ...skillRatingsDropdownOptions
            ]
          }

          return <DropdownEditor
            {...props}
            valueKey="key"
            textKey="text"
            options={options}
          />
        },
      },
    ];
  }, [rows, setRows]);

  const handleRowRenderer = (
    rendererProps: RowRendererProps<SkillRatingRow>
  ) => {
    let dirty = false;
    const row = rendererProps.row;
    if (row.isEditable && row.skillId) {
      dirty = modifiedItems.some((i) => i.skillId === row.skillId);
    }
    return CustomRowFormatter(dirty, rendererProps);
  };

  return (
    <Page>
      <CommandBar noBreadcrumbs>
        <Spacer />
        <CommandButton
          iconProps={{ iconName: 'Save' }}
          text="Save Changes"
          onClick={handleSave}
          disabled={!isDirty || isSaving}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>Skills</h2>
        </header>
        {generalError && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
              {generalError}
            </MessageBar>
          </div>
        )}
        {toastSuccessMessage && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
            >
              {toastSuccessMessage}
            </MessageBar>
          </div>
        )}
        <Paper>
          <Stack horizontal tokens={{ childrenGap: '1em' }}>
            <Stack.Item grow>
              <ComboBox
                label="Consultant"
                useComboBoxAsMenuWidth
                allowFreeform={true}
                autoComplete={'on'}
                options={availableMembers}
                onChange={(_: any, option: any) => {
                  setMemberId(option.key)
                }}
                disabled={isSkillRatingLoading}
              />
            </Stack.Item>
            <Stack.Item grow>
              <ComboBox
                label="Priorities"
                allowFreeform={true}
                autoComplete={'on'}
                selectedKey={priorityId}
                options={prioritiesDropdownOptions}
                onChange={(_: any, option: any) => {
                  setPriorityId(option.key)
                }}
              />
            </Stack.Item>
          </Stack>
        </Paper>
        <br />
        {rows?.length ? (
          <div className="skill-ratings-grid">
            <DataGrid
              columns={columnsTree}
              rows={rows}
              onRowsChange={handleRowsChange}
              rowRenderer={handleRowRenderer}
              className="rdg-light"
            />
          </div>
        ): rowText}
      </Container>
    </Page>
  );
};

export default SkillsPage;
