import { IColumn } from '@fluentui/react';

export const jobRolesFields: IColumn[] = [
	{
		key: 'name',
		fieldName: 'name',
		name: 'Job Role Name',
		minWidth: 100,
		maxWidth: 300
	},
	{
		key: 'sell_rate',
		fieldName: 'sell_rate',
		name: 'Rate',
		minWidth: 100,
		maxWidth: 100
	},
	{
		key: 'active',
		fieldName: 'active',
		name: 'Is Active',
		minWidth: 100,
		maxWidth: 200
	}
];