import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ISaveSkillRating } from '../types/ISkillRating';

export async function saveSkillRating(payload: ISaveSkillRating) {
  const response = await fetch(`${apiEndpoint}/skill_ratings`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
