import React, { useContext } from 'react';
import { Breadcrumb } from '@fluentui/react';
import './CommandBar.css';
import { FluentThemeContext } from '../providers/FluentThemeProvider';
import { useBreadcrumbs } from '../providers/BreadcrumbsProvider';

export const CommandBar: React.FC<
  React.PropsWithChildren & { noBreadcrumbs?: boolean }
> = ({ children, noBreadcrumbs }) => {
  const { theme } = useContext(FluentThemeContext);
  const { breadcrumbs } = useBreadcrumbs();
  const showBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;
  const dontRenderAtAll = !children && !showBreadcrumbs;

  if (dontRenderAtAll) {
    return <div className="da-command-bar-hidden" />;
  }

  return (
    <div
      className="da-command-bar"
      style={{ backgroundColor: theme.semanticColors.bodyBackground }}
    >
      {!noBreadcrumbs && (
        <div className="da-command-bar--breadcrumbs">
          {showBreadcrumbs && <Breadcrumb items={breadcrumbs} />}
        </div>
      )}
      {children}
    </div>
  );
};
