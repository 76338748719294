import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { IColumn, Toggle } from "@fluentui/react";
import { useUpdateRoleMember } from "../hooks/useJobs";

const ActiveColumn: React.FC<{ item: any }> = ({ item }) => {
  const { jobId, roleId } = useParams<any>();
  const [isActive, setIsActive] = useState(item?.active);
  const { update: updateMember, isLoading: isUpdatingMember } =
    useUpdateRoleMember({
      jobId,
      roleId,
    });

  const onToggle = useCallback(
    async (_: any, newValue: any) => {
      updateMember({
        jobId,
        payload: {
          ...item,
          active: newValue,
        },
      });
      setIsActive(newValue);
    },
    [jobId, item]
  );

  return (
    <Toggle
      checked={isActive}
      onChange={onToggle}
      disabled={isUpdatingMember}
    />
  );
};

export const roleMemberFields: IColumn[] = [
  {
    key: "name",
    fieldName: "name",
    name: "Member Name",
    minWidth: 100,
    maxWidth: 300,
  },
  {
    key: "email",
    fieldName: "email",
    name: "Email",
    minWidth: 100,
    maxWidth: 300,
  },
  {
    key: "active",
    fieldName: "active",
    name: "Is Active",
    minWidth: 100,
    maxWidth: 200,
    onRender: (item) => <ActiveColumn item={item} />,
  },
];
