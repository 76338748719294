import { saveRow } from './saveRow';
import { deleteRow } from './deleteRow';
import moment from 'moment';

export const saveTimesheets = (
  rows: any[],
  timesheets: any[],
) => {
  const forDeletions: any[] = [];
  timesheets.forEach(timesheet => {
    const { date, job_role } = timesheet;
    const selectedRow = rows.find(row => row.day === moment(date).format('YYYYMMDD') && row.job_role === job_role)
    if (selectedRow) {
      forDeletions.push(selectedRow);
    }
  });
  return Promise.all([
    ...timesheets.map((ts: any) => {
      const id: any = undefined;
      return saveRow({
        timesheet_id: id,
        hours: Boolean(ts.hours) ? +ts.hours : undefined,
        comment: ts.comments ?? '',
        date: ts.date,
        status: ts.status,
        job_role_id: ts.job_role_id,
        member_id: ts.member_id,
      });
    }),
    ...forDeletions.map((row: any) => {
      return deleteRow(row.id);
    }),
  ]);
};
