import { loginRequest, msalInstance } from '../providers/AuthProvider';

export async function standardHeaders() {
	const headers = new Headers();
	const teamsToken = window.localStorage?.getItem('TEAMS_TOKEN');
	if (teamsToken) {
		headers.set('Authorization', `Bearer ${teamsToken}`);
	} else {
		const token = await msalInstance.acquireTokenSilent(loginRequest);
		headers.set('Content-Type', 'application/json');
		if (token?.accessToken) {
			headers.set('Authorization', `Bearer ${token.accessToken}`);
		}
	}
	return headers;
}