import { FontIcon } from '@fluentui/react';
import { useFocusRef } from './helpers';

export interface CellExpanderFormatterProps {
  isCellSelected: boolean;
  expanded?: boolean;
  onCellExpand?: () => void;
  onCellExpandAll?: () => void;
}

export function CellExpanderFormatter({
  isCellSelected,
  expanded,
  onCellExpand,
  onCellExpandAll,
}: CellExpanderFormatterProps) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      if (onCellExpand) onCellExpand();
    }
  }
  return (
    <span ref={ref} tabIndex={tabIndex} className="cell-expander">
      <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
        <span>
          <FontIcon iconName={expanded ? 'ChevronDown' : 'ChevronRight'} />
        </span>
      </span>
      <span onClick={onCellExpandAll}>
        <FontIcon
          iconName={expanded ? 'DoubleChevronDown12' : 'DoubleChevronRight12'}
        />
      </span>
    </span>
  );
}
