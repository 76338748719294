import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function getJobStatus(jobId: string, statusId: string) {
  const response = await fetch(
    `${apiEndpoint}/jobs/${jobId}/statuses/${statusId}`,
    {
      method: 'GET',
      headers: await standardHeaders(),
      mode: 'cors',
    }
  );
  if (response.ok) {
    return await response.json();
  }
  return null;
}
