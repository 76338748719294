import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJobMilestone } from 'types/IJobMilestone';

export async function createJobMilestone(milestone: Partial<IJobMilestone>) {
	const response = await fetch(`${apiEndpoint}/jobs/${milestone.job_id}/milestones`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(milestone)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}