const SKILL_RATINGS = [
	{
		score: 0,
		short: 'I have no idea',
		long: 'I have no idea, never done it before. Haven\'t really thought about it too much.',
	},
	{
		score: 1,
		short: 'I have seen it been done',
		long: 'I have some experience it it but I\'ve failed before and/ or I am not confident.',
	},
	{
		score: 2,
		short: 'I can do this with help',
		long: 'I am aware that I need skills here and I have a plan to attain them.',
	},
	{
		score: 3,
		short: 'I can do this myself, but need to look it up',
		long: 'I have done it before, but maybe not for a while or as well as is needed.  I am confident that I can achieve here.',
	},
	{
		score: 4,
		short: 'I am confident about doing this',
		long: 'I am an expert and this is core to what I do and I do this consistently.',
	},
	{
		score: 5,
		short: 'I can teach others',
		long: 'I am an expert and this is core to what I do.  I can confidently to this and proactively teach others and I there is evidence of my ability to grow others around me.',
	},
];

export const OPTIONAL_SKILL_RATING ={
	score: 'N/A',
	short: 'I haven\'t rated myself',
	long: '',
}

export const skillRatings: { [key: number]: string } = (
	SKILL_RATINGS.reduce(
		(previousObject, currentObject) => {
			return Object.assign(previousObject, {
				[currentObject.score]: currentObject.short
			})
		},
	{})
);

export const skillRatingsDropdownOptions = 
	Object
		.entries(skillRatings)
		.map(([key, value]) => {
			let score = key;
			
			if (parseInt(key) < 0) {
				score = 'N/A'
			}
			return { key: key, text: `${score} - ${value}` }
		});

export const priorities: { [key: number]: string } = {
	1: 'Priority 1',
	2: 'Priority 2',
	3: 'Priority 3',
};

export const prioritiesDropdownOptions = [
	{
		key: 0,
		text: 'All',
	},
	{
		key: -1,
		text: 'Unrated',
	},
	...Object
	.entries(priorities)
	.map(([key, value]) => ({ key: parseInt(key), text: value })),
]
	
export const timeline: { [key: number]: string } = {
	1: 'First 3 months',
	2: 'First 6 months',
	3: 'First 12 months',
	4: 'Beyond 12 months',
};

export const timelineDropdownOptions = [
	...Object
	.entries(timeline)
	.map(([, value]) => ({ key: value, text: value })),
]