import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';

export async function updateInvoiceJobId(
  jobInvoiceId: number,
  jobId: number
): Promise<any> {
  const response = await fetch(
    `${apiEndpoint}/invoices/${jobInvoiceId}/update_job_id`,
    {
      method: 'POST',
      headers: await standardHeaders(),
      mode: 'cors',
      body: JSON.stringify({
        job_id: jobId,
      }),
    }
  );
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
