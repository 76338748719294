import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { ISaveCourseStatus } from 'types/ICourseStatus';

export async function saveCourseStatus(payload: ISaveCourseStatus) {
  const response = await fetch(`${apiEndpoint}/course_statuses`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
