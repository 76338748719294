import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MessageBar, MessageBarType, SelectionMode, Stack, StackItem, TextField } from '@fluentui/react';
import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useFilterList } from '../../hooks/useFilterList';
import { useInvoices } from '../../hooks/useInvoices';

import { invoicesPageFields } from '../../enums/invoicesPageFields';

import { useToast } from 'providers/ToastContextProvider';
// import { invoicesFilterables } from '../../enums/invoicesFilterables';
// import { getInvoicesContextualMenu, useInvoices } from 'hooks/useInvoices';
// import { invoicesPageFields } from 'enums/invoicesPageFields';

export const InvoicesPage: React.FC = () => {
  const history = useHistory();

  const { invoices = [] } = useInvoices();
  const { toastSuccessMessage } = useToast();
  // const { clients = [] } = useClients();
  const [searchQuery, setSearchQuery] = useState('');
  const [menu, setMenu] = useState<any>(null);
  const { filteredList } = useFilterList(invoices, searchQuery);

  const isDetailsListVisible = invoices?.length;

  useEffect(() => {
    // if (isDetailsListVisible) {
    //   setMenu(getInvoicesContextualMenu(invoices))
    // }
  }, [isDetailsListVisible, invoices]);

  return (
    <Page>
      <CommandBar />
      <Container mt={-5}>
        <header>
          <h2>Invoices</h2>
        </header>
        <Stack horizontal style={{ marginBottom: 16 }}>
          <StackItem>
            <TextField
              value={searchQuery}
              style={{ minWidth: 300 }}
              onChange={(_: any, value: any) => {
                setSearchQuery(value);
              }}
            />
          </StackItem>
        </Stack>
        {toastSuccessMessage && (
          <div style={{ marginBottom: 16 }}>
            <MessageBar
              messageBarType={MessageBarType.success}
              isMultiline={false}
            >
              {toastSuccessMessage}
            </MessageBar>
          </div>
        )}
        {isDetailsListVisible && (
          <div className="da-details-list-container">
            <DetailsListWithContextMenu
              items={filteredList}
              columns={invoicesPageFields}
              selectionMode={SelectionMode.none}
              onActiveItemChanged={(item) =>
                item && history.push(`/invoices/${item.id}`)
              }
              contextMenu={menu}
              filterableColumns={[]}
            />
          </div>
        )}
      </Container>
    </Page>
  );
};

export default InvoicesPage;
