import { IColumn } from '@fluentui/react';

export const invoiceLinesPageFields: IColumn[] = [
  {
    key: 'description',
    fieldName: 'description',
    name: 'Description',
    minWidth: 300,
    isResizable: true,
  },
  {
    key: 'amount_unit',
    fieldName: 'amount_unit',
    name: 'Unit',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'quantity',
    fieldName: 'quantity',
    name: 'Qty',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'amount_tax',
    fieldName: 'amount_tax',
    name: 'Tax',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: 'amount_line',
    fieldName: 'amount_line',
    name: 'Total',
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
];
