import { IDAColumn, IDAColumnHrefOptions } from 'types/IDADetailsList';

const levelCourseHref: IDAColumnHrefOptions = {
  getUrl: (item?: any, _?: number) => 'https://www.google.com',
};

export const courseStatusesFields: IDAColumn[] = [
  {
    key: 'level_course',
    fieldName: 'level_course',
    name: 'Level 1 / Level 2 / Course',
    minWidth: 300,
    maxWidth: 300,
    href: 'https://www.googdle.com',
  },
  {
    key: 'priority',
    fieldName: 'priority',
    name: 'Priority',
    minWidth: 50,
    maxWidth: 50,
  },
  {
    key: 'expected_date',
    fieldName: 'expected_date',
    name: 'Expected Date',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'booked_date',
    fieldName: 'booked_date',
    name: 'Booked Date',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'completed_date',
    fieldName: 'completed_date',
    name: 'Completed Date',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    key: 'status',
    fieldName: 'status',
    name: 'Status',
    minWidth: 80,
    maxWidth: 80,
  },
];
