import { useState, useEffect } from 'react';
/* eslint-disable react-hooks/exhaustive-deps */

export function useFilterList<T>(
  items: T[],
  query: string,
  minimumQueryLength = 3
) {
  const [filteredList, setFilteredList] = useState(items);

  const isValid = (value: any): boolean => {
    if (!value) return false;

    if (Array.isArray(value)) {
      return value.some((v) => isValid(v));
    }
    if (typeof value === 'object') {
      return Object.values(value).some((v) => isValid(v));
    }
    return typeof value === 'string'
      ? value?.toLowerCase().includes(query.toLowerCase())
      : value?.toString().toLowerCase().includes(query.toLowerCase());
  };

  const filter = () => {
    const result = items.filter((item) =>
      Object.values(item as {}).some((value) => isValid(value))
    );
    setFilteredList(result);
  };

  useEffect(() => {
    if (!items?.length) return;
    if (query?.length < minimumQueryLength) {
      setFilteredList(items);
      return;
    }
    filter();
  }, [query, items]);

  return { filteredList };
}
