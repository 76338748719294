import { useContext, useMemo, useState } from 'react';
import { SelectionMode, IColumn, DetailsListLayoutMode } from '@fluentui/react';
import moment from 'moment';
import { useClientTimesheets } from 'hooks/useClients';
import { useJobRoles } from 'hooks/useJobs';
import { FluentThemeContext } from 'providers/FluentThemeProvider';
import { DAAligmentType, IDAColumn } from 'types/IDADetailsList';
import DetailsListWithContextMenu from './DetailsListWithContextMenu';
import './ClientTimesheets.css';
import { getJobRoleTimesheetColumns } from 'enums/jobRoleBudgetFields';
import { IJobRole } from 'types/IJobRole';

type TimesheetItem = {
  [key: string]: number | string | boolean;
  name: string;
  total: number;
  isRole: boolean;
};

function formatDate(date: string) {
  return moment(date, 'YYYY-MM-DD').format('MMM YY');
}

const COLUMNS: IDAColumn[] = [
  {
    key: 'name',
    fieldName: 'name',
    name: 'Name',
    isResizable: true,
    minWidth: 150,
    maxWidth: 150,
    noDefaultColumnClick: true,
    onRender: (item?: any, _?: number, column?: IColumn) => (
      <span style={item.isRole ? { fontWeight: 'bold' } : {}}>
        {item[column?.fieldName as string]}
      </span>
    ),
  },
  {
    key: 'total',
    fieldName: 'total',
    name: 'Total',
    isResizable: true,
    minWidth: 50,
    maxWidth: 50,
    columnAlign: DAAligmentType.CENTER,
    rowAlign: DAAligmentType.CENTER,
    noDefaultColumnClick: true,
  },
];

export const ClientTimesheets = ({ jobId }: { jobId: number }) => {
  const { jobRoles } = useJobRoles(jobId.toString());
  const { themeString } = useContext(FluentThemeContext);
  const { timesheets } = useClientTimesheets('2000-01-01', '2050-12-31', jobId);

  const [jobRolesData, setJobRoles] = useState<IJobRole[]>(jobRoles);

  const columns = useMemo<IDAColumn[]>(() => {
    if (!timesheets?.length) return [];
    const copy = [...timesheets];
    copy.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    const formattedDates = Array.from(
      new Set(copy.map((s) => formatDate(s.date)))
    );

    return [
      ...COLUMNS,
      ...formattedDates.map((c) => ({
        key: c,
        fieldName: c,
        name: c,
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        columnAlign: DAAligmentType.CENTER,
        rowAlign: DAAligmentType.CENTER,
        noDefaultColumnClick: true,
      })),
    ];
  }, [timesheets]);

  const items = useMemo<TimesheetItem[]>(() => {
    if (!timesheets?.length || !jobRoles?.length) return [];
    const result: TimesheetItem[] = [];
    const roleIds = Array.from(
      new Set(timesheets.map((s) => s.job_role_id)).values()
    );

    for (const roleId of roleIds) {
      const roleTimesheets = timesheets.filter(
        (ts) => ts.job_role_id === roleId
      );
      const memberIds = Array.from(
        new Set(roleTimesheets.map((m) => m.member_id)).values()
      );
      const memberResults = [];
      for (const memberId of memberIds) {
        const member = roleTimesheets.find((m) => m.member_id === memberId);
        if (!member) continue;
        const memberTimesheets = roleTimesheets.filter(
          (m) => m.member_id === memberId
        );
        const dateFields = memberTimesheets.reduce<{ [key: string]: number }>(
          (curr, item) => {
            const monthYear = formatDate(item.date);
            curr[monthYear] = (curr[monthYear] ?? 0) + item.hours / 8;
            return curr;
          },
          {}
        );
        memberResults.push({
          name: member.member_name as string,
          isRole: false,
          total: Object.keys(dateFields).reduce(
            (curr, key) => (curr += dateFields[key]),
            0
          ),
          ...dateFields,
        });
      }

      const { name: roleName } = jobRoles.find((b) => b.id === roleId) || {};
      const dateFieldsTotal = memberResults.reduce<{ [key: string]: number }>(
        (curr, item) => {
          const dateKeys = Object.keys(item).filter(
            (k) => !['name', 'isRole', 'total'].includes(k)
          );
          for (const dateKey of dateKeys) {
            const val = (item as any)[dateKey] as number;
            curr[dateKey] = (curr[dateKey] ?? 0) + val;
          }
          return curr;
        },
        {}
      );
      result.push({
        name: roleName as string,
        isRole: true,
        total: memberResults.reduce((curr, item) => (curr += item.total), 0),
        ...dateFieldsTotal,
      });
      result.push(...memberResults);
    }
    return result;
  }, [jobRoles, timesheets]);

  return (
    <>
      <div className={`da-details-list-container grid-${themeString}`}>
        <DetailsListWithContextMenu
          items={items}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
        />
      </div>
      <div className="da-details-list-container">
        <DetailsListWithContextMenu
          items={jobRolesData ?? []}
          columns={getJobRoleTimesheetColumns(
            jobRoles,
          )}
          selectionMode={SelectionMode.none}
          // onRenderDetailsFooter={Footer}
        />
      </div>
    </>
  );
};

export default ClientTimesheets;
