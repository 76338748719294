import { IDropdownOption } from "@fluentui/react";

export const milestoneStatus: { [key: number]: string } = {
  1: 'green',
  2: 'orange',
  3: 'red',
};

export const milestoneStatusTypesDropdownOptions : IDropdownOption[] = 
	Object
		.entries(milestoneStatus)
		.map(([key, value]) => ({ key: key, text: value }));