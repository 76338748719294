import { ITimesheetOptions } from '../types/ITimesheetOptions';

export const CLIENTS_QUERY_KEYS = {
  getAll: () => ['clients'],
  getSingle: (id: string) => ['client', id],
  getClientJobs: (clientId: string) => ['clientJobs', clientId],
  getClientTimesheets: (startDate: string, endDate: string, jobId: number) => [
    'timesheets',
    { startDate, endDate, jobId },
  ],
};

export const JOBS_QUERY_KEYS = {
  getAll: () => ['jobs'],
  getSingle: (id: string) => ['job', id],
  getJobRoles: (jobId: string) => ['jobRoles', jobId],
  getJobRoleMembers: (jobId: string, roleId: string) => [
    'jobRoleMembers',
    { jobId, roleId },
  ],
  getJobMilestones: (jobId: string) => ['JobMilestones', jobId],
  getJobMilestone: (jobId: string, milestoneId: string) => [
    'JobMilestones',
    jobId,
    milestoneId,
  ],
  getJobStatuses: (jobId: string) => ['JobStatuses', jobId],
  getJobStatus: (jobId: string, statusId: string) => [
    'JobStatuses',
    jobId,
    statusId,
  ],
  getJobsSummary: () => ['jobsSummary'],
};

export const INVOICES_QUERY_KEYS = {
  getAll: () => ['invoices'],
  getSingle: (id: string) => ['invoice', id],
  getSingleByJobId: (jobId: string) => ['invoiceByJobId', jobId],
  getInvoiceLines: (jobInvoiceId: string) => ['invoiceLines', jobInvoiceId],
  updateInvoiceJobId: (jobInvoiceId: number, jobId: number) => [
    'invoiceJobId',
    jobInvoiceId,
    jobId,
  ],
};

export const BUDGETS_QUERY_KEYS = {
  getJobBudgetsWithJobRoles: (jobId: string) => [
    'jobBudgetsWithJobRoles',
    jobId,
  ],
  getJobBudgets: (jobId: string) => ['getJobBudgets', jobId],
  getJobBudgetSingle: (jobId: string, budgetId: string) => [
    'getJobBudgetSingle',
    { jobId, budgetId },
  ],
  getJobBudget: (jobId: string) => ['getJobBudget', jobId],
  getJobRoleBudgets: (jobId?: string, jobBudgetId?: string) => [
    'getJobRoleBudgets',
    { jobId, jobBudgetId },
  ],
};

export const MEMBERS_QUERY_KEYS = {
  getAll: () => ['members'],
  getActive: () => ['members_active'],
  getSingle: (id?: string) => ['member', id],
};

export const OPPORTUNITIES_QUERY_KEYS = {
  getAll: () => ['opportunities'],
  getLists: () => ['opportunitiesLists'],
  getSingle: (id: string) => ['opportunity', id],
};

export const TIMESHEETS_QUERY_KEYS = {
  getData: (filter: ITimesheetOptions) => ['timesheetData', filter],
  getTimesheets: (filter: {
    startDate: string;
    endDate: string;
    memberId?: number;
  }) => ['timesheets', filter],
};

export const SKILLS_KEYS = {
  getAll: () => ['skills'],
  getSingle: (id: string) => ['skills', id],
};

export const SKILL_RATINGS_KEYS = {
  getAll: () => ['skillRatings'],
  getSingle: (id: string) => ['skillRating', id],
};

export const COURSES_KEYS = {
  getAll: () => ['courses'],
  getSingle: (id: string) => ['courses', id],
};

export const COURSE_STATUSES_KEYS = {
  getAll: () => ['courseStatuses'],
  getSingle: (id: string) => ['courseStatus', id],
};

export const FEEDBACKS_KEYS = {
  getAll: () => ['feedbacks'],
  getSingle: (id: string) => ['feedback', id],
};
