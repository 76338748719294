import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import {
  DetailsList,
  ContextualMenu,
  IColumn,
  IContextualMenuItem,
  IContextualMenuListProps,
  IRenderFunction,
  SearchBox,
  Checkbox,
  IContextualMenuProps,
  IDetailsListProps,
  DetailsHeader,
  FontIcon,
  TooltipHost,
  Callout,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import {
  DAAligmentType,
  IDAColumn,
  IDAColumnHrefOptions,
} from 'types/IDADetailsList';
import Link from './Link';
import './DetailsListWithContextMenu.css';

type CheckboxFilter = {
  id: number;
  label: string;
  checked: boolean;
  referenceKey: string;
  isArray?: boolean;
};

type SortField<T> = {
  order: string;
  key: keyof T;
};

type DetailsListContextualMenu = {
  menuProps?: IContextualMenuProps;
  sortStack: string[];
  filters: { [key: string]: string | undefined };
  checkboxFilters: {
    [key: string]: CheckboxFilter[];
  };
};

export type DetailsListProps = IDetailsListProps & {
  contextMenu?: DetailsListContextualMenu;
  filterableColumns?: string[];
};

const TooltipAlternative = ({
  id,
  tooltipValue,
  content,
}: {
  id: string;
  tooltipValue: any;
  content: any;
}) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const elementId = useId(id);

  const handleMouseEnter = () => {
    if (!isCalloutVisible) {
      toggleIsCalloutVisible();
    }
  };
  const handleMouseLeave = () => {
    if (isCalloutVisible) {
      toggleIsCalloutVisible();
    }
  };

  return (
    <div>
      <span
        id={elementId}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="member-column"
      >
        {content}
      </span>
      {isCalloutVisible && (
        <Callout
          onDismiss={toggleIsCalloutVisible}
          target={`#${elementId}`}
          setInitialFocus
          style={{ padding: 10 }}
        >
          {tooltipValue}
        </Callout>
      )}
    </div>
  );
};

const processColumnSortOrder = (
  currentState: string[],
  columnName: string,
  sort?: '+' | '-' | '='
) => {
  const currentIndex = currentState.findIndex(
    (column) => column.indexOf(columnName) !== -1
  );
  if (currentIndex !== -1) {
    const newState = [
      ...currentState.slice(0, currentIndex),
      ...currentState.slice(currentIndex + 1),
    ];

    if (sort) {
      if (sort !== '=') {
        return [sort + columnName, ...newState];
      }
    } else {
      const currentSort = currentState[currentIndex].substr(0, 1);
      if (currentSort === '+') {
        // change to desc
        return ['-' + columnName, ...newState];
      }
    }

    return newState;
  } else {
    return [(sort ?? '+') + columnName, ...currentState];
  }
};

const compareFunc =
  <T extends any>(sort: SortField<T>) =>
  (a: T, b: T) => {
    if (sort) {
      switch (sort.key) {
        case 'value':
          {
            const aValue = Number((a[sort.key] + '').replace(/[^0-9.-]+/g, ''));
            const bValue = Number((b[sort.key] + '').replace(/[^0-9.-]+/g, ''));
            if (sort.order === '+') {
              return aValue - bValue;
            } else if (sort.order === '-') {
              return bValue - aValue;
            }
          }
          break;
        case 'duration':
        case 'resources':
        case 'probability':
          {
            const aValue = Number(a[sort.key]);
            const bValue = Number(b[sort.key]);
            if (sort.order === '+') {
              return aValue - bValue;
            } else if (sort.order === '-') {
              return bValue - aValue;
            }
          }
          break;
        default: {
          const isNumber = typeof a[sort.key];
          const aValue = isNumber
            ? a[sort.key]
            : (a[sort.key] + '').toUpperCase();
          const bValue = isNumber
            ? b[sort.key]
            : (b[sort.key] + '').toUpperCase();
          if (aValue < bValue) {
            return sort.order === '+' ? -1 : 1;
          }
          if (aValue > bValue) {
            return sort.order === '+' ? 1 : -1;
          }
        }
      }
    }
    return 0;
  };

const getSortedData = <T extends any>(
  data: T[],
  contextProps: DetailsListContextualMenu
): T[] => {
  if (!data) return [];
  const filtersArr = Object.entries(contextProps.filters);
  const cbFilters = Object.values(contextProps.checkboxFilters);
  const sortArr = contextProps.sortStack.map<SortField<T>>((sortKey) => ({
    order: sortKey.substr(0, 1),
    key: sortKey.substr(1) as keyof T,
  }));
  let sortedData = data.filter((item: any) => {
    const searchFilters = filtersArr.every(([key, value]) => {
      if (!value) return true;
      return item?.[key as keyof T]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase());
    });

    const checkboxFilters = cbFilters.every((checkboxes) => {
      const referenceKey = checkboxes[0].referenceKey;
      if (!referenceKey) return true;
      const ids = checkboxes.filter((cb) => cb.checked).map((cb) => +cb.id);
      if (!checkboxes[0].isArray)
        return ids.includes(+item[referenceKey as keyof T]!);
      if (
        !item?.[referenceKey as keyof T] ||
        item?.[referenceKey as keyof T].length === 0
      )
        return true;
      return ids.some((id) => item?.[referenceKey as keyof T]?.includes(id));
    });

    return searchFilters && checkboxFilters;
  });

  let sort: SortField<T> | undefined;
  while ((sort = sortArr.pop())) {
    sortedData = sortedData.sort(compareFunc(sort));
  }

  return sortedData;
};

const applyCustomColumnBehavior = (daCol: IDAColumn) => {
  if (!daCol.noDefaultStyle) {
    daCol.styles = {
      ...daCol.styles,
      cellName: {
        ...(daCol.styles as any)?.cellName,
        // sets column's font size
        fontSize: '12px',
      },
    };
  }

  if (daCol.href) {
    if (typeof daCol.href === 'string') {
      daCol.onRender = (item?: any, _?: number, column?: IColumn) => {
        return (
          <>
            <Link href={daCol.href as string} className={column?.className}>
              {item[column?.fieldName as string]}
            </Link>
          </>
        );
      };
    } else {
      const hrefOpts = daCol.href as IDAColumnHrefOptions;
      if (typeof hrefOpts.getUrl === 'function') {
        daCol.onRender = (item?: any, index?: number, column?: IColumn) => {
          const url = hrefOpts.getUrl(item, index, column);
          const content =
            typeof hrefOpts.getChild === 'function'
              ? hrefOpts.getChild(item, index, column)
              : item[column?.fieldName as string];
          return (
            <Link
              href={url}
              className={column?.className}
              {...hrefOpts.linkProps}
            >
              {content}
            </Link>
          );
        };
      }
    }
  }

  const applyTooltip = (
    item?: any,
    index?: number,
    column?: IColumn,
    children?: any
  ) => {
    const tooltip = daCol.cellTooltip?.getContent
      ? daCol.cellTooltip?.getContent(item, index, column)
      : item[column?.fieldName as string];
    return (
      <TooltipAlternative
        id="tooltip"
        tooltipValue={tooltip}
        content={children}
      />
    );
  };

  if (daCol.cellTooltip) {
    const previousRender = daCol.onRender;
    daCol.onRender = (item?: any, index?: number, column?: IColumn) => {
      return applyTooltip(
        item,
        index,
        column,
        previousRender
          ? previousRender(item, index, column)
          : item[column?.fieldName as string]
      );
    };
  }

  if (daCol.columnAlign) {
    switch (daCol.columnAlign) {
      case DAAligmentType.CENTER: {
        daCol.styles = {
          ...daCol.styles,
          cellName: { ...(daCol.styles as any)?.cellName, width: '100%' },
          cellTitle: {
            ...(daCol.styles as any)?.cellTitle,
            width: '100%',
            textAlign: 'center',
          },
        };
        break;
      }
      case DAAligmentType.RIGHT: {
        daCol.styles = {
          ...daCol.styles,
          cellName: { ...(daCol.styles as any)?.cellName, width: '100%' },
          cellTitle: {
            ...(daCol.styles as any)?.cellTitle,
            width: '100%',
            textAlign: 'right',
          },
        };
        break;
      }
      // do nothing as left is fluent ui's default alignment
      case DAAligmentType.LEFT:
      default: {
        break;
      }
    }
  }

  if (daCol.rowAlign) {
    const currentClassName = (daCol.className as any) || '';
    switch (daCol.rowAlign) {
      case DAAligmentType.CENTER: {
        daCol.className = `${currentClassName} da-cell-align-center`;
        break;
      }
      case DAAligmentType.RIGHT: {
        daCol.className = `${currentClassName} da-cell-align-right`;
        break;
      }
      // do nothing as left is fluent ui's default alignment
      case DAAligmentType.LEFT:
      default: {
        break;
      }
    }
  }
};

export const DetailsListWithContextMenu: React.FC<DetailsListProps> = (
  props: DetailsListProps
) => {
  const [menu, setMenu] = useState<DetailsListContextualMenu>(
    props.contextMenu ?? {
      sortStack: [],
      filters: {},
      checkboxFilters: {},
    }
  );
  const [isApplied, setIsApplied] = useState(false);

  useEffect(() => {
    if (isApplied) {
      setIsApplied(false);
    }
  }, [isApplied]);

  const newMenuState = useRef<DetailsListContextualMenu>();

  const columnHeaderCallback = useCallback(
    (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => {
      if (column) {
        setMenu((state: any) => {
          const sortStack = processColumnSortOrder(
            newMenuState.current?.sortStack ?? [],
            column.key
          );

          newMenuState.current = {
            ...state,
            sortStack,
          };

          setIsApplied(true);

          return {
            ...state,
            sortStack,
          };
        });
      }
    },
    [setMenu]
  );

  const columnContextMenuCallback = useCallback(
    (column?: IColumn, ev?: React.MouseEvent<HTMLElement>) => {
      if (!column) {
        return;
      }

      const onSort = (columnName: string, sort?: '+' | '-' | '=') => {
        setMenu((state: any) => {
          const sortStack = processColumnSortOrder(
            newMenuState.current?.sortStack ?? [],
            columnName,
            sort
          );

          newMenuState.current = {
            ...state,
            sortStack,
          };

          setIsApplied(true);

          return {
            ...state,
            sortStack,
          };
        });
      };

      const onDismiss = () => {
        setMenu((state: any) => {
          return {
            ...state,
            menuProps: undefined,
          };
        });
      };

      const onFilterChange = (columnName: string, value?: string) => {
        setMenu((state: any) => {
          newMenuState.current = {
            ...state,
            filters: {
              ...state.filters,
              [columnName]: value,
            },
          };

          return {
            ...state,
            filters: {
              ...state.filters,
              [columnName]: value,
            },
          };
        });
      };

      const onCheckboxFilterChange = (
        columnName: string,
        id: number,
        value?: boolean
      ) => {
        setMenu((state: any) => {
          if (state?.checkboxFilters[columnName]) {
            const index = state.checkboxFilters[columnName].findIndex(
              (cb: any) => cb.id === id
            );

            if (index !== -1) {
              const newState = {
                ...state,
                checkboxFilters: {
                  ...state.checkboxFilters,
                  [columnName]: [
                    ...state.checkboxFilters[columnName].slice(0, index),
                    {
                      ...state.checkboxFilters[columnName][index],
                      checked:
                        typeof value === 'boolean'
                          ? value
                          : !state.checkboxFilters[columnName][index].checked,
                    },
                    ...state.checkboxFilters[columnName].slice(index + 1),
                  ],
                },
              };

              newMenuState.current = {
                ...newState,
                menuProps: menu.menuProps,
              };

              return {
                ...newState,
                menuProps: {
                  ...state.menuProps,
                  ts: Date.now(),
                },
              } as DetailsListContextualMenu;
            }
          }

          newMenuState.current = {
            ...state,
            menuProps: menu.menuProps,
          };

          return state;
        });
      };

      const onToggleAllCheckboxes = (columnKey: string) => {
        const currentMenu = { ...newMenuState.current };
        const checkboxFilters = { ...currentMenu.checkboxFilters };
        const items: CheckboxFilter[] = [];
        let checkboxState = true;

        checkboxFilters[columnKey].forEach((ar: CheckboxFilter) => {
          if (!ar.checked) {
            checkboxState = false;
          }
          items.push({ ...ar });
        });

        items.forEach((value, idx) => {
          items[idx].checked = !checkboxState;
        });

        checkboxFilters[columnKey] = items;
        const newState = { ...menu, checkboxFilters };

        newMenuState.current = {
          ...newState,
          menuProps: menu.menuProps,
        };

        setMenu((state) => {
          return {
            ...newState,
            menuProps: {
              ...state.menuProps,
              ts: Date.now(),
            },
          } as DetailsListContextualMenu;
        });
      };

      if (ev?.currentTarget) {
        setMenu((state) => {
          const items: IContextualMenuItem[] = [
            {
              key: 'aToZ',
              name: 'A to Z',
              iconProps: { iconName: 'SortUp' },
              canCheck: true,
              checked: column.isSorted && !column.isSortedDescending,
              onClick: () => onSort(column.key, '+'),
            },
            {
              key: 'zToA',
              name: 'Z to A',
              iconProps: { iconName: 'SortDown' },
              canCheck: true,
              checked: column.isSorted && column.isSortedDescending,
              onClick: () => onSort(column.key, '-'),
            },
          ];

          if (column.isSorted) {
            items.push({
              key: 'unset',
              name: 'Remove Sort',
              iconProps: { iconName: 'StatusCircleErrorX' },
              canCheck: true,
              onClick: () => onSort(column.key, '='),
            });
          }

          const item = {
            ...state,
            menuProps: {
              ...state.menuProps,
              items,
              target: ev.currentTarget as HTMLElement,
              onDismiss,
              isBeakVisible: true,
              onRenderMenuList: (
                menuListProps: IContextualMenuListProps,
                defaultRender: IRenderFunction<IContextualMenuListProps>
              ) => {
                return (
                  <>
                    <b
                      style={{
                        display: 'block',
                        margin: 0,
                        padding: '0.5em 1em',
                      }}
                    >
                      {column.name}
                    </b>
                    {props.filterableColumns?.includes(column.key) && (
                      <SearchBox
                        styles={{
                          root: {
                            width: '200px',
                            borderColor: 'transparent',
                            backgroundColor: 'var(--neutralLighter)',
                          },
                        }}
                        disableAnimation={true}
                        placeholder={`Filter ${column.name}`}
                        defaultValue={state.filters?.[column.key]}
                        onSearch={() =>
                          setMenu((state) => ({
                            ...state,
                            menuProps: undefined,
                          }))
                        }
                        onChange={(_, newValue) => {
                          onFilterChange(column.key, newValue);
                          setIsApplied(true);
                        }}
                      />
                    )}
                    {defaultRender(menuListProps)}
                    {state.checkboxFilters[column.key] && (
                      <div
                        style={{
                          borderTop: '1px solid var(--neutralLighter)',
                          width: '200px',
                        }}
                      >
                        <ul
                          style={{ listStyle: 'none', padding: 0, margin: 0 }}
                        >
                          {state.checkboxFilters[column.key].map(
                            (cbFilter: any, i) => {
                              const newVal =
                                newMenuState.current?.checkboxFilters?.[
                                  column.key
                                ]?.find(
                                  (s) => s.label === cbFilter.label
                                )?.checked;
                              return (
                                <li style={{ padding: '0.5em' }} key={i}>
                                  <Checkbox
                                    label={cbFilter.label}
                                    checked={newVal}
                                    onChange={() => {
                                      onCheckboxFilterChange(
                                        column.key,
                                        cbFilter.id
                                      );
                                    }}
                                  />
                                </li>
                              );
                            }
                          )}
                        </ul>
                        <p
                          style={{
                            display: 'block',
                            marginTop: '0.5rem',
                            marginBottom: '1rem',
                            padding: '0 0.5rem',
                            cursor: 'pointer',
                            color: 'var(--themePrimary)',
                          }}
                          onClick={() => {
                            onToggleAllCheckboxes(column.key);
                          }}
                        >
                          Toggle all
                        </p>
                        <p
                          style={{
                            display: 'block',
                            marginTop: '0.5rem',
                            marginBottom: '1rem',
                            padding: '0 0.5rem',
                            cursor: 'pointer',
                            color: 'var(--themePrimary)',
                          }}
                          onClick={() => {
                            setIsApplied(true);
                            onDismiss();
                          }}
                        >
                          Apply
                        </p>
                      </div>
                    )}
                  </>
                );
              },
            },
          } as any;

          newMenuState.current = item;
          return item;
        });
      }
    },
    [setMenu, menu, props.filterableColumns]
  );

  const columns = useMemo(() => {
    return props?.columns?.map((col) => {
      const sortStack = newMenuState.current?.sortStack || [];

      const currentIndex = sortStack.findIndex(
        (i) => i.indexOf(col.key) !== -1
      );
      const currentSort =
        currentIndex !== -1 ? sortStack[currentIndex].substr(0, 1) : '=';

      const daCol = col as IDAColumn;
      applyCustomColumnBehavior(daCol);

      return {
        ...col,
        isSorted: currentSort !== '=',
        isSortedDescending: currentSort === '-',
        ...(daCol.noDefaultColumnClick
          ? {}
          : { onColumnClick: columnHeaderCallback }),
        onColumnContextMenu: columnContextMenuCallback,
      };
    });
  }, [props.columns, menu, columnHeaderCallback, columnContextMenuCallback]);

  const sortedData = useMemo(() => {
    return getSortedData(props.items, menu);
  }, [props.items, isApplied]);

  const renderDetailsHeader = (detailsHeaderProps: any) => {
    return (
      <DetailsHeader
        {...detailsHeaderProps}
        onRenderColumnHeaderTooltip={renderCustomHeaderTooltip}
      />
    );
  };

  const renderCustomHeaderTooltip = (tooltipHostProps: any) => {
    const columnKey = tooltipHostProps.column.key;
    const hasFilters = !!menu.checkboxFilters[columnKey];
    const isAllSelected = menu.checkboxFilters[columnKey]?.every(
      (c) => c.checked
    );
    const isNoneSelected = menu.checkboxFilters[columnKey]?.every(
      (c) => !c.checked
    );
    const isPartiallyFiltered = hasFilters && !isAllSelected && !isNoneSelected;
    return (
      <TooltipHost content={tooltipHostProps.column.name}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {tooltipHostProps.children}
          {isPartiallyFiltered && <FontIcon iconName="Filter" />}
        </span>
      </TooltipHost>
    );
  };

  const { contextMenu: _c, filterableColumns: _f, ...detailsListProps } = props;

  const tableProps = {
    ...detailsListProps,
    items: sortedData,
    columns,
    onRenderDetailsHeader: renderDetailsHeader,
  };

  return (
    <>
      <DetailsList
        className="detailsListWithContextMenu"
        {...tableProps}
        onShouldVirtualize={() => {
          return false;
        }}
      />
      {menu.menuProps && <ContextualMenu {...menu.menuProps} />}
    </>
  );
};

export default DetailsListWithContextMenu;
