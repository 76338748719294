/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  CommandButton,
  MessageBar,
  MessageBarType,
  TextField,
  Stack,
  DatePicker,
  ComboBox,
} from '@fluentui/react';
import moment from 'moment';

import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Container } from '../Container';
import { Paper } from '../Paper';

import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useToast } from 'providers/ToastContextProvider';
import { IFeedback } from 'types/IFeedback';
import { useCreateFeedback, useDeleteFeedback, useFeedbackSingle, useUpdateFeedback } from 'hooks/useFeedback';
import { useMembersDropdownOptions, useSubordinateMembersDropdownOptions } from '../../hooks/useMembers';
import { useGetCurrentUser } from '../../hooks/useGetCurrentUser';
import { CurrentUserContext } from 'providers/CurrentUserProvider';



const getFormErrors = (form: IFeedback): any => {
  const errors: any = {};
  
  if (!form.status || form.status === 0) {
    if (!form.recipient) {  
      errors.recipient = 'Recipient is required.';
    }

    if (!form.provider) {  
      errors.provider = 'Provider is required.';
    }

    if (!form.requester) {  
      errors.requester = 'Requester is required.';
    }

  } else if (form.status === 1) {
    if (!form.when) {  
      errors.when = 'When is required.';
    }

    if (!form.context) {  
      errors.context = 'Context is required.';
    }

    if (!form.feedback) {  
      errors.feedback = 'Feedback is required.';
    }

  } else if (form.status === 2) {  

  }

  return errors;
};


export const FeedbackFormPage: React.FC = () => {
  const history = useHistory();
  const { feedbackId } = useParams<any>();
  const { feedback } = useFeedbackSingle(feedbackId);
  const { currentUser } = useContext(CurrentUserContext);
  
  const { setToastSuccessMessage } = useToast();

  const [data, setData] = useState(feedback);
  
  const { setBreadcrumbs } = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState('');

  const memberOptions = useMembersDropdownOptions();
  const subordinateMemberOptions = useSubordinateMembersDropdownOptions(false);

  let showFeedbackPanel = false;
  let allowEditRecipientPanel = false;
  let allowEditFeedbackPanel = false;
  let allowSubmit = false;
  let allowReject = false;
  let allowSave = false;
  let allowDelete = false;

  let title = '';
  let submitTitle = 'Submit';

  if(data && feedbackId && !data.id) {
    history.push('/feedback')
  } 

// status
// 1 - Submitted - (Receipient/Provider/Requester)
// 2 - Feedback provided
// 3 - Rejected
// 4 - Approved for consumption

  if (!data?.status || data?.status === 0) {
    title = 'Feedback - New Request';
    allowEditRecipientPanel = true;
    allowSave = true;
  } else if (data?.status === 1) {
    title = 'Feedback - Awaiting Feedback from Provider';
    showFeedbackPanel = true;

    if(currentUser?.id === data?.provider) {
      allowEditFeedbackPanel = true;
      allowSubmit = true;
      allowSave = true;
    }

    if(currentUser?.id === data?.requester) {
      allowDelete = true;
    }
  } else if (data?.status === 2) {
    title = 'Feedback - Awaiting Approval to Publish by Requester';

    submitTitle = 'Approve';
    showFeedbackPanel = true;
    if(currentUser?.id === data?.requester) {
      allowSubmit = true;
      allowReject = true;
      allowDelete = true;
    }
  } else if (data?.status === 3) {
    title = 'Feedback - Rejected for consumption. Requester to update and resubmit.';

    submitTitle = 'Submit';
    showFeedbackPanel = true;
    if(currentUser?.id === data?.provider) {
      allowEditFeedbackPanel = true;
      allowSubmit = true;
    }

    if(currentUser?.id === data?.requester) {
      allowDelete = true;
    }

  } else if (data?.status === 4) {
    title = 'Feedback';

    showFeedbackPanel = true;
    allowSubmit = false;
    allowReject = false;
    if(currentUser?.id === data?.requester) {
      allowDelete = true;
    }
  }

  console.log('data', data);  

  useEffect(() => {
    if (!data?.requester && currentUser?.id) {
      setData((state) => ({
        ...state,
        requester: currentUser.id,
      }));
    }
  }, [data, currentUser]);
  
  const { create, isLoading: isCreating } = useCreateFeedback({
    onSuccess: () => {
      history.push(`/feedback`);
      setToastSuccessMessage('Feedback has been created.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to create a new feedback.'
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateFeedback({
    onSuccess: () => {
      history.push(`/feedback`);
      setToastSuccessMessage('Feedback has been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update the feedback.'
      ),
  });
  const { delete: deleteSkill, isLoading: isDeleting } =
    useDeleteFeedback({
      onSuccess: () => {
        history.push(`/feedback`);
      },
      onError: () =>
        setGeneralError(
          'An error occurred while trying to delete the feedback.'
        ),
    });

  const isSubmitting = useMemo(
    () => isCreating || isUpdating || isDeleting,
    [isCreating, isUpdating, isDeleting]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'feedback',
        text: 'Feedback',
        onClick: () => history.push('/feedback'),
      },
      ...(feedbackId
        ? [{ key: 'edit', text: 'Edit' }]
        : [{ key: 'new', text: 'New' }]),
    ]);
  }, [history, data, feedbackId]);


  const onReject = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    setGeneralError('');


    if (data.status === 2) {
      data.status = 3;
    } 

    // if(data.status === 1) {
    //   setGeneralError('Feedback is required.');
    //   return;
    // }
    const payload = form;
    update({ feedbackId, payload  });
  };


  const onApprove = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    setGeneralError('');

    if (data.status === 1) {
      data.status = 2;
    } else if (data.status === 3) {
      data.status = 2;
    } else if (data.status === 2) {
      data.status = 4;
    }

    // if(data.status === 1) {
    //   setGeneralError('Feedback is required.');
    //   return;
    // }
    const payload = form;
    update({ feedbackId, payload  });
  };

  const onSave = async () => {
    const form = data ?? {};
    const errors = getFormErrors(form);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    setErrors({});
    const payload = form;
    if (!form.id) {
      create({ payload });
    } else {
      update({ feedbackId, payload  });
    }
  };

  // const onSubmit = async () => {
  //   const form = data ?? {};
  //   const errors = getFormErrors(form);
  //   if (Object.keys(errors).length > 0) {
  //     setErrors(errors);
  //     return;
  //   }
  //   setErrors({});
  //   const payload = form;
  //   if (!form.id) {
  //     create({ payload });
  //   } else {
  //     update({ feedbackId, payload  });
  //   }
  // };


  const onDelete = useCallback(async () => {
    if (window.confirm('Are you sure you want to delete this feedback?')) {
      deleteSkill(feedbackId);
    }
  }, [data]);

  return (
    <Page>
      <CommandBar>
          {(allowEditRecipientPanel || allowEditFeedbackPanel) &&
          <CommandButton
            iconProps={{ iconName: 'Undo' }}
            text={"Discard Changes"}
            onClick={() => history.push(`/feedback`)}
            disabled={isSubmitting}
          />
          }
          {allowDelete && 
          <CommandButton
            iconProps={{ iconName: 'Delete' }}
            text="Delete"
            onClick={onDelete}
            disabled={isSubmitting}
          />
          }
          {allowReject &&
          <CommandButton
            iconProps={{ iconName: 'Cancel' }}
            text="Reject"
            onClick={onReject}
            disabled={isSubmitting}
          />
          }
          {allowSave &&
          <CommandButton
            iconProps={{ iconName: 'Save' }}
            text="Save"
            onClick={onSave}
            disabled={isSubmitting}
          />
          }
          {allowSubmit &&
          <CommandButton
            iconProps={{ iconName: 'Checkmark' }}
            text={submitTitle}
            onClick={onApprove}
            disabled={isSubmitting}
          />
          }
      </CommandBar>
      <Container>
        <header>
          <h2>{title}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <ComboBox
                  label="Recipient"
                  options={subordinateMemberOptions}
                  allowFreeform
                  autoComplete="on"
                  selectedKey={data?.recipient}
                  onChange={(_: any, value: any) =>
                    setData((state) => ({
                      ...state,
                      recipient: value.key,
                    }))
                  }
                  errorMessage={errors.recipient}
                  disabled={isSubmitting || !allowEditRecipientPanel}
                />
              </Stack.Item>
              <Stack.Item grow>
              <ComboBox
                  label="Provider"
                  options={memberOptions}
                  allowFreeform
                  autoComplete="on"
                  selectedKey={data?.provider}
                  onChange={(_: any, value: any) =>
                    setData((state) => ({
                      ...state,
                      provider: value.key,
                    }))
                  }
                  errorMessage={errors.provider}
                  disabled={isSubmitting || !allowEditRecipientPanel}
                />
              </Stack.Item>
              <Stack.Item grow>
                <ComboBox
                    label="Requester"
                    options={memberOptions}
                    allowFreeform
                    autoComplete="on"
                    selectedKey={data?.requester}
                    onChange={(_: any, value: any) =>
                      setData((state) => ({
                        ...state,
                        requester: value.key,
                      }))
                    }
                    errorMessage={errors.requester}
                    disabled={isSubmitting || !allowEditRecipientPanel}
                  />

              </Stack.Item>
            </Stack>
            { showFeedbackPanel &&
            <>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <DatePicker
                    label="When"
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    onSelectDate={(date) =>
                      setData((state) => ({
                        ...state,
                        when: moment(date).format('YYYY-MM-DD'),
                      }))
                    }
                    isRequired={true}
                    value={
                      data?.when
                        ? moment(data.when, 'YYYY-MM-DD').toDate()
                        : undefined
                    }
                    disabled={isSubmitting || !allowEditFeedbackPanel}
                  />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Context"
                  value={data?.context ? data.context  + '' : ''}
                  onChange={(_: any, context: any) =>
                    setData((state: IFeedback) => ({ ...state, context }))
                  }
                  errorMessage={errors.context}
                  disabled={isSubmitting || !allowEditFeedbackPanel}
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  label="Feedback"
                  value={data?.feedback ? data.feedback + '' : ''}
                  onChange={(_: any, feedback: any) =>
                    setData((state: IFeedback) => ({ ...state, feedback }))
                  }
                  errorMessage={errors.feedback}
                  disabled={isSubmitting || !allowEditFeedbackPanel}
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
            </>
            }            
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default FeedbackFormPage;
