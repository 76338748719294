export function toCurrency(
  value: number,
  opts: {
    currency: string;
    maximumFractionDigits: number;
    noCommas: boolean;
  } = {
    currency: 'USD',
    maximumFractionDigits: 0,
    noCommas: true,
  }
): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: opts.currency || 'USD',
    maximumFractionDigits:
      opts.maximumFractionDigits > 20 || opts.maximumFractionDigits < 0
        ? 0
        : opts.maximumFractionDigits,
  });

  if (!opts.noCommas) formatter.format(value);
  return formatter.format(value).replaceAll(',', '');
}
