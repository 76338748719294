/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { JOBS_QUERY_KEYS, BUDGETS_QUERY_KEYS } from '../constants/queries';
import { businessUnit } from '../enums/businessUnit';
import { jobStatus } from '../enums/jobStatus';
import { createJob } from '../resources/createJob';
import { deleteJob } from '../resources/deleteJob';
import { getJob } from '../resources/getJob';
import { getJobs } from '../resources/getJobs';
import { getJobsSummary } from '../resources/getJobsSummary';
import { getJobRoles } from '../resources/getJobRoles';
import { updateJob } from '../resources/updateJob';
import { getJobRoleMembers } from '../resources/getJobRoleMembers';
import { updateRoleMember } from '../resources/updateMember';
import { createJobRole } from '../resources/createJobRoles';
import { updateJobRole, updateJobRoles } from '../resources/updateJobRole';
import { deleteJobRole } from '../resources/deleteJobRole';
import { IJob } from '../types/IJob';
import { IJobSummary } from '../types/IJobSummary';
import { IJobRole } from '../types/IJobRole';
import {
  ICreateResult,
  IDeleteResult,
  IMutateOption,
  IQueryResult,
  IUpdateResult,
} from '../types/IQueryResult';
import { useMembers } from './useMembers';
import { IRoleMember } from '../types/IRoleMember';
import { IMember } from '../types/IMember';
import { useClients } from './useClients';
import { IClient } from 'types/IClient';
import { IJobMilestone } from 'types/IJobMilestone';
import { getJobMilestones } from 'resources/getJobMilestones';
import { getJobMilestone } from 'resources/getJobMilestone';
import { updateJobMilestone } from 'resources/updateJobMilestone';
import { deleteJobMilestone } from 'resources/deleteJobMilestone';
import { createJobMilestone } from 'resources/createJobMilestone';
import { getPersonNameInitials } from 'components/utils/MiscHelper';
import { IJobStatus } from 'types/IJobStatus';
import { getJobStatuses } from 'resources/getJobStatuses';
import { createJobStatus } from 'resources/createJobStatus';
import { updateJobStatus } from 'resources/updateJobStatus';
import { deleteJobStatus } from 'resources/deleteJobStatus';
import { getJobStatus } from 'resources/getJobStatus';

const populateJobData = (job: IJob) => ({
  statusText: jobStatus[job.status],
  business_unit_text: job.business_unit ? businessUnit[job.business_unit] : '',
});

export function useJobSingle(id: string): IQueryResult & {
  job: IJob;
} {
  const { data, isLoading, error } = useQuery(
    JOBS_QUERY_KEYS.getSingle(id),
    () => getJob(id),
    {
      enabled: !!id,
    }
  );
  const { jobRoles } = useJobRoles(id);
  const job = useMemo(() => {
    if (data)
      return { ...data, ...populateJobData(data), roles: jobRoles ?? [] };
    return null;
  }, [data, jobRoles]);
  return { job, isLoading, error };
}

export function useJobs(): IQueryResult & {
  jobs: IJob[];
} {
  const { data, isLoading, error } = useQuery(
    JOBS_QUERY_KEYS.getAll(),
    getJobs,
    {}
  );

  const { clients = [] } = useClients();

  const jobs = useMemo(() => {
    if (data?.length)
      return data.map((j: IJob) => ({
        ...j,
        ...populateJobData(j),
        clientName:
          clients.find((client) => client.id === j.client_id)?.name ??
          'Unknown',
      }));
    return null;
  }, [clients, data]);

  return { jobs, isLoading, error };
}

export function useJobsSummary(): IQueryResult & {
  jobs: IJobSummary[];
} {
  const { data, isLoading, error } = useQuery(
    JOBS_QUERY_KEYS.getJobsSummary(),
    getJobsSummary,
    {}
  );

  const { clients = [] } = useClients();
  const { members = [] } = useMembers();

  const jobs = useMemo(() => {
    if (data?.length)
      return data.map((j: IJob) => {
        const projectLead = members.find(
          (m) => m.id === j.project_lead_id
        )?.name;
        const accountManager = members.find(
          (m) => m.id === j.account_manager_id
        )?.name;
        const projectManager = members.find(
          (m) => m.id === j.project_manager_id
        )?.name;
        const client = clients.find((client) => client.id === j.client_id);
        return {
          ...j,
          ...populateJobData(j),
          clientName: client?.name ?? 'Unknown',
          clientInitials: client?.initials ?? 'Unknown',
          projectLeadInitials: projectLead
            ? getPersonNameInitials(projectLead)
            : '',
          projectLead: projectLead || '',
          accountManagerInitials: accountManager
            ? getPersonNameInitials(accountManager)
            : '',
          accountManager: accountManager || '',
          projectManagerInitials: projectManager
            ? getPersonNameInitials(projectManager)
            : '',
          projectManager: projectManager || '',
        };
      });
    return null;
  }, [clients, data, members]);

  return { jobs, isLoading, error };
}

export function useCreateJob(
  opts: IMutateOption<IJob>
): ICreateResult<{ clientId: string; payload: IJob }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { clientId: string; payload: IJob }) =>
      createJob(data.clientId, data.payload),
    {
      onSuccess: (data: IJob) => {
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateJob(
  opts?: IMutateOption<IJob>
): IUpdateResult<{ clientId: string; payload: IJob }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { clientId: string; payload: IJob }) =>
      updateJob(data.clientId, data.payload),
    {
      onSuccess: (data: IJob) => {
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getSingle(data.id.toString())
        );
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteJob(opts: IMutateOption<IJob>): IDeleteResult<string> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(deleteJob, {
    onSuccess: (data: IJob) => {
      queryClient.removeQueries(
        JOBS_QUERY_KEYS.getSingle(data?.id?.toString())
      );
      queryClient.invalidateQueries(JOBS_QUERY_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}

export function useJobRoles(jobId: string): IQueryResult & {
  jobRoles: IJobRole[];
} {
  const {
    data: jobRoles,
    isLoading,
    error,
  } = useQuery(JOBS_QUERY_KEYS.getJobRoles(jobId), () => getJobRoles(jobId), {
    enabled: !!jobId,
  });

  const santizeNumber = (value?: number) => {
    if (value == null || Number.isNaN(value) || value < 0) return 0;
    return value;
  };

  const sanitizeJobRoles = useMemo(() => {
    if (!jobRoles?.length) return [];

    return jobRoles?.map((r: IJobRole) => ({
      ...r,
      hours_budget: santizeNumber(r.hours_budget),
      sell_rate: santizeNumber(r.sell_rate),
    }));
  }, [jobRoles]);

  return {
    jobRoles: sanitizeJobRoles,
    isLoading,
    error,
  };
}

export function useJobRole(
  jobId: string,
  roleId: string
): IQueryResult & {
  jobRole: IJobRole;
} {
  const { job, isLoading, error } = useJobSingle(jobId);
  const { jobRoleMembers } = useJobRoleMembers(jobId, roleId);

  const jobRole = useMemo(() => {
    const result = job?.roles?.find((r) => +r.id === +roleId);
    return { ...result, job, members: jobRoleMembers };
  }, [job, jobRoleMembers, roleId]) as IJobRole;

  return { jobRole, isLoading, error };
}

export function useCreateJobRole(
  opts: IMutateOption<IJobRole> & { jobId: string }
): ICreateResult<Partial<IJobRole>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobRole>) => createJobRole(data),
    {
      onSuccess: (data: IJobRole) => {
        if (!data) return;
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getJobRoles(opts.jobId));
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(opts.jobId)
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateJobRole(
  opts: IMutateOption<IJobRole> & { jobId: string }
): IUpdateResult<Partial<IJobRole>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobRole>) => updateJobRole(data),
    {
      onSuccess: (data: IJobRole) => {
        if (!data) return;
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getJobRoles(opts.jobId));
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(opts.jobId)
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { update: mutate, isLoading, error };
}

export function useUpdateJobRoles(
  opts: IMutateOption<IJobRole[]> & { jobId: string }
): IUpdateResult<Partial<IJobRole>[]> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobRole>[]) => updateJobRoles(data) as Promise<IJobRole[]>,
    {
      onSuccess: (data: IJobRole[]) => {
        if (!data) return;
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getJobRoles(opts.jobId));
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(opts.jobId)
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { update: mutate, isLoading, error };
}

export function useDeleteJobRole(
  opts: IMutateOption<IJobRole> & { jobId: string }
): IDeleteResult<{ jobId: string; roleId: string }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobId: string; roleId: string }) =>
      deleteJobRole(data.jobId, data.roleId),
    {
      onSuccess: (data: IJobRole) => {
        if (!data) return;
        queryClient.invalidateQueries(JOBS_QUERY_KEYS.getJobRoles(opts.jobId));
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgets(opts.jobId)
        );
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudgetsWithJobRoles(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { delete: mutate, isLoading, error };
}

export function useJobRoleMembers(
  jobId: string,
  roleId: string
): IQueryResult & {
  jobRoleMembers: IRoleMember[];
  unassignedMembers: { key: number; text: string }[];
} {
  const { members } = useMembers();
  const { data, isLoading, error } = useQuery(
    JOBS_QUERY_KEYS.getJobRoleMembers(jobId, roleId),
    () => getJobRoleMembers(jobId, roleId),
    {
      enabled: !!jobId && !!roleId,
    }
  );

  const jobRoleMembers = useMemo(() => {
    return data?.map((roleMember: Partial<IRoleMember>) => {
      const member = members?.find((m) => +m.id === +roleMember.member_id!);
      return {
        ...roleMember,
        email: member?.email,
        name: member?.name,
      };
    });
  }, [data, members]) as IRoleMember[];

  const unassignedMembers = useMemo(() => {
    return members
      ?.filter(
        (member: IMember) =>
          !jobRoleMembers?.find(
            (existingMember) => +existingMember.member_id === +member.id
          )
      )
      .map((member: IMember) => ({ key: member.id, text: member.name }));
  }, [jobRoleMembers, members]);

  return { jobRoleMembers, unassignedMembers, isLoading, error };
}

export function useUpdateRoleMember(
  opts: IMutateOption<IJob> & { jobId: string; roleId: string }
): IUpdateResult<{ jobId: string; payload: Partial<IRoleMember> }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobId: string; payload: Partial<IRoleMember> }) =>
      updateRoleMember(data.jobId, data.payload),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobRoleMembers(opts.jobId, opts.roleId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function getJobsContextualMenu(
  jobs: IJob[],
  clients: IClient[],
  members: IMember[]
) {
  const filteredMemberList = jobs?.length
    ? members.filter((member: IMember) => {
        return jobs.find(
          (job) => job.project_lead_id === member.id && member.active
        )?.name;
      })
    : [];

    const filteredAMList = jobs?.length
    ? members.filter((member: IMember) => {
        return jobs.find(
          (job) => job.account_manager_id === member.id && member.active
        )?.name;
      })
    : [];

  const filteredPMList = jobs?.length
    ? members.filter((member: IMember) => {
        return jobs.find(
          (job) => job.project_manager_id === member.id && member.active
        )?.name;
      })
    : [];

  const clientList = clients.reduce((previousObject, currentObject, i) => {
    return Object.assign(previousObject, {
      [currentObject.id]: currentObject.name,
    });
  }, {});

  const memberList = filteredMemberList
    .filter((member) => member.active)
    .reduce((previousObject, currentObject, i) => {
      return Object.assign(previousObject, {
        [currentObject.id]: currentObject.name,
      });
    }, {});

  const accountManagerList = filteredAMList
    .filter((member) => member.active)
    .reduce((previousObject, currentObject, i) => {
      return Object.assign(previousObject, {
        [currentObject.id]: currentObject.name,
      });
    }, {});

  const projectManagerList = filteredPMList
    .filter((member) => member.active)
    .reduce((previousObject, currentObject, i) => {
      return Object.assign(previousObject, {
        [currentObject.id]: currentObject.name,
      });
    }, {});

  const clientIdList = clients?.map((client) => client.id) || [];
  const memberIdList = filteredMemberList?.map((member) => member.id) || [];
  const accountManagerIdList = filteredAMList?.map((member) => member.id) || [];
  const projectManagerIdList = filteredPMList?.map((member) => member.id) || [];

  return {
    checkboxFilters: {
      statusText: Object.entries(jobStatus).map(([id, label]) => ({
        id: +id,
        label,
        checked: [1].includes(+id),
        referenceKey: 'status',
      })),
      clientName: Object.entries(clientList).map(([id, label]) => ({
        id: +id,
        label,
        checked: clientIdList.includes(+id),
        referenceKey: 'client_id',
      })),
      project_lead_id: Object.entries(memberList).map(([id, label]) => ({
        id: +id,
        label,
        checked: memberIdList.includes(+id),
        referenceKey: 'project_lead_id',
      })),
      account_manager_id: Object.entries(accountManagerList).map(([id, label]) => ({
        id: +id,
        label,
        checked: accountManagerIdList.includes(+id),
        referenceKey: 'account_manager_id',
      })),
      project_manager_id: Object.entries(projectManagerList).map(([id, label]) => ({
        id: +id,
        label,
        checked: projectManagerIdList.includes(+id),
        referenceKey: 'project_manager_id',
      })),
    },
    filters: {},
    sortStack: ['+close_date'],
    menuProps: undefined,
  };
}

export function useJobStatuses(jobId: string): IQueryResult & {
  jobStatuses: IJobStatus[];
} {
  const {
    data: jobStatuses,
    isLoading,
    error,
  } = useQuery(
    JOBS_QUERY_KEYS.getJobStatuses(jobId),
    () => getJobStatuses(jobId),
    {
      enabled: !!jobId,
    }
  );
  return { jobStatuses, isLoading, error };
}

export function useJobStatus(
  jobId: string,
  statusId: string
): IQueryResult & {
  jobStatus: IJobStatus;
} {
  const {
    data: jobStatus,
    isLoading,
    error,
  } = useQuery(
    JOBS_QUERY_KEYS.getJobStatus(jobId, statusId),
    () => getJobStatus(jobId, statusId),
    {
      enabled: !!jobId && !!statusId,
    }
  );
  return { jobStatus, isLoading, error };
}

export function useCreateJobStatus(
  opts: IMutateOption<IJobStatus> & { jobId: string }
): ICreateResult<Partial<IJobStatus>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobStatus>) => createJobStatus(data),
    {
      onSuccess: (data: IJobStatus) => {
        if (!data) return;
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobStatuses(opts.jobId)
        );
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobStatus(opts.jobId, data.id.toString())
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateJobStatus(
  opts: IMutateOption<IJobStatus> & { jobId: string }
): IUpdateResult<Partial<IJobStatus>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobStatus>) => updateJobStatus(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobStatuses(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteJobStatus(
  opts: IMutateOption<IJobStatus> & { jobId: string }
): IDeleteResult<{ jobId: string; statusId: string }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobId: string; statusId: string }) =>
      deleteJobStatus(data.jobId, data.statusId),
    {
      onSuccess: (data: IJobStatus) => {
        if (!data) return;
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobStatuses(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { delete: mutate, isLoading, error };
}

export function useJobMilestones(jobId: string): IQueryResult & {
  jobMilestones: IJobMilestone[];
} {
  const {
    data: jobMilestones,
    isLoading,
    error,
  } = useQuery(
    JOBS_QUERY_KEYS.getJobMilestones(jobId),
    () => getJobMilestones(jobId),
    {
      enabled: !!jobId,
    }
  );
  return { jobMilestones, isLoading, error };
}

export function useJobMilestone(
  jobId: string,
  milestoneId: string
): IQueryResult & {
  jobMilestone: IJobMilestone;
} {
  const {
    data: jobMilestone,
    isLoading,
    error,
  } = useQuery(
    JOBS_QUERY_KEYS.getJobMilestone(jobId, milestoneId),
    () => getJobMilestone(jobId, milestoneId),
    {
      enabled: !!jobId && !!milestoneId,
    }
  );
  return { jobMilestone, isLoading, error };
}

export function useCreateJobMilestone(
  opts: IMutateOption<IJobMilestone> & { jobId: string }
): ICreateResult<Partial<IJobMilestone>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobMilestone>) => createJobMilestone(data),
    {
      onSuccess: (data: IJobMilestone) => {
        if (!data) return;
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobMilestones(opts.jobId)
        );
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobMilestone(opts.jobId, data.id.toString())
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateJobMilestone(
  opts: IMutateOption<IJobMilestone> & { jobId: string }
): IUpdateResult<Partial<IJobMilestone>> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: Partial<IJobMilestone>) => updateJobMilestone(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobMilestones(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteJobMilestone(
  opts: IMutateOption<IJobMilestone> & { jobId: string }
): IDeleteResult<{ jobId: string; milestoneId: string }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { jobId: string; milestoneId: string }) =>
      deleteJobMilestone(data.jobId, data.milestoneId),
    {
      onSuccess: (data: IJobMilestone) => {
        if (!data) return;
        queryClient.invalidateQueries(
          JOBS_QUERY_KEYS.getJobMilestones(opts.jobId)
        );
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { delete: mutate, isLoading, error };
}
