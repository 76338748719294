import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IOpportunityNew, IOpportunity } from '../types/IOpportunity';

export async function createOpportunity(
  opportunity: IOpportunityNew
): Promise<IOpportunity> {
  const response = await fetch(`${apiEndpoint}/opportunities`, {
    method: 'POST',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify(opportunity),
  });
  if (response.ok) {
    return await response.json();
  }
  throw new Error(response.statusText);
}
