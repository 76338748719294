import React from 'react';
import './Container.css';

export const Container: React.FC<
  React.PropsWithChildren & { mt?: number; scrollable?: boolean }
> = ({ children, mt, scrollable = true }) => {
  return (
    <div
      className="da-container"
      style={{
        marginTop: mt ? mt : 0,
        overflow: scrollable ? 'auto' : 'hidden',
      }}
    >
      {children}
    </div>
  );
};
