import React, { Suspense, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import HomePage from "../pages/HomePage";
import RefreshPage from "../pages/RefreshPage";
import ClientsPage from "../pages/ClientsPage";
import ClientPage from "../pages/ClientPage";
import ClientFormPage from "../pages/ClientFormPage";
import ClientJobPage from "../pages/ClientJobPage";
import ClientJobFormPage from "../pages/ClientJobFormPage";
import ClientJobRolePage from "../pages/ClientJobRolePage";
import ClientJobRoleFormPage from "../pages/ClientJobRoleFormPage";
import ClientMilestoneFormPage from "../pages/ClientMilestoneFormPage";
import ClientJobStatuseFormPage from "../pages/ClientJobStatuseFormPage";
import TimesheetPage from "../pages/TimesheetPage";
import OpportunitiesPage from "../pages/OpportunitiesPage";
import JobsPage from "../pages/JobsPage";
import InvoicesPage from "../pages/InvoicesPage";
import InvoicePage from "../pages/InvoicePage";
import OpportunityFormPage from "../pages/OpportunityFormPage";
import OpportunityPage from "../pages/OpportunityPage";
import BookingsTimelinePage from "../pages/BookingsTimelinePage";
import ClientJobBudgetFormPage from "../pages/ClientJobBudgetFormPage";
import ManageSkills from "../pages/ManageSkillsPage";
import SkillFormPage from "../pages/SkillFormPage";
import SkillsPage from "../pages/SkillsPage";
import SkillPrioritiesPage from "../pages/SkillPrioritiesPage";
import ManageCourses from "../pages/ManageCourses";
import CourseFormPage from "../pages/CourseFormPage";
import CoursesPage from "../pages/CoursesPage";
import CourseStatusesFormPage from "../pages/CourseStatusesFormPage";
import CoursePrioritiesPage from "../pages/CoursePrioritiesPage"
import FeedbackPage from "../pages/FeedbackPage"
import FeedbackFormPage from "../pages/FeedbackFormPage"
import GeneralLoadingSkeleton from "../GeneralLoadingSkeleton";
import { useToast } from "providers/ToastContextProvider";

const AppRoutes: React.FC = () => {
  const history = useHistory();
  
  const {
    setToastSuccessMessage,
    setMilestoneSuccessMessage,
  } = useToast();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setToastSuccessMessage('');
      setMilestoneSuccessMessage('');
    })
    return function cleanup() {
      unlisten()
    }
  }, [])
  
  return (
    <div className="da-stage">
      <Suspense fallback={<GeneralLoadingSkeleton />}>
        <Switch>
          <Route path="/home" component={HomePage} />
          <Route path="/clients" component={ClientsPage} exact={true} />
          <Route path="/clients/new" component={ClientFormPage} exact={true} />
          <Route path="/clients/:clientId" component={ClientPage} exact={true} />
          <Route
            path="/clients/:clientId/edit"
            component={ClientFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/new"
            component={ClientJobFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId"
            component={ClientJobPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/roles"
            component={ClientJobPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/financials"
            component={ClientJobPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/timesheet"
            component={ClientJobPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/milestones"
            component={ClientJobPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/statuses"
            component={ClientJobPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/status/new"
            component={ClientJobStatuseFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/status/:statusId/edit"
            component={ClientJobStatuseFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/edit"
            component={ClientJobFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/roles/new"
            component={ClientJobRoleFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/roles/:roleId"
            component={ClientJobRolePage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/roles/:roleId/edit"
            component={ClientJobRoleFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/milestone/new"
            component={ClientMilestoneFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/milestone/:milestoneId/edit"
            component={ClientMilestoneFormPage}
            exact={true}
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/budgets/:budgetId/edit"
            component={ClientJobBudgetFormPage}
            exact
          />
          <Route
            path="/clients/:clientId/jobs/:jobId/budgets/new"
            component={ClientJobBudgetFormPage}
            exact
          />
          <Route path="/timesheet" component={TimesheetPage} />
          <Route
            path="/opportunities"
            component={OpportunitiesPage}
            exact={true}
          />
          <Route
            path="/opportunities/new"
            component={OpportunityFormPage}
            exact={true}
          />
          <Route
            path="/opportunities/:opportunityId"
            component={OpportunityPage}
            exact={true}
          />
          <Route
            path="/opportunities/:opportunityId/edit"
            component={OpportunityFormPage}
            exact={true}
          />
          <Route
            path="/jobs"
            component={JobsPage}
            exact={true}
          />
          <Route
            path="/invoices"
            component={InvoicesPage}
            exact={true}
          />
          <Route
            path="/invoices/:jobInvoiceId"
            component={InvoicePage}
            exact={true}
          />
          <Route
            path="/manageskills"
            component={ManageSkills}
            exact={true}
          />
          <Route
            path="/manageskills/new"
            component={SkillFormPage}
            exact={true}
          />
          <Route
            path="/manageskills/edit"
            component={SkillFormPage}
            exact={true}
          />
          <Route
            path="/manageskills/:skillId/edit"
            component={SkillFormPage}
            exact={true}
          />
          <Route path="/skills" component={SkillsPage} />
          <Route path="/skillpriorities" component={SkillPrioritiesPage} />
          <Route
            path="/managecourses"
            component={ManageCourses}
            exact={true}
          />
          <Route
            path="/managecourses/new"
            component={CourseFormPage}
            exact={true}
          />
          <Route
            path="/managecourses/edit"
            component={CourseFormPage}
            exact={true}
          />
          <Route
            path="/managecourses/:courseId/edit"
            component={CourseFormPage}
            exact={true}
          />
          <Route
            path="/courses"
            component={CoursesPage}
            exact={true}
          />
          <Route
            path="/courses/:memberId"
            component={CoursesPage}
            exact={true}
          />
          <Route
            path="/courses/new"
            component={CourseStatusesFormPage}
            exact={true}
          />
          <Route
            path="/courses/edit"
            component={CourseStatusesFormPage}
            exact={true}
          />
          <Route
            path="/courses/:courseStatusId/edit"
            component={CourseStatusesFormPage}
            exact={true}
          />
          <Route path="/coursepriorities" component={CoursePrioritiesPage} />
          <Route path="/refresh" component={RefreshPage} exact />
          <Route
            path="/bookingstimeline"
            component={BookingsTimelinePage}
            exact={true}
          />
          <Route
            path="/feedback"
            component={FeedbackPage}
            exact={true}
          />
          <Route
            path="/feedback/:feedbackId/edit"
            component={FeedbackFormPage}
            exact={true}
          />
          <Route
            path="/feedback/new"
            component={FeedbackFormPage}
            exact={true}
          />

          <Redirect to={"/home"} />
        </Switch>
      </Suspense>
    </div>
  )
};

export default AppRoutes;
