import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IFeedback } from '../types/IFeedback';

export async function updateFeedback(feedbackId: string, feedback: IFeedback) {
  const { id: _, ...rest } = feedback;
  const response = await fetch(`${apiEndpoint}/feedback/${feedbackId}`, {
    method: 'PUT',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...rest,
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
