/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CommandButton,
  Stack,
  TextField,
  MessageBar,
  MessageBarType,
  ComboBox,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Paper } from "../Paper";
import { IJobBudget } from "../../types/IJobBudget";
import { useClientSingle } from "../../hooks/useClients";
import { useJobSingle } from "../../hooks/useJobs";
import {
  useOpportunitiesDropdownItems,
  useOpportunitySingle,
} from "../../hooks/useOpportunities";
import {
  useJobBudgetSingle,
  useCreateJobBudget,
  useUpdateJobBudget,
} from "../../hooks/useBudgets";

import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";

type Errors = {
  [P in keyof IJobBudget]?: string | JSX.Element | undefined;
};

export const ClientJobBudgetFormPage: React.FC = () => {
  const { budgetId, jobId, clientId } = useParams<any>();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);
  const [errors, setErrors] = useState<Errors>();
  const { dropdownItems: opportunityOpts } = useOpportunitiesDropdownItems({
    filter: (c) => c.client_id === parseInt(clientId.toString()),
  });

  const navigateToBudgets = () =>
    history.push(`/clients/${client.id}/jobs/${job.id}/budgets`);

  const {
    budget,
    isLoading: isFetching,
    error: fetchingError,
  } = useJobBudgetSingle(jobId, budgetId);

  const {
    update,
    isLoading: isUpdating,
    error: updatingError,
  } = useUpdateJobBudget({
    onSuccess: navigateToBudgets,
  });

  const {
    create,
    isLoading: isCreating,
    error: creatingError,
  } = useCreateJobBudget({
    onSuccess: navigateToBudgets,
  });

  const [formState, setFormState] = useState<IJobBudget>({
    id: budgetId,
    name: "",
    job_id: jobId,
    budget_total: null,
    comments: "",
  });

  const { opportunity } = useOpportunitySingle(
    formState?.opporunity_id ? formState.opporunity_id?.toString() : ""
  );

  const isLoading = useMemo(
    () => isFetching || isUpdating || isCreating,
    [isFetching, isUpdating, isCreating]
  );

  const error = useMemo(
    () => (fetchingError || updatingError || creatingError)?.toString(),
    [fetchingError, updatingError, creatingError]
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      {
        key: "client",
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      {
        key: "job",
        text: job.name,
        onClick: () => history.push(`/clients/${client.id}/jobs/${job.id}`),
      },
    ]);
  }, [history, client, job]);

  useEffect(() => {
    if (budget) setFormState(budget);
  }, [budget]);

  useEffect(() => {
    if (!opportunity?.value) return;
    setFormState({
      ...formState,
      budget_total: opportunity.value,
    });
  }, [opportunity]);

  const onSubmit = async () => {
    const errors: Errors = {};
    if (!formState.name) {
      errors.name = "Reference is required.";
    }
    if (!formState.budget_total) {
      errors.budget_total = "Budget total is required";
    }

    setErrors(errors);
    if (Object.keys(errors).length) return;

    if (!!budgetId) update(formState);
    else create({ jobId, payload: formState });
  };

  return (
    <Page>
      <CommandBar>
        {jobId && (
          <>
            <CommandButton
              iconProps={{ iconName: "Undo" }}
              text="Discard Changes"
              onClick={() =>
                history.push(`/clients/${clientId}/jobs/${jobId}/budgets`)
              }
              disabled={isLoading}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text={budgetId ? "Update Budget" : "Create Budget"}
              onClick={onSubmit}
              disabled={isLoading}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>
            {!budgetId ? "Create new Job Budget" : `Update ${formState?.name}`}
          </h2>
        </header>
        <Paper>
          <div className="da-form">
            {error && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {error}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow>
                <TextField
                  autoFocus
                  label="Reference"
                  value={formState?.name ?? ""}
                  errorMessage={errors?.name}
                  onChange={(_: any, name: any) => {
                    if (!!name) {
                      setErrors({ ...errors, name: undefined });
                    }
                    setFormState((state: any) => ({
                      ...state,
                      name: name?.toString(),
                    }));
                  }}
                  disabled={isLoading}
                />
              </Stack.Item>
              <Stack.Item grow>
                <ComboBox
                  label="Opportunity"
                  allowFreeform={true}
                  autoComplete={"on"}
                  options={opportunityOpts}
                  selectedKey={formState?.opporunity_id}
                  onChange={(_: any, newValue: any) =>
                    newValue &&
                    setFormState((state: any) => ({
                      ...state,
                      opporunity_id: newValue.key,
                    }))
                  }
                  disabled={isLoading}
                />
              </Stack.Item>
              <Stack.Item grow>
                <TextField
                  autoFocus
                  label="Budget Total"
                  type="number"
                  errorMessage={errors?.budget_total}
                  value={formState?.budget_total?.toString() ?? "0"}
                  onChange={(_: any, value: any) => {
                    let budget_total = parseFloat(value);
                    if (isNaN(budget_total)) budget_total = 0;
                    else {
                      setErrors({ ...errors, budget_total: undefined });
                    }
                    setFormState((state: any) => ({
                      ...state,
                      budget_total,
                    }));
                  }}
                  disabled={isLoading}
                />
              </Stack.Item>
            </Stack>
            <TextField
              label="Comments"
              value={formState?.comments ?? ""}
              onChange={(_: any, comments: any) =>
                setFormState((state: any) => ({ ...state, comments }))
              }
              disabled={isLoading}
              multiline
              autoAdjustHeight
            />
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientJobBudgetFormPage;
