import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IJob } from '../types/IJob';

export async function updateJob(clientId: string, job: IJob) {
  const { id: _, ...rest } = job;
  const response = await fetch(`${apiEndpoint}/jobs/${job.id}`, {
    method: 'PUT',
    headers: await standardHeaders(),
    mode: 'cors',
    body: JSON.stringify({
      ...rest,
      client_id: parseInt(clientId),
    }),
  });
  if (response.ok) {
    return await response.json();
  }
  return null;
}
