/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Callout,
  CommandButton,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Page } from '../Page';
import { nanoid } from 'nanoid';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import DataGrid, {
  Column,
  FillEvent,
  HeaderRendererProps,
  Row,
  RowRendererProps,
} from 'react-data-grid';
import { IUserAccess } from '../../providers/CurrentUserProvider';
import withAccess from '../../hocs/withAccess';
import { manageSkillsPermission } from '../../enums/permissions';
import './TimesheetPage.css';
import { useSaveSkillRatings, useSkillRatings } from 'hooks/useSkillRatings';
import { useSkills } from 'hooks/useSkills';
import { useSubordinateMembers } from 'hooks/useMembers';
import { IMember } from 'types/IMember';
import { ISkill } from 'types/ISkill';
import { ISaveSkillRating, ISkillRating } from 'types/ISkillRating';
import {
  DropdownEditor,
  CellExpanderFormatter,
  TreeViewRow,
  toggleSubRow,
} from 'components/DataGrid';
import './SkillPrioritiesPage.css';
import { getPersonNameInitials } from 'components/utils/MiscHelper';
import { useToast } from 'providers/ToastContextProvider';

type SkillPriorityRow = TreeViewRow & {
  name: string;
  level: number;
  skillId?: number;
  isEditable?: boolean;
  [key: string]: any;
};

type DirtyState = {
  memberId: number;
  priority: number;
  skillId: number;
};

const PRIORITY_KEY_PREFIX = 'memberPriority';

const getMemberIdFromPriorityKey = (key: string): number =>
  parseInt(key.replace(PRIORITY_KEY_PREFIX, ''));

const formatMemberPriorityKey = (member: IMember): string =>
  `${PRIORITY_KEY_PREFIX}${member.id}`;

function distinct(arr: string[]) {
  return Array.from(new Set(arr)).filter((a) => Boolean(a?.trim()));
}

function buildRows(
  skills: ISkill[],
  members: IMember[],
  ratings: ISkillRating[]
): SkillPriorityRow[] {
  const level1 = distinct(skills.map((s) => s.level1));
  return level1.map((lv1) => {
    const lv1Id = nanoid();
    const colLevel1 = {
      id: lv1Id,
      name: lv1,
      isExpanded: false,
      level: 0,
    } as SkillPriorityRow;
    const level2 = distinct(
      skills.filter((s) => s.level1 === lv1).map((s) => s.level2)
    );

    colLevel1.children = level2.map((lv2) => {
      const lv2Id = nanoid();
      const colLevel2 = {
        parentId: colLevel1.id,
        id: lv2Id,
        name: lv2,
        isExpanded: false,
        level: 1,
      } as SkillPriorityRow;
      const level3 = distinct(
        skills
          .filter((s) => s.level1 === lv1 && s.level2 === lv2)
          .map((s) => s.level3)
      );
      colLevel2.children = level3.map((lv3) => {
        const lv3Id = nanoid();
        const colLevel3 = {
          parentId: colLevel2.id,
          id: lv3Id,
          name: lv3,
          isExpanded: false,
          level: 2,
        } as SkillPriorityRow;
        const filteredSkills = skills.filter(
          (s) => s.level1 === lv1 && s.level2 === lv2 && s.level3 === lv3
        );
        colLevel3.children = filteredSkills.map((skill) => {
          const skillId = nanoid();
          const colSkills = {
            parentId: colLevel3.id,
            id: skillId,
            name: skill.skill,
            skillId: skill.id,
            isExpanded: false,
            isEditable: true,
            level: 3,
          } as SkillPriorityRow;

          const membersRows = members.reduce((value, member) => {
            const rating = ratings.find(
              (r) => r.skill_id === skill.id && r.member_id === member.id
            );
            value[formatMemberPriorityKey(member)] = rating?.priority ?? 0;
            return value;
          }, {} as SkillPriorityRow);

          return { ...colSkills, ...membersRows };
        });

        return colLevel3;
      });

      return colLevel2;
    });

    return colLevel1;
  });
}

const CustomRowFormatter = (
  isDirty: boolean,
  rendererProps: RowRendererProps<SkillPriorityRow>
) => {
  const classNames = [];
  if (isDirty) {
    classNames.push('row-dirty');
  }
  return (
    <div className={classNames?.join(' ')}>
      <Row {...rendererProps} />
    </div>
  );
};

export const SkillPrioritiessPage: React.FC<{
  userAccess: IUserAccess;
}> = (props: { userAccess: IUserAccess }) => {
  const { setToastSuccessMessage } = useToast();
  const [rows, setRows] = useState<SkillPriorityRow[]>([]);
  const [generalError, setGeneralError] = useState('');
  const { skillRatings } = useSkillRatings();
  const { skills } = useSkills();
  const { members } = useSubordinateMembers();
  const [modifiedItems, setModifiedItems] = useState<DirtyState[]>([]);
  const { saveSkillRatings, isLoading: isSaving } = useSaveSkillRatings({
    onSuccess: () => {
      setModifiedItems([]);
      setToastSuccessMessage('Skill priorities have been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update skill priorities.'
      ),
  });
  const isDirty = useMemo(
    () => Boolean(modifiedItems?.length),
    [modifiedItems?.length]
  );

  useEffect(() => {
    setRows(buildRows(skills ?? [], members ?? [], skillRatings ?? []));
  }, [skills, members, skillRatings]);

  const handleRowsChange = (
    currentRows: SkillPriorityRow[],
    { indexes, column }: { indexes: number[]; column: any }
  ) => {
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      const row = currentRows[index];
      if (row) {
        const memberId = getMemberIdFromPriorityKey(column.key);
        setModifiedItems((prev) => {
          let newValue = [...prev];
          const modifiedItem = newValue.find(
            (i) => i.memberId === memberId && i.skillId === row.skillId
          );
          const newPriority = parseInt(row[column.key] as string);
          if (modifiedItem) {
            modifiedItem.priority = newPriority;
          } else {
            newValue.push({
              memberId,
              skillId: row.skillId!,
              priority: newPriority,
            });
          }
          return newValue;
        });
      }
    }
    setRows(currentRows);
  };

  const handleSave = () => {
    if (!isDirty) return;
    const payload = modifiedItems.map((item) => {
      const skillRating = skillRatings.find(
        (sr) =>
          sr.skill_id.toString() === item.skillId.toString() &&
          sr.member_id.toString() === item.memberId.toString()
      );
      return {
        skill_rating_id: skillRating?.id,
        skill_id: item.skillId,
        member_id: item.memberId,
        priority: item.priority,
      } as ISaveSkillRating;
    });
    saveSkillRatings(payload);
  };

  const handleOnFill = (event: FillEvent<SkillPriorityRow>) => {
    // handles on drag-fill to exclude cells that are not supposed to have values
    const value = event.sourceRow[event.columnKey];
    return event.targetRows.map((tr) => ({
      ...tr,
      ...(tr.isEditable ? { [event.columnKey]: value } : {}),
    }));
  };

  const columnsTree: Column<SkillPriorityRow>[] = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 400,
        resizable: true,
        frozen: true,
        formatter: ({ row, isCellSelected }) => {
          const hasChildren = Boolean(row.children?.length!);
          const style = {
            paddingLeft: `${(row.level + 1) * 15}px`,
          };
          return (
            <>
              {hasChildren && (
                <CellExpanderFormatter
                  isCellSelected={isCellSelected}
                  expanded={row.isExpanded === true}
                  onCellExpand={() => {
                    const res = toggleSubRow(rows as TreeViewRow[], row.id);
                    setRows(res as SkillPriorityRow[]);
                  }}
                  onCellExpandAll={() => {
                    const res = toggleSubRow(
                      rows as TreeViewRow[],
                      row.id,
                      true
                    );
                    setRows(res as SkillPriorityRow[]);
                  }}
                />
              )}
              <div className="rdg-cell-value">
                <div style={style}>{row.name}</div>
              </div>
            </>
          );
        },
      },
      ...members.map((m) => ({
        key: formatMemberPriorityKey(m),
        name: getPersonNameInitials(m.name)!,
        headerRenderer: (props: HeaderRendererProps<SkillPriorityRow, any>) => {
          const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
            useBoolean(false);
          const elementId = useId(`col-member-${m.id}`);

          const handleMouseEnter = () => {
            if (!isCalloutVisible) {
              toggleIsCalloutVisible();
            }
          };
          const handleMouseLeave = () => {
            if (isCalloutVisible) {
              toggleIsCalloutVisible();
            }
          };

          return (
            <div>
              <span
                id={elementId}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="member-column"
              >
                {props.column.name}
              </span>
              {isCalloutVisible && (
                <Callout
                  onDismiss={toggleIsCalloutVisible}
                  target={`#${elementId}`}
                  setInitialFocus
                  style={{ padding: 10 }}
                >
                  {m.name}
                </Callout>
              )}
            </div>
          );
        },
        resizable: true,
        editor: (props: any) => {
          const row = props.row as SkillPriorityRow;
          if (!row?.isEditable) return null;
          return (
            <DropdownEditor
              {...props}
              valueKey="value"
              textKey="value"
              options={Array.from(Array(4).keys()).map((k) => ({
                value: k,
              }))}
            />
          );
        },
      })),
    ];
  }, [rows, setRows, members]);

  const handleRowRenderer = (
    rendererProps: RowRendererProps<SkillPriorityRow>
  ) => {
    let dirty = false;
    const row = rendererProps.row;
    if (row.isEditable && row.skillId) {
      dirty = modifiedItems.some((i) => i.skillId === row.skillId);
    }
    return CustomRowFormatter(dirty, rendererProps);
  };

  return (
    <Page>
      <CommandBar noBreadcrumbs>
        <Spacer />
        <CommandButton
          iconProps={{ iconName: 'Save' }}
          text="Save Changes"
          onClick={handleSave}
          disabled={!isDirty || isSaving}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>Skill Priorities</h2>
        </header>
        {generalError && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {generalError}
          </MessageBar>
        )}
        <div className="skill-priorities-grid">
          <DataGrid
            columns={columnsTree}
            rows={rows}
            onRowsChange={handleRowsChange}
            onFill={handleOnFill}
            rowRenderer={handleRowRenderer}
            className="rdg-light"
          />
        </div>
      </Container>
    </Page>
  );
};

export default withAccess(manageSkillsPermission, SkillPrioritiessPage);
