import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  CommandButton,
  Stack,
  TextField,
  MessageBar,
  MessageBarType,
  DatePicker,
  Label,
  Dropdown,
  FontIcon,
  ISelectableOption,
} from "@fluentui/react";
import { Page } from "../Page";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Paper } from "../Paper";
import { useClientSingle } from "../../hooks/useClients";
import {
  useCreateJobMilestone,
  useDeleteJobMilestone,
  useJobMilestone,
  useJobSingle,
  useUpdateJobMilestone,
} from "../../hooks/useJobs";

import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";
import { milestoneStatusTypesDropdownOptions } from "enums/milestoneStatus";
import { useToast } from "providers/ToastContextProvider";

export const ClientMilestoneFormPage: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setMilestoneSuccessMessage } = useToast();
  const { milestoneId, clientId, jobId } = useParams<any>();
  const { jobMilestone: milestone } = useJobMilestone(jobId, milestoneId);
  const [formState, setFormState] = useState(
    milestone ?? {status: 1}
  );
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState("");

  const history = useHistory();
  const { client } = useClientSingle(clientId);
  const { job } = useJobSingle(jobId);

  const { create, isLoading: isCreating } = useCreateJobMilestone({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/milestones`);
      setMilestoneSuccessMessage('Milestone has been created.')
    },
    onError: () =>
      setGeneralError(
        "An error occurred while trying to create a new job milestone."
      ),
  });
  const { update, isLoading: isUpdating } = useUpdateJobMilestone({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${r.job_id}/milestones`);
      setMilestoneSuccessMessage('Milestone has been updated.')
    },
    onError: () =>
      setGeneralError("An error occurred while trying to update the job milestone."),
  });
  const { delete: deleteMilestone, isLoading: isDeleting } = useDeleteJobMilestone({
    jobId,
    onSuccess: (r) => {
      history.push(`/clients/${clientId}/jobs/${jobId}/milestones`);
      setMilestoneSuccessMessage('Milestone has been deleted.')
    },
    onError: () =>
      setGeneralError("An error occurred while trying to delete the job milestone."),
  });

  const isSubmitting = useMemo(
    () => isUpdating || isCreating || isDeleting,
    [isCreating, isDeleting, isUpdating]
  );
  
  const isEdit = formState.id || milestoneId;

  useEffect(() => {
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      {
        key: "client",
        text: client.name,
        onClick: () => history.push(`/clients/${client.id}`),
      },
      {
        key: "job",
        text: job.name,
        onClick: () => history.push(`/clients/${client.id}/jobs/${job.id}`),
      },
      ...(isEdit
        ? [
            {
              key: "milestone",
              text: formState.name,
              onClick: () =>
                history.push(
                  `/clients/${client.id}/jobs/${job.id}/milestones`
                ),
            },
            { key: "edit", text: "Edit Milestone" },
          ]
        : [{ key: "new", text: "New Milestone" }]),
    ]);
  }, [history, client, job]);

  const onSubmit = useCallback(async () => {
    const errors: any = {};
    if (!formState.name) {
      errors.name = "Field is required.";
    }

    if (!formState.estimated_date_complete) {
      errors.estimated_date_complete = "Field is required.";
    }

    if (!formState.estimated_hours_spent) {
      errors.estimated_hours_spent = "Field is required.";
    }
    
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    const payload: any = {
      name: formState.name,
      status: parseFloat(formState.status + ''),
      estimated_hours_spent: parseFloat(formState.estimated_hours_spent + ''),
      comments: formState.comments || '',
      job_id: +jobId,
    };

    if (!isEdit) {
      create({
        ...payload,
        estimated_date_complete: onFormatDate(formState.estimated_date_complete),
        actual_date_complete: formState.actual_date_complete ? onFormatDate(formState.actual_date_complete) : null,
      });
    } else {
      update({
        ...payload,
        id: formState.id || milestoneId,
        estimated_date_complete: onFormatDate(formState.estimated_date_complete),
        actual_date_complete: formState.actual_date_complete ? onFormatDate(formState.actual_date_complete) : null,
      });
    }
  }, [setErrors, setGeneralError, history, formState, clientId, jobId]);

  const onDelete = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete this job milestone?")) {
      deleteMilestone({ jobId, milestoneId });
    }
  }, [jobId, milestoneId]);

  const onRenderTitle = (option: ISelectableOption[]) => {
    const color = option[0].text;
      return (
        <>
          <FontIcon aria-label="CompletedSolidIcon" iconName="Info" style={{ color }}/>
          <span style={{ textTransform: 'capitalize', margin: '3px 0 4px 6px' }}>{color}</span>
        </>
      )
  };

  const onRenderOption = (option: ISelectableOption) => {
    const color = option.text;
      return (
        <>
          <FontIcon aria-label="CompletedSolidIcon" iconName="Info" style={{ 
            color,
            position: 'absolute',
            top: '1px',
          }}/>
          <span style={{ textTransform: 'capitalize', margin: '3px 0px 4px 20px' }}>{color}</span>
        </>
      )
  };

  const onFormatDate = (date?: Date): string => {
    if (!date) return '';

    const d1 = new Date((date));

    const year = d1.getFullYear();
    const month = ("0" + (d1.getMonth() + 1)).slice(-2)
    const day = ("0" + d1.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  };

  return (
    <Page>
      <CommandBar>
        {isEdit && (
          <>
            <CommandButton
              iconProps={{ iconName: "Delete" }}
              text="Delete Milestone"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Update Milestone"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!isEdit && (
          <>
            <CommandButton
              iconProps={{ iconName: "Cancel" }}
              text="Cancel"
              onClick={() => {
                setMilestoneSuccessMessage('');
                history.push(`/clients/${clientId}/jobs/${jobId}/milestones`)
              }}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Create New Milestone"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>
            {isEdit ? `Edit Milestone` : `Create New Milestone for ${job.name}`}
          </h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item>
                <TextField
                  autoFocus
                  label="Name"
                  disabled={isSubmitting}
                  value={formState.name}
                  errorMessage={errors.name}
                  onChange={(_: any, name: any) =>
                    setFormState((state) => ({ ...state, name }))
                  }
                  style={{ width: 510 }}
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <Dropdown
                  label="Status"
                  defaultSelectedKey={formState.status.toString()}
                  options={milestoneStatusTypesDropdownOptions}
                  disabled={isSubmitting}
                  onRenderTitle={(option: any) => onRenderTitle(option)}
                  onRenderOption={(option: any) => onRenderOption(option)}
                  onChange={(_: any, status: any) =>
                    setFormState((state) => ({ ...state, status: status.key }))
                  }
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={3}>
                <Label> Estimated Date Completed </Label>
                <DatePicker
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  formatDate={onFormatDate}
                  disabled={isSubmitting}
                  isRequired={!!errors.estimated_date_complete}
                  value={formState.estimated_date_complete ? new Date(formState.estimated_date_complete) : undefined}
                  onSelectDate={(estimated_date_complete: any) =>
                    setFormState((state) => ({ ...state, estimated_date_complete }))
                  }
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <TextField
                  label="Estimated Hours Spent"
                  disabled={isSubmitting}
                  value={formState.estimated_hours_spent?.toString()}
                  errorMessage={errors.estimated_hours_spent}
                  onChange={(_: any, estimated_hours_spent: any) =>
                    setFormState((state) => ({ ...state, estimated_hours_spent }))
                  }
                />
              </Stack.Item>
              <Stack.Item grow={3}>
                <Label> Actual Date Completed </Label>
                <DatePicker
                  placeholder="Select a date..."
                  ariaLabel="Select a date"
                  formatDate={onFormatDate}
                  disabled={isSubmitting}
                  value={formState.actual_date_complete ? new Date(formState.actual_date_complete) : undefined}
                  onSelectDate={(actual_date_complete: any) =>
                    setFormState((state) => ({ ...state, actual_date_complete }))
                  }
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={1}>
                <TextField
                  label="Comments"
                  disabled={isSubmitting}
                  value={formState?.comments}
                  onChange={(_: any, comments: any) =>
                    setFormState((state) => ({ ...state, comments }))
                  }
                  multiline
                  autoAdjustHeight
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientMilestoneFormPage;
