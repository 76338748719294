import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  CommandButton,
  Dropdown,
  MessageBar,
  MessageBarType,
  Stack,
  TextField,
} from "@fluentui/react";
import { ClientIndustryDropdownOptions } from "../../enums/clientIndustry";
import { stateOptions } from "../../enums/stateOptions";
import {
  useClientSingle,
  useCreateClient,
  useUpdateClient,
  useDeleteClient,
} from "../../hooks/useClients";
import { useBreadcrumbs } from "../../providers/BreadcrumbsProvider";
import { IClient } from "../../types/IClient";
import { CommandBar } from "../CommandBar";
import { Container } from "../Container";
import { Page } from "../Page";
import { Paper } from "../Paper";

export const ClientFormPage: React.FC = () => {
  const { clientId } = useParams<any>();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { client } = useClientSingle(clientId);
  const [formState, setFormState] = useState(client ?? {});
  const [errors, setErrors] = useState<any>({});
  const [generalError, setGeneralError] = useState("");
  const { update: updateClient, isLoading: isUpdating } = useUpdateClient({
    onError: () =>
      setGeneralError(
        "An error occurred while trying to update the client details."
      ),
    onSuccess: (data) => {
      history.push(`/clients/${data.id}`);
    },
  });
  const { create: createClient, isLoading: isCreating } = useCreateClient({
    onError: () =>
      setGeneralError("An error occurred while trying to create a new client."),
    onSuccess: (data) => {
      history.push(`/clients/${data.id}`);
    },
  });
  const { delete: deleteClient, isLoading: isDeleting } = useDeleteClient({
    onError: () =>
      setGeneralError("An error occurred while trying to delete the client."),
    onSuccess: () => history.push(`/clients`),
  });

  const isSubmitting = useMemo(
    () => isUpdating || isCreating || isDeleting,
    [isCreating, isDeleting, isUpdating]
  );

  useEffect(() => {
    if (!setBreadcrumbs) return;
    setBreadcrumbs([
      {
        key: "clients",
        text: "Clients",
        onClick: () => history.push("/clients"),
      },
      ...(client
        ? [
            {
              key: "client",
              text: client.name,
              onClick: () => history.push(`/clients/${clientId}`),
            },
            { key: "edit", text: "Edit" },
          ]
        : [{ key: "new", text: "New" }]),
    ]);
  }, [history, client, clientId]);

  useEffect(() => {
    setFormState(client ?? {});
  }, [client]);

  const onSubmit = useCallback(async () => {
    const errors: any = {};
    if (!formState.name) {
      errors.name = "Client Name is required.";
    }
    if (!formState.initials) {
      errors.initials = "Client Initals is required.";
    }
    if (!formState.address) {
      errors.address = "Address is required.";
    }
    if (!formState.suburb) {
      errors.suburb = "Suburb is required.";
    }
    if (!formState.postcode) {
      errors.postcode = "Post Code is required.";
    }
    if (!formState.state) {
      errors.state = "state is required.";
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    } else {
      setErrors({});
    }

    if (!clientId) {
      createClient({
        ...formState,
        industry_id: !!formState.industry_id
          ? parseInt(formState.industry_id.toString(), 10)
          : undefined,
      });
    } else {
      updateClient(formState);
    }
  }, [formState, setErrors, clientId, updateClient, createClient]);

  const onDelete = useCallback(async () => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      deleteClient(clientId);
    }
  }, [deleteClient, clientId]);

  return (
    <Page>
      <CommandBar>
        {client && (
          <>
            <CommandButton
              iconProps={{ iconName: "Undo" }}
              text="Discard Changes"
              onClick={() => history.push(`/clients/${clientId}`)}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Delete" }}
              text="Delete Client"
              onClick={onDelete}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Update Client"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
        {!client && (
          <>
            <CommandButton
              iconProps={{ iconName: "Cancel" }}
              text="Cancel"
              onClick={() => history.push("/clients")}
              disabled={isSubmitting}
            />
            <CommandButton
              iconProps={{ iconName: "Save" }}
              text="Create Client"
              onClick={onSubmit}
              disabled={isSubmitting}
            />
          </>
        )}
      </CommandBar>
      <Container>
        <header>
          <h2>{client ? "Edit Client" : "Create New Client"}</h2>
        </header>
        <Paper>
          <div className="da-form">
            {generalError && (
              <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
              >
                {generalError}
              </MessageBar>
            )}
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={3}>
                <TextField
                  autoFocus
                  label="Client Name"
                  value={formState?.name}
                  onChange={(_: any, name: any) =>
                    setFormState((state: IClient) => ({ ...state, name }))
                  }
                  errorMessage={errors.name}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1} style={{ flexBasis: 0 }}>
                <TextField
                  autoFocus
                  label="Client Initials"
                  value={formState?.initials}
                  onChange={(_: any, initials: any) =>
                    setFormState((state: IClient) => ({ ...state, initials }))
                  }
                  errorMessage={errors.initials}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <TextField
              label="Address"
              value={formState?.address}
              onChange={(_: any, address: any) =>
                setFormState((state: IClient) => ({ ...state, address }))
              }
              errorMessage={errors.address}
              disabled={isSubmitting}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={2}>
                <TextField
                  label="Suburb"
                  value={formState?.suburb}
                  onChange={(_: any, suburb: any) =>
                    setFormState((state: IClient) => ({ ...state, suburb }))
                  }
                  errorMessage={errors.suburb}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <TextField
                  label="Post Code"
                  value={formState?.postcode}
                  onChange={(_: any, postcode: any) =>
                    setFormState((state: IClient) => ({ ...state, postcode }))
                  }
                  errorMessage={errors.postcode}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <Dropdown
                  label="State"
                  styles={{ dropdown: { width: "100%", minWidth: "165px" } }}
                  selectedKey={formState?.state?.toUpperCase()}
                  options={stateOptions}
                  onChange={(_: any, value: any) =>
                    setFormState((state: IClient) => ({
                      ...state,
                      state: value.key,
                    }))
                  }
                  errorMessage={errors.state}
                  disabled={isSubmitting}
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack.Item grow={3}>
                <TextField
                  autoFocus
                  label="ABN"
                  value={formState?.abn}
                  onChange={(_: any, abn: any) =>
                    setFormState((state: IClient) => ({ ...state, abn }))
                  }
                  errorMessage={errors.name}
                  disabled={isSubmitting}
                />
              </Stack.Item>
              <Stack.Item grow={2} style={{ flexBasis: 0 }}>
                <Dropdown
                  label="Industry"
                  options={ClientIndustryDropdownOptions}
                  selectedKey={formState.industry_id?.toString()}
                  disabled={isSubmitting}
                  onChange={(_: any, newValue: any) =>
                    setFormState((state: IClient) => ({
                      ...state,
                      industry_id: parseInt(newValue.key, 10),
                    }))
                  }
                  errorMessage={errors.industry}
                />
              </Stack.Item>
            </Stack>
          </div>
        </Paper>
      </Container>
    </Page>
  );
};

export default ClientFormPage;
