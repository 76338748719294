import React, { useMemo } from "react";
import { ITimesheet } from "../types/ITimesheet";
import { IJobRole } from "../types/IJobRole";
import { timesheetStatus } from "../enums/timesheetStatus";
import { useClientSingle } from "../hooks/useClients";
import { useJobSingle, useJobRoleMembers } from "../hooks/useJobs";
import "./TimesheetDetailsCard.css";

export const TimesheetDetailsCard: React.FC<{ data: ITimesheet }> = ({
  data,
}) => {
  const { client } = useClientSingle(data.client_id.toString());
  const { job } = useJobSingle(data.job_id.toString());
  const { jobRoleMembers: members } = useJobRoleMembers(
    data.job_id.toString(),
    data.job_role_id.toString()
  );

  const jobRole = useMemo(() => {
    const role = (job.roles || [])?.find(
      (role) => +role.id === +data.job_role_id
    );
    if (role) {
      return {
        ...role,
        members,
        job,
      };
    }
    return {
      job,
    };
  }, [data.job_role_id, job, members]) as IJobRole;

  return (
    <div className="da-timesheet-details-card">
      <ul className="da-unordered-list">
        <li>
          <h4>Client</h4>
          <p>{client.name}</p>
        </li>
        <li>
          <h4>Job</h4>
          <p>{jobRole.job?.name}</p>
        </li>
        <li>
          <h4>Job Role</h4>
          <p>{jobRole.name}</p>
        </li>
        <li>
          <h4>Hours</h4>
          <p>{data.hours}</p>
        </li>
        <li>
          <h4>Status</h4>
          <p>{timesheetStatus[data.status_id]}</p>
        </li>
      </ul>
    </div>
  );
};
