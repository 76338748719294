import { SpinButton, ISpinButtonProps } from '@fluentui/react';
import { EditorProps } from './types';

type NumberEditorProps = { spinButtonProps?: ISpinButtonProps } & EditorProps;

const NumberEditor = (props: NumberEditorProps) => {
  const handleChange = (_: any, newValue?: string) => {
    const asNumber = isNaN(parseFloat(newValue!))
      ? undefined
      : parseFloat(newValue!);
    props.onRowChange({ ...props.row, [props.column.key]: asNumber }, true);
  };

  return (
    <SpinButton
      value={props.row[props.column.key]}
      onChange={handleChange}
      onBlur={props.onClose}
      {...props.spinButtonProps}
    />
  );
};

export default NumberEditor;
