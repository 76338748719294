import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IContextualMenuProps } from '@fluentui/react';
import {
  OPPORTUNITIES_QUERY_KEYS,
  JOBS_QUERY_KEYS,
  BUDGETS_QUERY_KEYS,
} from '../constants/queries';
import { businessUnit, businessUnitInitals } from '../enums/businessUnit';
import { ClientIndustry } from '../enums/clientIndustry';
import { jobTypes, jobTypesInitals } from '../enums/jobTypes';
import { opportunityForecastTypes } from '../enums/opportunityForecastTypes';
import { opportunityStageTypes } from '../enums/opportunityStageTypes';
import { solutions, solutionsInitals } from '../enums/solutions';
import { getOpportunities } from '../resources/getOpportunities';
import { getOpportunity } from '../resources/getOpportunity';
import { getJobBudgetByJobId } from '../resources/getJobBudget';
import { createOpportunity } from '../resources/createOpportunity';
import { updateOpportunity } from '../resources/updateOpportunity';
import { deleteOpportunity } from '../resources/deleteOpportunity';
import { createJob } from '../resources/createJob';
import { updateJobBudgetOnly } from '../resources/updateJobBudget';
import { IDropdownItem } from '../types/IDropdownItem';
import {
  IOpportunity,
  IOpportunityNew,
  IOpportunityView,
} from '../types/IOpportunity';
import { IJob } from '../types/IJob';
import {
  ICreateResult,
  IDeleteResult,
  IMutateOption,
  IQueryResult,
  IUpdateResult,
} from '../types/IQueryResult';
import { parseNumber } from '../helpers/NumberHelper';
import { useClients } from './useClients';
import { useMembers } from './useMembers';

import { customAlphabet } from 'nanoid';

export function useOpportunities(): IQueryResult & {
  opportunities: IOpportunity[] | undefined;
} {
  const {
    data: opportunities,
    isLoading,
    error,
  } = useQuery<IOpportunity[], Error>(OPPORTUNITIES_QUERY_KEYS.getAll(), () =>
    getOpportunities()
  );
  return {
    opportunities,
    isLoading,
    error,
  };
}

export function useOpportunitiesDropdownItems(opts?: {
  filter?: (o: IOpportunity) => boolean;
  map?: (o: IOpportunity) => IDropdownItem;
}): { dropdownItems: IDropdownItem[] } {
  const { opportunities } = useOpportunities();
  const dropdownItems = useMemo(() => {
    if (!opportunities?.length) return [];
    let filteredItems = [...opportunities];
    if (opts?.filter) filteredItems = filteredItems.filter(opts.filter);
    return opts?.map
      ? filteredItems.map(opts.map)
      : filteredItems.map((o) => ({ key: o.id, text: o.name }));
  }, [opportunities, opts?.filter, opts?.map]);

  return {
    dropdownItems,
  };
}

export function useOpportunitySingle(id: string): IQueryResult & {
  opportunity: IOpportunity | IOpportunityView;
} {
  const { clients } = useClients();
  const { data, isLoading, error } = useQuery(
    OPPORTUNITIES_QUERY_KEYS.getSingle(id),
    () => getOpportunity(id),
    {
      enabled: !!id,
    }
  );

  const opportunity = useMemo(() => {
    if (!data) return null;
    const clientName =
      clients?.find((client) => +client.id === +data!.client_id!)?.name ??
      'Unknown Client';
    return {
      ...data,
      client: clientName,
      stage: opportunityStageTypes[+data.stage_id],
      forecast: opportunityForecastTypes[+data!.forecast_id!],
      job_type_name: jobTypes[+data!.job_type!],
      job_type_initals: jobTypesInitals[+data!.job_type!],
    };
  }, [data, clients]);

  return { opportunity, isLoading, error };
}

export function useOpportunitiesLists(): IQueryResult & {
  opportunities: IOpportunity[] | undefined;
} {
  const {
    clients = [],
    isLoading: clientsLoading,
    error: clientsError,
  } = useClients();
  const {
    members = [],
    isLoading: membersLoading,
    error: membersError,
  } = useMembers();

  const {
    data,
    isLoading: opportunitiesLoading,
    error: opportunitiesError,
  } = useQuery<IOpportunity[], Error>(OPPORTUNITIES_QUERY_KEYS.getLists(), () =>
    getOpportunities()
  );

  const isLoading = useMemo(
    () => clientsLoading || membersLoading || opportunitiesLoading,
    [clientsLoading, membersLoading, opportunitiesLoading]
  );

  const error = useMemo(
    () => clientsError || membersError || opportunitiesError,
    [clientsError, membersError, opportunitiesError]
  );

  const getInitials = (name: string) => {
    return name
      .replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      ?.join('');
  };

  const opportunities = useMemo(() => {
    if (!data) return [];
    return data.map((item: IOpportunity) => {
      const client = clients.find((client) => +client.id === +item.client_id);
      const clientName = client?.name ?? 'Unknown Client';
      const memberFullNames = [];
      const memberInitals = [];
      const clientInitials = client?.initials ?? 'Unknown';
      for (var i = 0; i < item.key_person.length; i++) {
        for (var k = 0; k < members.length; k++) {
          if (+item.key_person[i] === members[k].id) {
            memberInitals.push(getInitials(members[k].name));
            memberFullNames.push(members[k].name);
          }
        }
      }

      let accountManagerFullName = '';
      let accountManagerInitals = '';
      for (var k = 0; k < members.length; k++) {
        if (+item.account_manager_id === members[k].id) {
          accountManagerFullName = members[k].name;
          let initials = getInitials(members[k].name);
          if (initials) {
            accountManagerInitals = initials;
          }
        }
      }

      const solutionNames: string[] = [];
      const solutionFullNames: string[] = [];
      item.solution.forEach((item) => {
        const name = solutionsInitals[parseInt(item, 10)];
        const fullName = solutions[parseInt(item, 10)];
        solutionFullNames.push(fullName);
        solutionNames.push(name);
      });

      const businessUnitName = businessUnitInitals[item.business_unit];
      const businessUnitFullName = businessUnit[item.business_unit];
      const industryName = client?.industry_id
        ? ClientIndustry[client.industry_id]?.shortName
        : '';
      const industryFullname = client?.industry_id
        ? ClientIndustry[client.industry_id]?.label
        : '';

      return {
        ...item,
        client: clientName,
        clientInitials,
        stage: opportunityStageTypes[+item.stage_id],
        job_type_name: jobTypes[+item.job_type],
        key_person_names: memberInitals.join('; '),
        key_person_full_names: memberFullNames.join('; '),
        account_manager_name: accountManagerInitals,
        account_manager_full_name: accountManagerFullName,
        business_unit_name: businessUnitName,
        business_unit_full_name: businessUnitFullName,
        solution_names: solutionNames.join(', '),
        solution_full_names: solutionFullNames.join(', '),
        value_currency: item.value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
        forecast: opportunityForecastTypes[+item.forecast_id]?.charAt(0),
        job_type_initals: jobTypesInitals[+item.job_type],
        industry_name: industryName,
        industry_full_name: industryFullname,
        industry_id: client?.industry_id,
      } as unknown as IOpportunityView;
    });
  }, [clients, members, data]);

  return {
    opportunities,
    isLoading,
    error,
  };
}

export interface IOpportunitiesContextualMenu {
  menuProps?: IContextualMenuProps;
  sortStack: string[];
  filters: { [key: string]: string | undefined };
  checkboxFilters: {
    [key: string]: {
      id: number;
      label: string;
      checked: boolean;
      referenceKey: string;
      isArray?: boolean;
    }[];
  };
}

export const getOpportunitiesContextualMenu = () => ({
  checkboxFilters: {
    stage: Object.entries(opportunityStageTypes).map(([id, label]) => ({
      id: +id,
      label,
      checked: [2, 4, 5, 6].includes(+id),
      referenceKey: 'stage_id',
    })),
    forecast: Object.entries(opportunityForecastTypes).map(([id, label]) => {
      return {
        id: +id,
        label,
        checked: ['1', '2'].includes(id),
        referenceKey: 'forecast_id',
      };
    }),
    business_unit_name: Object.entries(businessUnit).map(([id, label]) => {
      return {
        id: +id,
        label,
        checked: Object.keys(businessUnit).includes(id),
        referenceKey: 'business_unit',
      };
    }),
    solution_names: Object.entries(solutions).map(([id, label]) => {
      return {
        id: +id,
        label,
        checked: Object.keys(solutions).includes(id),
        referenceKey: 'solution',
        isArray: true,
      };
    }),
    industry_name: Object.entries(ClientIndustry).map(([id, value]) => {
      return {
        id: +id,
        label: '(' + value.shortName + ') ' + value.label,
        checked: Object.keys(ClientIndustry).includes(id),
        referenceKey: 'industry_id',
      };
    }),
  },
  filters: {},
  sortStack: ['+close_date'],
  menuProps: undefined,
});

function generateReference() {
  const nanoid = customAlphabet('1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);
  return nanoid();
}

const createJobByOpportunity = (opportunity: IOpportunityNew): Promise<IJob> =>
  createJob(opportunity.client_id.toString(), {
    name: opportunity.name,
    client_id: opportunity.client_id,
    status: 2,
    business_unit: opportunity.business_unit,
    reference: generateReference(),
  });

const increaseJobBudget = async (jobId: number, amount: number) => {
  const budget = await getJobBudgetByJobId(jobId.toString());
  const value = parseNumber(amount?.toString());
  if (value) {
    budget.budget_total = parseNumber(budget.budget_total?.toString()) + value;
    await updateJobBudgetOnly(budget);
  }
};

const decreaseJobBudget = async (jobId: number, amount: number) => {
  const budget = await getJobBudgetByJobId(jobId.toString());
  const value = parseNumber(amount?.toString());
  if (value) {
    const newValue = parseNumber(budget.budget_total?.toString()) - value;
    budget.budget_total = newValue >= 0 ? newValue : 0;
    await updateJobBudgetOnly(budget);
  }
};

export function useCreateOpportunity(
  opts: IMutateOption<IOpportunityNew>
): ICreateResult<{
  opportunity: IOpportunityNew;
  shouldCreateJob: boolean;
}> {
  const handler = async (opts: {
    opportunity: IOpportunityNew;
    shouldCreateJob: boolean;
  }) => {
    const payload = opts.opportunity;
    if (opts.shouldCreateJob) {
      const job = await createJobByOpportunity(opts.opportunity);
      payload.job_id = job.id;
    }
    const opportunity = await createOpportunity(payload);
    // adds opportunity's value to job's budget
    // if (payload.job_id) {
    //   await increaseJobBudget(payload.job_id, payload.value);
    // }
    return opportunity;
  };

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(handler, {
    onSuccess: (data: IOpportunityNew) => {
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getLists());
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getAll());
      queryClient.invalidateQueries(JOBS_QUERY_KEYS.getAll());
      if (data?.job_id) {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudget(data.job_id.toString())
        );
      }
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });
  return { create: mutate, isLoading, error };
}

export function useUpdateOpportunity(
  opts: IMutateOption<IOpportunityNew>
): IUpdateResult<{
  opportunity: IOpportunityNew;
  shouldCreateJob: boolean;
  previousOpportunity: IOpportunityNew;
}> {
  const handler = async (opts: {
    opportunity: IOpportunityNew;
    shouldCreateJob: boolean;
    previousOpportunity: IOpportunityNew;
  }) => {
    const payload = opts.opportunity;
    if (opts.shouldCreateJob) {
      const job = await createJobByOpportunity(opts.opportunity);
      payload.job_id = job.id;
    }
    const opportunity = await updateOpportunity(payload);
    // deducts opportunity's previous value from previous job budget
    // if (
    //   opts.previousOpportunity.job_id &&
    //   payload.job_id !== opts.previousOpportunity.job_id
    // ) {
    //   await decreaseJobBudget(opts.previousOpportunity.job_id, payload.value);
    // }
    // // adds opportunity's value to job's budget
    // if (payload.job_id) {
    //   await increaseJobBudget(payload.job_id, payload.value);
    // }
    return opportunity;
  };
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(handler, {
    onSuccess: (data: IOpportunityNew) => {
      queryClient.invalidateQueries(
        OPPORTUNITIES_QUERY_KEYS.getSingle(data.id!.toString())
      );
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getLists());
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getAll());
      queryClient.invalidateQueries(JOBS_QUERY_KEYS.getAll());
      if (data?.job_id) {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudget(data.job_id.toString())
        );
      }
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });
  return { update: mutate, isLoading, error };
}

export function useDeleteOpportunity(
  opts: IMutateOption<IOpportunityNew>
): IDeleteResult<{ id: string; opportunity: IOpportunityNew }> {
  const handler = async (opts: {
    id: string;
    opportunity: IOpportunityNew;
  }) => {
    const opportunity = await deleteOpportunity(opts.id);
    // deducts opportunity's previous value from previous job budget
    if (opts.opportunity.job_id) {
      await decreaseJobBudget(opts.opportunity.job_id, opts.opportunity.value);
    }
    return opportunity as IOpportunityNew;
  };
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(handler, {
    onSuccess: (data: IOpportunityNew) => {
      queryClient.removeQueries(
        OPPORTUNITIES_QUERY_KEYS.getSingle(data?.id!.toString())
      );
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getLists());
      queryClient.invalidateQueries(OPPORTUNITIES_QUERY_KEYS.getAll());
      if (data?.job_id) {
        queryClient.invalidateQueries(
          BUDGETS_QUERY_KEYS.getJobBudget(data.job_id.toString())
        );
      }
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}
