import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { MEMBERS_QUERY_KEYS } from '../constants/queries';
import { getMembers, getMember } from '../resources/getMembers';
import { IMember } from '../types/IMember';
import { IQueryResult } from '../types/IQueryResult';

export function useMembers(activeOnly:boolean = true): IQueryResult & {
  members: IMember[];
} {
  const {
    data: activeMembers,
    isLoading: activeMembersLoading,
    error: activeMembersError,
  } = useQuery(activeOnly ? MEMBERS_QUERY_KEYS.getActive() : MEMBERS_QUERY_KEYS.getAll(), () => getMembers(activeOnly), {});
  return { members: activeMembers, isLoading: activeMembersLoading, error: activeMembersError };
}

export function useMemberSingle(id: number | undefined): IQueryResult & {
  member: IMember;
} {
  const {
    data: member,
    isLoading,
    error,
  } = useQuery(
    MEMBERS_QUERY_KEYS.getSingle(id?.toString()),
    () => getMember(id!),
    {
      enabled: !!id,
    }
  );
  return { member, isLoading, error };
}

export function useMembersDropdownOptions(filter?: (m: IMember) => boolean) {
  const { members } = useMembers();
  const memberOptions = useMemo(() => {
    const mapper = (member: IMember) => ({
      key: member.id,
      text: member.name,
    });
    const result = filter
      ? (members ?? []).filter(filter).map(mapper)
      : (members ?? []).map(mapper);
    result.sort((a, b) => a.text.localeCompare(b.text));
    return result;
  }, [filter, members]);
  return memberOptions;
}


export function useSubordinateMembersDropdownOptions(includeMe: boolean = true,   filter?: (m: IMember) => boolean) {
  const { members } = useSubordinateMembers(includeMe);
  const memberOptions = useMemo(() => {
    const mapper = (member: IMember) => ({
      key: member.id,
      text: member.name,
    });
    const result = filter
      ? (members ?? []).filter(filter).map(mapper)
      : (members ?? []).map(mapper);
    result.sort((a, b) => a.text.localeCompare(b.text));
    return result;
  }, [filter, members]);
  return memberOptions;
}

type LeadMembersGroupItem = { [key: string]: IMember[] };

export function useSubordinateMembers(
  includeMe: boolean = true
): IQueryResult & {
  members: IMember[];
} {
  const { currentUser } = useCurrentUser();
  const { members: rawMembers, isLoading, error } = useMembers();
  const members = useMemo(() => {
    if (!currentUser) return [];
    const result = [] as IMember[];
    if (includeMe) {
      result.push(currentUser);
    }
    if (!rawMembers?.length) return result;

    const groupedByLeads = rawMembers.reduce<LeadMembersGroupItem>(
      (value, member) => {
        if (!member.leader_id) return value;
        (value[member.leader_id] = value[member.leader_id] || []).push(member);
        return value;
      },
      {}
    );
    const directSubordinates = groupedByLeads[currentUser.id]?.filter(
      (i) => i.id !== currentUser.id
    );
    if (directSubordinates?.length) {
      traverseSubordinates(directSubordinates, result, groupedByLeads);
    }
    return result?.filter((m) => m.active);
  }, [includeMe, currentUser, rawMembers]);

  return { members, isLoading, error };
}

function traverseSubordinates(
  subordinates: IMember[],
  result: IMember[],
  group: LeadMembersGroupItem
) {
  for (const child of subordinates) {
    result.push(child);
    if (group[child.id]?.length) {
      traverseSubordinates(group[child.id], result, group);
    }
  }
}
