/* eslint-disable eqeqeq */
import { useEffect, useContext } from 'react';
import moment from 'moment';
import { FontIcon } from '@fluentui/react';
import ReactDataGrid, { Row } from 'react-data-grid';
import { FluentThemeContext } from '../../providers/FluentThemeProvider';
import {
  DropdownEditor,
  TextEditor,
  NumberEditor,
  defaultFillHandler,
  defaultPasteHandler,
} from 'components/DataGrid';
import './CustomGrid.css';

const DateFormatter = (setRows: any) => {
  return (cellProps: any) => {
    const handleAdd = () => {
      const new_row = {
        date: cellProps.row.date,
        day: cellProps.row.day,
        dow: cellProps.row.dow,
        member_id: cellProps.row.member_id,
      };

      setRows((prev: any[]) => {
        const updatedRows = prev
          .slice(0, cellProps.rowIdx + 1)
          .concat(new_row)
          .concat(prev.slice(cellProps.rowIdx + 1));
        return updatedRows;
      });
    };

    const handleUndo = () => {
      const { forDeletion, ...newRow } = cellProps.row;
      setRows((prev: any[]) => {
        const updatedRows = prev
          .slice(0, cellProps.rowIdx)
          .concat({ ...newRow })
          .concat(prev.slice(cellProps.rowIdx + 1));
        return updatedRows;
      });
    };

    const handleDelete = () => {
      if (cellProps.row.id) {
        setRows((prev: any[]) => {
          const updatedRows = prev
            .slice(0, cellProps.rowIdx)
            .concat({ ...cellProps.row, forDeletion: true })
            .concat(prev.slice(cellProps.rowIdx + 1));
          return updatedRows;
        });
      } else {
        setRows((prev: any[]) => {
          const copy = prev.slice();
          let dateCount = 0;
          for (let i = 0; i < copy.length; i++) {
            if (copy[i]['day'] === cellProps.row['day'] && !cellProps.row.id) {
              dateCount++;
            }
          }

          if (dateCount > 1) {
            return copy
              .slice(0, cellProps.rowIdx)
              .concat(copy.slice(cellProps.rowIdx + 1));
          }

          const { date, day, dow } = cellProps.row;
          return copy
            .slice(0, cellProps.rowIdx)
            .concat({ date, day, dow })
            .concat(copy.slice(cellProps.rowIdx + 1));
        });
      }
    };

    const value = cellProps.row[cellProps.column.key];
    return (
      <div className="with-actions">
        {value}
        <div className="cell-actions">
          <FontIcon iconName="Add" onClick={handleAdd} />
          {cellProps.row.forDeletion ? (
            <FontIcon iconName="Undo" onClick={handleUndo} />
          ) : (
            <FontIcon iconName="Delete" onClick={handleDelete} />
          )}
        </div>
      </div>
    );
  };
};

const CustomRowFormatter = (props: any) => {
  const styles: any = {};
  if (props.row.forDeletion) {
    styles.opacity = 0.35;
  }
  if (props.row.dirty) {
    styles.fontStyle = 'italic';
    if (
      !props.row.job_role ||
      !props.row.hours ||
      !props.row.status ||
      isNaN(parseFloat(props.row.hours))
    ) {
      styles.color = 'var(--warning)';
    }
  }
  let className = 'grid-row';
  if (props.row.job_role_status == 1 && props.row.status) {
    if (props.row.status === 'Committed') {
      className += ' committed';
    } else if (props.row.status === 'Tentative') {
      className += ' tentative';
    }
  }

  if (props.row.job_role_status == 0) {
    styles.fontStyle = 'italic';
    styles.color = 'gray';
    return (
      <div className={className} style={styles}>
        <Row {...props} forceUpdate={true} editable={false} />
      </div>
    );
  }

  if (props.row.dow === 'Sun' || props.row.dow === 'Sat') {
    return (
      <div style={styles} className={`${className} weekend`}>
        <Row {...props} forceUpdate={true} />
      </div>
    );
  }

  return (
    <div className={className} style={styles}>
      <Row {...props} forceUpdate={true} />
    </div>
  );
};

const updateRows =
  (setRows: any) =>
  (updatedRows: any, { indexes }: any) => {
    const validateRow = (row: any) => {
      if (row.job_role) {
        if (!row.status) {
          row.status = 'Committed';
        }
        if (!row.hours || isNaN(parseFloat(row.hours))) {
          row.hours = 8;
        }
      }
      row.dirty = true;
    };

    for (let i = 0; i < updatedRows.length; i++) {
      for (let ii = 0; ii < indexes.length; ii++) {
        if (indexes[ii] !== i) continue;
        validateRow(updatedRows[i]);
      }
    }

    setRows(updatedRows.slice());
  };

export const TimesheetGrid = ({
  data,
  rows,
  setRows,
  cols,
  setCols,
  timesheetState,
}: any) => {
  const { themeString } = useContext(FluentThemeContext);

  useEffect(() => {
    const jobRoles = data.job_roles.map((jr: any) => {
      return {
        id: +jr.job_role_id,
        value: jr.name,
        status: +jr.status,
      };
    });

    const status = [
      { id: 1, name: 'Committed' },
      { id: 2, name: 'Tentative' },
    ];

    const statusOptions: any = status.map((s: any) => {
      return {
        id: +s.id,
        value: s.name,
      };
    });

    let jobRoleByIds = jobRoles.reduce(
      (acc: any, jobRole: any) => ({ ...acc, [+jobRole.id]: jobRole.value }),
      {}
    );
    let jobRoleStatusByIds = jobRoles.reduce(
      (acc: any, jobRole: any) => ({ ...acc, [+jobRole.id]: jobRole.status }),
      {}
    );
    const statusById = statusOptions.reduce(
      (acc: any, status: any) => ({ ...acc, [+status.id]: status.value }),
      {}
    );

    let gridRows = [];

    for (let i = 0; i < data.timesheets.length; i++) {
      let ts = data.timesheets[i];
      let m = moment(ts.date);

      gridRows.push({
        id: ts.id,
        date: m.format('ddd D MMM YYYY'),
        day: m.format('YYYYMMDD'),
        dow: m.format('ddd'),
        job_role: jobRoleByIds[ts.job_role_id],
        job_role_status: jobRoleStatusByIds[ts.job_role_id],
        status: statusById[ts.status_id],
        hours: +ts.hours,
        member_id: timesheetState.memberId,
      });
    }

    let startDate = moment(timesheetState.startDate, 'YYYY MMM').startOf(
      'month'
    );
    let endDate = moment(timesheetState.endDate, 'YYYY MMM').endOf('month');

    for (let m = startDate; m.isBefore(endDate); m.add(1, 'days')) {
      let found = false;
      for (let i = 0; i < gridRows.length; i++) {
        if (gridRows[i].day === m.format('YYYYMMDD')) {
          found = true;
          break;
        }
      }

      if (!found) {
        gridRows.push({
          date: m.format('ddd D MMM YYYY'),
          day: m.format('YYYYMMDD'),
          dow: m.format('ddd'),
          member_id: timesheetState.memberId,
        });
      }
    }

    gridRows = gridRows.sort((r1, r2) => {
      if (r1.day > r2.day) return 1;

      if (r1.day < r2.day) return -1;

      return 0;
    });
    setCols([
      {
        key: 'date',
        name: 'Date',
        resizable: true,
        width: 200,
        formatter: DateFormatter(setRows),
      },
      {
        key: 'job_role',
        name: 'Job',
        resizable: true,
        editor: (props: any) => (
          <DropdownEditor
            {...props}
            valueKey="value"
            textKey="value"
            options={jobRoles.filter((jr: any) => jr.status == 1)}
          />
        ),
      },
      {
        key: 'status',
        name: 'Status',
        resizable: true,
        width: 110,
        editor: (props: any) => (
          <DropdownEditor
            {...props}
            valueKey="value"
            textKey="value"
            options={statusOptions}
          />
        ),
      },
      {
        key: 'hours',
        name: 'Hours',
        resizable: true,
        width: 60,
        editor: (props: any) => <NumberEditor {...props} />,
      },
      {
        key: 'comments',
        name: 'Notes',
        resizable: true,
        editor: (props: any) => <TextEditor {...props} />,
      },
    ]);
    setRows(gridRows);
  }, [data, timesheetState, setRows, setCols]);

  return (
    <div className={`grid-container ${themeString}`}>
      <ReactDataGrid
        columns={cols}
        rows={rows}
        style={{ minHeight: 400 }}
        rowRenderer={CustomRowFormatter}
        onRowsChange={updateRows(setRows)}
        onFill={defaultFillHandler}
        onPaste={defaultPasteHandler}
        className="rdg-light"
      />
    </div>
  );
};
