import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IRoleMember } from '../types/IRoleMember';

export async function updateRoleMember(jobId: string, member: Partial<IRoleMember>) {
	const response = await fetch(`${apiEndpoint}/jobs/${jobId}/roles/${member.job_role_id}/members`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify({
			active: member.active,
			job_role_id: member.job_role_id,
			member_id: member.member_id
		})
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}