import React, { useEffect, useState } from "react";
import { Stack, StackItem } from "@fluentui/react";
import Skeleton from "react-loading-skeleton";
import { Container } from "./Container";
import "react-loading-skeleton/dist/skeleton.css";
import "./GeneralLoadingSkeleton.css";

const STACK_GAP = { childrenGap: "1em" };

type LoaderProps = {
  delay?: number;
};

const GeneralLoadingSkeleton: React.FC<LoaderProps> = (props: LoaderProps) => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, props.delay ?? 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [props.delay]);

  if (!ready) return null;

  return (
    <Container mt={64}>
      <Stack
        horizontal
        tokens={STACK_GAP}
        style={{ marginBottom: 40, justifyContent: "space-between" }}
      >
        <StackItem>
          <Skeleton height={40} width={300} />
        </StackItem>
        <StackItem className="da-general-loading-skeleton--hide-xs">
          <Stack horizontal tokens={STACK_GAP}>
            <StackItem>
              <Skeleton height={40} width={150} />
            </StackItem>
            <StackItem>
              <Skeleton height={40} width={150} />
            </StackItem>
          </Stack>
        </StackItem>
      </Stack>
      {Array.from(Array(15)).map((_, k) => (
        <Stack
          key={k}
          horizontal
          tokens={STACK_GAP}
          style={{ justifyContent: "space-between", marginBottom: 24 }}
        >
          {Array.from(Array(3)).map((_, i) => (
            <StackItem style={{ width: "100%", height: 30 }} key={i}>
              <Skeleton height={30} />
            </StackItem>
          ))}
        </Stack>
      ))}
      <Stack horizontal tokens={STACK_GAP} style={{ marginBottom: 40 }}>
        <StackItem>
          <Skeleton width={100} />
        </StackItem>
        <StackItem>
          <Skeleton width={100} />
        </StackItem>
        <StackItem className="da-general-loading-skeleton--hide-xs">
          <Skeleton width={100} />
        </StackItem>
        <StackItem className="da-general-loading-skeleton--hide-xs">
          <Skeleton width={100} />
        </StackItem>
      </Stack>
    </Container>
  );
};

export default GeneralLoadingSkeleton;
