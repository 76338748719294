import React from 'react';
import { IColumn, TextField } from '@fluentui/react';
import { IJobRole, JobRoleColumnTotals } from 'types/IJobRole';
import { toCurrency } from 'components/utils/NumberHelper';
import { DAAligmentType, IDAColumn } from 'types/IDADetailsList';

interface IHoursBudgetColumnProps {
  item?: any;
  index?: number;
  column?: IColumn;
  onChange: (item: IJobRole, newValue: any) => void;
  disabled?: boolean;
}

export const HOURS_PER_DAY = 8;

const HoursTotalBudgetColumn: React.FC<IHoursBudgetColumnProps> = ({
  item,
  onChange,
  disabled,
}) => {
  const errorMsg =
    item.hours_budget + '' !== '' &&
    item.hours_budget !== null &&
    isNaN(parseFloat(item.hours_budget + ''))
      ? 'Invalid Number'
      : '';
  const totalDays = (item.hours_budget / HOURS_PER_DAY).toString();

  return (
    <TextField
      value={totalDays}
      onChange={(e: any, val: any) => onChange(item, val)}
      errorMessage={errorMsg}
      disabled={disabled}
      type="number"
    />
  );
};

export const getJobRoleColumns = (
  totals: JobRoleColumnTotals,
  onHoursTotalChange: (item: IJobRole, newValue: any) => void,
  canViewMoneyValues: boolean,
  disableBudgetDays?: boolean
): IColumn[] => {
  const columns: IColumn[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Job Role',
      minWidth: 100,
      maxWidth: 300,
      flexGrow: 1,
      onRender: (item?: any, index?: number) => {
        if (index === -1) return '';
        const sellRate = toCurrency(
          Number.isNaN(item.sell_rate) ? 0 : item.sell_rate
        );
        return canViewMoneyValues ? `${item.name} (${sellRate})` : item.name;
      },
    },
    {
      key: 'hours_budget',
      fieldName: 'hours_budget',
      name: 'Budget (Days)',
      minWidth: 120,
      maxWidth: 120,
      onRender: (item?: any, index?: number) => {
        if (index === -1) return <>{totals.totalBudgetDays / HOURS_PER_DAY}</>;
        return (
          <HoursTotalBudgetColumn
            item={item}
            onChange={onHoursTotalChange}
            disabled={disableBudgetDays}
          />
        );
      },
    },
    {
      key: 'hours_booked',
      name: 'Booked (Days)',
      minWidth: 120,
      maxWidth: 120,
      onRender: (item?: any, index?: number) => {
        const value =
          (index === -1 ? totals.totalBookedDays : item.hours_booked) /
          HOURS_PER_DAY;
        return Number.isNaN(value) ? 0 : value;
      },
    },
    {
      key: 'variance_days',
      name: 'Variance (Days)',
      minWidth: 120,
      maxWidth: 120,
      onRender: (item?: any, index?: number) => {
        const value =
          (index === -1
            ? totals.totalVarianceDays
            : item.hours_budget - item.hours_booked) / HOURS_PER_DAY;
        return Number.isNaN(value) ? 0 : value;
      },
    },
  ];
  if (canViewMoneyValues) {
    columns.push(
      ...[
        {
          key: 'budget',
          name: 'Budget ($)',
          minWidth: 120,
          maxWidth: 120,
          onRender: (item?: any, index?: number) => {
            const value =
              index === -1
                ? totals.totalBudgetAmount
                : (item.hours_budget / HOURS_PER_DAY) * item.sell_rate;
            return toCurrency(Number.isNaN(value) ? 0 : value);
          },
        },
        {
          key: 'booked',
          name: 'Booked ($)',
          minWidth: 120,
          maxWidth: 120,
          onRender: (item?: any, index?: number) => {
            const value =
              index === -1
                ? totals.totalBookedAmount
                : (item.hours_booked / HOURS_PER_DAY) * item.sell_rate;
            return toCurrency(Number.isNaN(value) ? 0 : value);
          },
        },
        {
          key: 'budget_variance',
          name: 'Variance ($)',
          minWidth: 120,
          maxWidth: 120,
          onRender: (item?: any, index?: number) => {
            if (index === -1) {
              return toCurrency(
                Number.isNaN(totals.totalVarianceAmount)
                  ? 0
                  : totals.totalVarianceAmount
              );
            }
            const budgetTotal =
              (item.hours_budget / HOURS_PER_DAY) * item.sell_rate;
            const bookedTotal =
              (item.hours_booked / HOURS_PER_DAY) * item.sell_rate;
            const value = budgetTotal - bookedTotal;
            return toCurrency(Number.isNaN(value) ? 0 : value);
          },
        },
      ]
    );
  }
  return columns;
};

export const getJobRoleTimesheetColumns = (
  jobRoles: IJobRole[],
): IColumn[] => {
  const getJobRoleFieldTotal = (field: string, items?: IJobRole[]) => {
    return (items ?? []).reduce((sum, cur: any) => {
      const value = parseFloat(cur[field] + '');
      if (!isNaN(value)) {
        return sum + value;
      }
      return sum;
    }, 0);
  };

  const totalBudgetDays = getJobRoleFieldTotal('hours_budget', jobRoles);
  const totalBookedDays = getJobRoleFieldTotal('hours_booked', jobRoles);
  const totalVarianceDays = totalBudgetDays - totalBookedDays;

  const columns: IDAColumn[] = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Job Role',
      minWidth: 150,
      maxWidth: 250,
      flexGrow: 1,
      onRender: (item?: any, index?: number) => {
        if (index === -1) return '';
        return item.name;
      },
    },
    {
      key: 'hours_budget',
      fieldName: 'hours_budget',
      name: 'Budget (Days)',
      minWidth: 150,
      maxWidth: 200,
      columnAlign: DAAligmentType.CENTER,
      rowAlign: DAAligmentType.CENTER,
      onRender: (item?: any, index?: number) => {
        if (index === -1) return <>{totalBudgetDays / HOURS_PER_DAY}</>;
        const totalDays = (item.hours_budget / HOURS_PER_DAY).toString();
        return totalDays;
      },
    },
    {
      key: 'hours_booked',
      name: 'Booked (Days)',
      minWidth: 150,
      maxWidth: 200,
      columnAlign: DAAligmentType.CENTER,
      rowAlign: DAAligmentType.CENTER,
      onRender: (item?: any, index?: number) => {
        const value =
          (index === -1 ? totalBookedDays : item.hours_booked) /
          HOURS_PER_DAY;
        return Number.isNaN(value) ? 0 : value;
      },
    },
    {
      key: 'variance_days',
      name: 'Variance (Days)',
      minWidth: 150,
      maxWidth: 200,
      columnAlign: DAAligmentType.CENTER,
      rowAlign: DAAligmentType.CENTER,
      onRender: (item?: any, index?: number) => {
        const value =
          (index === -1
            ? totalVarianceDays
            : item.hours_budget - item.hours_booked) / HOURS_PER_DAY;
        return Number.isNaN(value) ? 0 : value;
      },
    },
  ];
  return columns;
};