/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import moment from "moment";
import { CLIENTS_QUERY_KEYS } from "../constants/queries";
import { apiEndpoint } from "../resources/apiEndpoint";
import { createClient } from "../resources/createClient";
import { getClient } from "../resources/getClient";
import { getClients } from "../resources/getClients";
import { updateClient } from "../resources/updateClient";
import { deleteClient } from "../resources/deleteClient";
import { IClient } from "../types/IClient";
import { IJob } from "../types/IJob";
import { IJobBudget } from "../types/IJobBudget";
import {
  IQueryResult,
  IMutateOption,
  IUpdateResult,
  ICreateResult,
  IDeleteResult,
} from "../types/IQueryResult";
import { useCrudItem } from "./useData";
import { useJobs } from "./useJobs";
import { getClientTimesheets } from "resources/getClientTimesheets";
import { useMembers } from "./useMembers";
import { ITimesheet } from "types/ITimesheet";

export function useClientJobBudgetItem(jobId: string, budgetId?: string) {
  return useCrudItem<IJobBudget>({
    url: {
      create: `${apiEndpoint}/jobs/${jobId}/budgets`,
      get: !budgetId
        ? undefined
        : `${apiEndpoint}/jobs/${jobId}/budgets/${budgetId}`,
      update: !budgetId
        ? undefined
        : `${apiEndpoint}/jobs/${jobId}/budgets/${budgetId}`,
    },
  });
}

export function useClientSingle(id: string): IQueryResult & {
  client: IClient;
} {
  const {
    data: client,
    isLoading,
    error,
  } = useQuery(CLIENTS_QUERY_KEYS.getSingle(id), () => getClient(id), {
    enabled: !!id,
  });
  return { client, isLoading, error };
}

export function useUpdateClient(
  opts: IMutateOption<IClient>
): IUpdateResult<IClient> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(updateClient, {
    onSuccess: (data: IClient) => {
      queryClient.invalidateQueries(
        CLIENTS_QUERY_KEYS.getSingle(data.id.toString())
      );
      queryClient.invalidateQueries(CLIENTS_QUERY_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { update: mutate, isLoading, error };
}

export function useDeleteClient(
  opts: IMutateOption<IClient>
): IDeleteResult<string> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(deleteClient, {
    onSuccess: (data: IClient) => {
      queryClient.removeQueries(
        CLIENTS_QUERY_KEYS.getSingle(data?.id?.toString())
      );
      queryClient.invalidateQueries(CLIENTS_QUERY_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}

export function useCreateClient(
  opts: IMutateOption<IClient>
): ICreateResult<IClient> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(createClient, {
    onSuccess: (data: IClient) => {
      queryClient.invalidateQueries(CLIENTS_QUERY_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });
  return { create: mutate, isLoading, error };
}

export function useClients(): IQueryResult & {
  clients: IClient[] | undefined;
} {
  const [clients, setClients] = useState<IClient[]>([]);
  const { data, isLoading, error } = useQuery<IClient[], Error>(
    CLIENTS_QUERY_KEYS.getAll(),
    () => getClients()
  );
  useEffect(() => {
    setClients(
      (data ?? [])
        .slice()
        .sort((a, b) => b.active_jobs_count - a.active_jobs_count)
    );
  }, [data]);
  return {
    clients,
    isLoading,
    error,
  };
}

export function useClientJobs(cliendId: string): IQueryResult & {
  clientJobs: IJob[] | undefined;
} {
  const { jobs, isLoading, error } = useJobs();
  const clientJobs = useMemo(() => {
    const result = jobs?.filter((job: IJob) => +job.client_id === +cliendId);
    return result ?? [];
  }, [jobs]);

  return {
    clientJobs,
    isLoading,
    error,
  };
}

export function useClientsDropdownOptions() {
  const { clients } = useClients();
  const clientOptions = useMemo(
    () =>
      (clients ?? []).map((client) => ({ key: client.id, text: client.name })),
    [clients]
  );
  return clientOptions;
}

export function useClientTimesheets(startDate: string, endDate: string, jobId: number): IQueryResult & {
  timesheets: ITimesheet[];
} {
  const {
    data,
    isLoading,
    error,
  } = useQuery(CLIENTS_QUERY_KEYS.getClientTimesheets(startDate, endDate, jobId), () => 
    getClientTimesheets(startDate, endDate, jobId), {
    enabled: !!jobId,
  });
  const { members } = useMembers();

  const timesheets = useMemo(() => {
    if (data?.length) {
      return data.map((timesheet: ITimesheet) => (
        {
          ...timesheet,
          member_name: members.find(members => members.id === timesheet.member_id)?.name ?? 'Unknown',
        }
      ));
    }
    return null;
  }, [data]);

  return { timesheets, isLoading, error };
}

export function getTimesheetDateOptions(): any {
  const current = moment();
  const currentYear = current.year();
  const currentMonth = current.month();
  const dates: any = [];
  for (let i = currentYear; i >= 2019; i--) {
    const startingMonth = currentMonth + 1;
    for (let j = startingMonth; j > 0; j--) {
      const formatted = moment(i + "-" + j, "YYYY-M").format("MMM YY");
      dates.push({
        'key': formatted,
        'fieldName': formatted,
        'name': formatted,
        'min-width': 20,
        'styles': {
            "cellTitle": {
                "padding": 0
            }
        }
      });
    }
  }
  return dates.sort();
}

export function getTimesheetTabDateOptions(timesheets: ITimesheet[]): any {
  if (!timesheets?.length) return [];

  const startYear = Number(moment(timesheets[0]?.date, "YYYY-MM-DD").format("YYYY"));
  const startMonth = Number(moment(timesheets[0]?.date, "YYYY-MM-DD").format("M"));

  const endYear = Number(moment(timesheets[timesheets.length - 1]?.date, "YYYY-MM-DD").format("YYYY"));
  const endMonth = Number(moment(timesheets[timesheets.length - 1]?.date, "YYYY-MM-DD").format("M"));

  const dates: any = [];

  for (let i = endYear; i >= startYear; i--) {
    for (let j = endMonth; j >= startMonth; j--) {
      const formatted = moment(i + "-" + j, "YYYY-M").format("MMM YY");
      dates.push({
        'key': formatted,
        'fieldName': formatted,
        'name': formatted,
        'min-width': 20,
        'styles': {
            "cellTitle": {
                "padding": 0
            }
        }
      });
    }
  }
  return dates.sort();
}
