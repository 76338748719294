import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  CommandButton,
  SelectionMode,
  ComboBox,
  Stack,
  StackItem,
  IDropdownOption,
  TextField,
  MessageBar,
  MessageBarType,
  VerticalDivider,
} from '@fluentui/react';

import { Page } from '../Page';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import { Paper } from '../Paper';
import { useBreadcrumbs } from '../../providers/BreadcrumbsProvider';
import { useInvoiceSingle, useUpdateInvoiceJobId } from 'hooks/useInvoices';
import { invoiceLinesPageFields } from '../../enums/invoiceLinesPageFields';

import DetailsListWithContextMenu from '../DetailsListWithContextMenu';
import { useJobsSummary } from 'hooks/useJobs';
import { IJob } from 'types/IJob';
import { useToast } from 'providers/ToastContextProvider';
import { useClients } from 'hooks/useClients';

export const InvoicePage: React.FC = () => {
  const { jobInvoiceId } = useParams<any>();
  const history = useHistory();
  const { setBreadcrumbs } = useBreadcrumbs();
  const { setToastSuccessMessage } = useToast();
  const { invoice } = useInvoiceSingle(jobInvoiceId);

  const {
    update,
    isLoading,
  } = useUpdateInvoiceJobId({
    onSuccess: (r) => {
      history.push(`/invoices`);
      setToastSuccessMessage('Invoice Job ID has been updated.')
    },
    onError: () =>
      setGeneralError("An error occurred while trying to update the job milestone."),
  });

  const { jobs = [] } = useJobsSummary();
  const { clients = [] } = useClients();
  const [jobId, setJobId] = useState(invoice?.job_id);
  const [generalError, setGeneralError] = useState("");

  useEffect(() => {
    setBreadcrumbs([
      {
        key: 'invoices',
        text: 'Invoices',
        onClick: () => history.push('/invoices'),
      },
      { key: 'invoice', text: invoice.invoice_number! },
    ]);
  }, [history, invoice.invoice_number]);

  const handleSubmit = () => {
    if (!jobId) {
      setGeneralError('Job ID is required.');
      return;
    };
    update({ jobInvoiceId, jobId });
  }

  const getJobOptions = () : IDropdownOption[] => {
    if (!clients.length) return [];
    const options = jobs.map((job: IJob) => {
      const { name: clientName } = clients.find(client => client.id === job.client_id) || {};
      return {
        key: job.id,
        text: clientName ? `${clientName} - ${job.name}` : job.name,
      }
    })
    return options.sort((a, b) => a.text.localeCompare(b.text));
  };

  return (
    <Page>
      <CommandBar>
        <Spacer />
        <CommandButton
          iconProps={{ iconName: 'Save' }}
          disabled={!jobId}
          onClick={handleSubmit}
          text="Save Changes"
        />
      </CommandBar>
      <Container>
        <header>
          <h2>{invoice.invoice_number}</h2>
        </header>
        <div>
          {generalError && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
            >
            {generalError} 
          </MessageBar>
          )}
          <br/>
          <div className="da-details-list-container">
            <div className="da-details-list-header">
              <h2 className="da-details-list-title">Invoice</h2>
            </div>
            <div style={{ padding: 15 }}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <TextField
                    label='Date'
                    value={invoice.date}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='ID'
                    value={invoice.id.toString()}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='Inv #'
                    value={invoice.invoice_number}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='Contact'
                    value={invoice.contact_name.toString()}
                    disabled
                  />
                </Stack.Item>
              </Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <TextField
                    label='Reference'
                    value={invoice.reference}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='Status'
                    value={invoice.status}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='Total'
                    value={invoice.amount_total.toString()}
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <TextField
                    label='Due'
                    value={invoice.amount_due.toString()}
                    disabled
                  />
                </Stack.Item>
              </Stack>
              <hr style={{ marginTop: 20 }} />
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item grow>
                  <ComboBox
                    label='Job'
                    allowFreeform={true}
                    autoComplete={'on'}
                    options={getJobOptions()}
                    selectedKey={jobId}
                    onChange={(_: any, option: any) =>
                      option?.key && setJobId(option.key)
                    }
                    disabled={isLoading}
                  />
                </Stack.Item>
              </Stack>
            </div>
          </div>
          <br />
          <br />
          {invoice.lines && (
            <div className="da-details-list-container">
              <div className="da-details-list-header">
                <h2 className="da-details-list-title">Lines</h2>
              </div>
              <DetailsListWithContextMenu
                items={invoice.lines}
                columns={invoiceLinesPageFields}
                selectionMode={SelectionMode.none}
                // contextMenu={menu}
                filterableColumns={[]}
              />
            </div>
          )}
        </div>
      </Container>
    </Page>
  );
};

export default InvoicePage;
