import React, { useEffect, useMemo, useState } from "react";
import {
  DatePicker,
  Stack,
  StackItem,
  Dropdown,
  Toggle,
} from "@fluentui/react";
import moment from "moment";
import { useJobs } from "hooks/useJobs";
import { IMember } from "../types/IMember";
import { BookingsTimelineFilter } from "../hooks/useBookingTimelines";
import { Paper } from "./Paper";
import "./BookingsTimelineFilters.css";

const BookingsTimelineFilters: React.FC<{
  members: IMember[];
  open?: boolean | undefined;
  onClose?: () => void;
  filter?: BookingsTimelineFilter;
  setFilter?: (f: BookingsTimelineFilter) => void;
}> = (props: {
  members: IMember[];
  open?: boolean | undefined;
  onClose?: () => void;
  filter?: BookingsTimelineFilter;
  setFilter?: (f: BookingsTimelineFilter) => void;
}) => {
  const { jobs } = useJobs();

  const [uncommittedFilters, setUncommittedFilters] = useState<
    BookingsTimelineFilter | undefined
  >(props?.filter);

  const sortArray = (arr: any) => (
    arr?.sort(
      (
        a: { key: number; text: string },
        b: { key: number; text: string }
      ) => {
        return a.text > b.text ? 1 : -1;
      }
    ) ?? []
  );

  const roles = useMemo(() => {
    const mappedRoles = [...Array.from(new Set(props.members.map((m) => m.role)))].map(
      (s) => ({
        key: s as string,
        text: s as string,
      })
    );
    return sortArray(mappedRoles)
  }, [props.members]);

  const activeJobs = useMemo(() => {
    const mappedActiveJobs = [...jobs]
      .filter(({ status }) => status === 1)
      .map(({ clientName, id, name }) => (
        {
          key: id,
          text: `${clientName}: ${name}` as string,
        }
      ));
    return sortArray(mappedActiveJobs)
  }, [jobs]);

  const capabilities = useMemo(() => {
    const mappedCapabilities = [...Array.from(new Set(props.members.map((m) => m.capability)))].map(
      (s) => ({
        key: s as string,
        text: s as string,
      })
    );
    return sortArray(mappedCapabilities)
  }, [props.members]);

  useEffect(() => {
    const updateFilter = (f: Partial<BookingsTimelineFilter>) => {
      if (props?.setFilter) {
        props?.setFilter(f as BookingsTimelineFilter);
      }
    };
    
    updateFilter(uncommittedFilters as BookingsTimelineFilter);
  }, [props, uncommittedFilters]);

  const handleListSelectedChanged = (
    items: string[] | undefined,
    item: any,
    field?: string | undefined
  ) => {
    if (!field) return;
    let result: string[] = [];
    if (items) result.push(...items);
    const index = result.indexOf(item.key);
    if (index !== -1) result.splice(index, 1);
    if (item.selected) result.push(item.key);
    setUncommittedFilters(
      (currVal) =>
        ({
          ...currVal,
          [field]: result,
        } as BookingsTimelineFilter)
    );
  };

  const handleFilterChanged = (
    field?: string | undefined,
    value?: any | undefined
  ) => {
    if (!field) return;
    setUncommittedFilters(
      (currVal) =>
        ({
          ...currVal,
          [field]: value,
        } as BookingsTimelineFilter)
    );
  };
  const onFormatDate = (date?: Date): string => {
    if (!date) return '';
  
    const d1 = new Date((date));

    const year = d1.getFullYear().toString().substr(-2);;
    const month = (d1.getMonth() + 1).toString().substr(-2)
    const day = d1.getDate().toString().substr(-2);

    return `${day}-${month}-${year}`;
  };

  return (
    <Paper>
      <Stack
        wrap
        horizontal
        tokens={{ childrenGap: "10px" }}
        style={{ marginBottom: 16 }}
      >
        <StackItem>
          <DatePicker
            label="Start date"
            placeholder="Select a date..."
            ariaLabel="Select a date"
            formatDate={onFormatDate}
            style={{ width: 100 }}
            value={uncommittedFilters?.startDate}
            onSelectDate={(d) => handleFilterChanged("startDate", d)} />
        </StackItem>
        <StackItem>
          <DatePicker
            label="End date"
            placeholder="Select a date..."
            ariaLabel="Select a date"
            formatDate={onFormatDate}
            style={{ width: 100 }}
            value={uncommittedFilters?.endDate}
            onSelectDate={(d) => handleFilterChanged("endDate", d)} />
        </StackItem>
        <StackItem>
          <Dropdown
            label="Role"
            options={roles}
            multiSelect
            selectedKeys={uncommittedFilters?.roles}
            style={{ width: 150 }}
            onChange={(_: any, item: any) => handleListSelectedChanged(
              uncommittedFilters?.roles,
              item,
              "roles"
            )} />
        </StackItem>
        <StackItem>
          <Dropdown
            label="Business Unit"
            options={capabilities}
            multiSelect
            selectedKeys={uncommittedFilters?.capabilities}
            style={{ width: 150 }}
            onChange={(_: any, item: any) => handleListSelectedChanged(
              uncommittedFilters?.capabilities,
              item,
              "capabilities"
            )} />
        </StackItem>
        <StackItem>
          <Dropdown
            label="Jobs"
            options={activeJobs}
            multiSelect
            selectedKeys={uncommittedFilters?.jobs}
            style={{ width: 150 }}
            onChange={(_: any, item: any) => handleListSelectedChanged(
              uncommittedFilters?.jobs,
              item,
              "jobs"
            )} />
        </StackItem>
        <StackItem 
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 12,
          }}
        >
          <Toggle
            label="Capacity"
            checked={uncommittedFilters?.hasCapacity}
            onChange={(_, b) => handleFilterChanged("hasCapacity", b)}
          />
        </StackItem>
        <StackItem 
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 12,
          }}
        >
          <Toggle
            label="Weekly"
            checked={uncommittedFilters?.isWeekly}
            onChange={(_, isWeekly) => {
              const weekCount = isWeekly ? 12 : 6;
              const endDate = moment(moment().startOf("isoWeek")).add(weekCount, "weeks").toDate();
              setUncommittedFilters(
                (currVal) =>
                  ({
                    ...currVal,
                    endDate,
                    isWeekly,
                  } as BookingsTimelineFilter)
              );
            }}
          />
        </StackItem>
      </Stack>
    </Paper>
  );
};

export default BookingsTimelineFilters;
