import React, { useReducer, useEffect } from 'react';
import { ILocalStorageContext } from '../types/ILocalStorageContext';

export const LocalStorageContext =
  React.createContext<ILocalStorageContext | null>(null);

const KEYS_DICTIONARY = 'keys_collection';
const TIMESTAMP = 'local_store_timestamp';
const keys = JSON.parse(window.localStorage.getItem(KEYS_DICTIONARY) ?? '[]');

const loadStoredValues = (keys: string[]) => {
  return keys.reduce((memVal: any, key: string) => {
    let value;
    const storedValue = window.localStorage.getItem(key);
    try {
      value = JSON.parse(storedValue ?? 'null');
    } catch (_) {
      value = storedValue;
    }
    memVal[key] = value;
    return memVal;
  }, {});
};

const initialValue = loadStoredValues(keys);

const reducer = (state: any, action: any) => {
  const now = Date.now();

  switch (action.type) {
    case 'set': {
      const value = JSON.stringify(action.value ?? null);
      window.localStorage.setItem(action.key, value);
      window.localStorage.setItem(TIMESTAMP, now.toString());

      if (!keys.includes(action.key)) {
        keys.push(action.key);
        window.localStorage.setItem(KEYS_DICTIONARY, JSON.stringify(keys));
      }

      return {
        ...state,
        timestamp: now,
        [action.key]: action.value,
      };
    }
    case 'checksync': {
      const lastGlobalUpdate = parseInt(
        window.localStorage.getItem(TIMESTAMP) ?? '0',
        10
      );
      const lastLocalUpdate = state.timestamp ?? 0;

      if (lastGlobalUpdate > lastLocalUpdate) {
        const keys = JSON.parse(
          window.localStorage.getItem(KEYS_DICTIONARY) ?? '[]'
        );
        const recentState = loadStoredValues(keys);
        return {
          ...recentState,
          timestamp: lastGlobalUpdate,
        };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

export const LocalStorageProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  useEffect(() => {
    const onFocus = () => {
      if (dispatch) {
        dispatch({ type: 'checksync' });
      }
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [dispatch]);

  const getValue = (callback: (state: any) => any) => {
    return callback(state);
  };
  return (
    <LocalStorageContext.Provider value={{ getValue, dispatch }}>
      {children}
    </LocalStorageContext.Provider>
  );
};
