import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Nav,
  INavLinkGroup,
  INavLink,
  Pivot,
  PivotItem,
} from '@fluentui/react';
import AppRoutes from './AppRoutes';
import { NAVIGATION_LINKS } from '../../constants/nav';
import { getNavigationLinks } from '../utils/NavigationHelper';
import { useCurrentUser } from '../../providers/CurrentUserProvider';

import {
  financialPermissions,
  manageCoursesPermission,
  manageSkillsPermission,
} from '../../enums/permissions';

const getUrlByTabKey = (key: string): string => {
  switch (key) {
    case 'clients':
      return NAVIGATION_LINKS.CLIENTS.url;
    case 'timesheet':
      return NAVIGATION_LINKS.TIMESHEET.url;
    case 'bookingstimeline':
      return NAVIGATION_LINKS.BOOKINGS_TIMELINE.url;
    case 'opportunities':
      return NAVIGATION_LINKS.OPPORTUNITIES.url;
    default:
      return NAVIGATION_LINKS.HOME.url;
  }
};

const TeamsContainer: React.FC = () => {
  const history = useHistory();
  const { currentUser, canViewOpportunity, canViewTimesheet } =
    useCurrentUser();
  const canViewFinancialAll = currentUser?.access_rules?.some(
    (r) => r.role === financialPermissions.editAll
  );
  const canManageSkills = currentUser?.access_rules?.some(
    (r) => r.role === manageSkillsPermission
  );
  const canManageCourses = currentUser?.access_rules?.some(
    (r) => r.role === manageCoursesPermission
  );

  const [, setSelectedTab] = useState('clients');
  const [navGroups, setNavGroups] = useState<INavLinkGroup[]>([
    {
      links: [],
    },
  ]);
  const [selectedNav, setSelectedNav] = useState<string | undefined>('');

  const onNavigate = useCallback(
    (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      e?.preventDefault();
      if (item) {
        history.push(item.url);
        setSelectedNav(item.key);
      }
    },
    [history]
  );

  const handleLinkClick = (item?: PivotItem) => {
    if (item) {
      setSelectedTab(item.props.itemKey!);
      history.push(getUrlByTabKey(item.props.itemKey!));
    }
  };

  useEffect(() => {
    const links = getNavigationLinks({
      canViewTimesheet,
      canViewOpportunity,
      canViewFinancialAll,
      canManageSkills,
      canManageCourses,
    });
    setNavGroups([{ links }]);
  }, [
    canViewFinancialAll,
    canViewOpportunity,
    canViewTimesheet,
    canManageSkills,
    canManageCourses,
  ]);

  return (
    <div className="da-main da-teams">
      <Pivot
        className="da-teams--nav"
        linkFormat="links"
        overflowBehavior="menu"
        onLinkClick={handleLinkClick}
        headersOnly={true}
      >
        <PivotItem
          headerText={NAVIGATION_LINKS.CLIENTS.label}
          itemKey={NAVIGATION_LINKS.CLIENTS.key}
        />
        <PivotItem
          headerText={NAVIGATION_LINKS.TIMESHEET.label}
          itemKey={NAVIGATION_LINKS.TIMESHEET.key}
        />
        <PivotItem
          headerText={NAVIGATION_LINKS.BOOKINGS_TIMELINE.label}
          itemKey={NAVIGATION_LINKS.BOOKINGS_TIMELINE.key}
        />
        {canViewOpportunity && (
          <PivotItem
            headerText={NAVIGATION_LINKS.OPPORTUNITIES.label}
            itemKey={NAVIGATION_LINKS.OPPORTUNITIES.key}
          />
        )}
      </Pivot>
      <main>
        <div className="da-sidebar">
          <Nav
            selectedKey={selectedNav}
            groups={navGroups}
            onLinkClick={onNavigate}
          />
        </div>
        <AppRoutes />
      </main>
    </div>
  );
};

export default TeamsContainer;
