export const jobStatus: { [key: number]: string } = {
  1: 'Active',
  2: 'Not Started',
  3: 'Finished',
  4: 'Abandoned',
};

export enum JobStatus {
  ACTIVE = 1,
  NOT_STARTED = 2,
  FINISHED = 3,
  ABANDONED = 4,
}

export const jobStatusTypesIndexLookup = 
	Object
		.entries(JobStatus)
		.reduce((acc: {[key: string]: number}, [key,value]) => {
			acc[value] = +key;
			return acc;
		}, {});

export const jobStatusTypesDropdownOptions = 
	Object
		.entries(JobStatus)
		.map(([key, value]) => ({ key: key, text: value }));