import { IColumn } from '@fluentui/react';

export const jobStatusesFields: IColumn[] = [
	{
		key: 'overall_rag',
		fieldName: 'overall_rag',
		name: 'Overall RAG',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'scope_rag',
		fieldName: 'scope_rag',
		name: 'Scope RAG',
		minWidth: 100,
		maxWidth: 100
	},
	{
		key: 'schedule_rag',
		fieldName: 'schedule_rag',
		name: 'Schedule RAG',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'budget_rag',
		fieldName: 'budget_rag',
		name: 'Budget RAG',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'comments',
		fieldName: 'comments',
		name: 'Comments',
		minWidth: 100,
		maxWidth: 180
	},
	{
		key: 'status_date',
		fieldName: 'status_date',
		name: 'Status Date',
		minWidth: 100,
		maxWidth: 150
	},
];