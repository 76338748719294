/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Callout,
  CommandButton,
  MessageBar,
  MessageBarType,
} from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Page } from '../Page';
import { nanoid } from 'nanoid';
import { CommandBar } from '../CommandBar';
import { Spacer } from '../Spacer';
import { Container } from '../Container';
import DataGrid, {
  Column,
  FillEvent,
  HeaderRendererProps,
  Row,
  RowRendererProps,
} from 'react-data-grid';
import './TimesheetPage.css';
import { useCourses } from 'hooks/useCourses';
import { useSubordinateMembers } from 'hooks/useMembers';
import {
  useCourseStatuses,
  useSaveCourseStatuses,
} from 'hooks/useCourseStatuses';
import withAccess from 'hocs/withAccess';
import { IMember } from 'types/IMember';
import {
  CellExpanderFormatter,
  TreeViewRow,
  toggleSubRow,
  NumberEditor,
} from 'components/DataGrid';
import { getPersonNameInitials } from 'components/utils/MiscHelper';
import { useToast } from 'providers/ToastContextProvider';
import { ICourseStatus, ISaveCourseStatus } from 'types/ICourseStatus';
import { ICourse } from 'types/ICourse';
import { manageSkillsPermission } from 'enums/permissions';

import './CoursePrioritiesPage.css';

type CoursePriorityRow = TreeViewRow & {
  name: string;
  level: number;
  courseId?: number;
  isEditable?: boolean;
  [key: string]: any;
};

type DirtyState = {
  memberId: number;
  priority: number;
  courseId: number;
};

const PRIORITY_KEY_PREFIX = 'memberPriority';

const getMemberIdFromPriorityKey = (key: string): number =>
  parseInt(key.replace(PRIORITY_KEY_PREFIX, ''));

const formatMemberPriorityKey = (member: IMember): string =>
  `${PRIORITY_KEY_PREFIX}${member.id}`;

function distinct(arr: string[]) {
  return Array.from(new Set(arr)).filter((a) => Boolean(a?.trim()));
}

function buildRows(
  courses: ICourse[],
  members: IMember[],
  statuses: ICourseStatus[]
): CoursePriorityRow[] {
  const level1 = distinct(courses.map((s) => s.level1));
  return level1.map((lv1) => {
    const lv1Id = nanoid();
    const colLevel1 = {
      id: lv1Id,
      name: lv1,
      isExpanded: false,
      level: 0,
    } as CoursePriorityRow;
    const level2 = distinct(
      courses.filter((s) => s.level1 === lv1).map((s) => s.level2)
    );

    colLevel1.children = level2.map((lv2) => {
      const lv2Id = nanoid();
      const colLevel2 = {
        parentId: colLevel1.id,
        id: lv2Id,
        name: lv2,
        isExpanded: false,
        level: 1,
      } as CoursePriorityRow;
      const filtedCourses = courses.filter(
        (s) => s.level1 === lv1 && s.level2 === lv2
      );
      colLevel2.children = filtedCourses.map((course) => {
        const courseRowId = nanoid();
        const colCourse = {
          parentId: colLevel2.id,
          id: courseRowId,
          name: course.course,
          courseId: course.id,
          isExpanded: false,
          isEditable: true,
          level: 2,
        } as CoursePriorityRow;

        const membersRows = members.reduce((value, member) => {
          const status = statuses.find(
            (r) => r.course_id === course.id && r.member_id === member.id
          );
          value[formatMemberPriorityKey(member)] = status?.priority ?? 0;
          return value;
        }, {} as CoursePriorityRow);

        return { ...colCourse, ...membersRows };
      });

      return colLevel2;
    });

    return colLevel1;
  });
}

const CustomRowFormatter = (
  isDirty: boolean,
  rendererProps: RowRendererProps<CoursePriorityRow>
) => {
  const classNames = [];
  if (isDirty) {
    classNames.push('row-dirty');
  }
  return (
    <div className={classNames?.join(' ')}>
      <Row {...rendererProps} />
    </div>
  );
};

export const CoursePrioritiesPage: React.FC = () => {
  const { setToastSuccessMessage } = useToast();
  const [rows, setRows] = useState<CoursePriorityRow[]>([]);
  const [generalError, setGeneralError] = useState('');
  const { courseStatuses } = useCourseStatuses();
  const { courses } = useCourses();
  const { members } = useSubordinateMembers();
  const [modifiedItems, setModifiedItems] = useState<DirtyState[]>([]);
  const { saveCourseStatuses, isLoading: isSaving } = useSaveCourseStatuses({
    onSuccess: () => {
      setModifiedItems([]);
      setToastSuccessMessage('Course priorities have been updated.');
    },
    onError: () =>
      setGeneralError(
        'An error occurred while trying to update course priorities.'
      ),
  });
  const isDirty = useMemo(
    () => Boolean(modifiedItems?.length),
    [modifiedItems?.length]
  );

  useEffect(() => {
    setRows(buildRows(courses ?? [], members ?? [], courseStatuses ?? []));
  }, [courses, members, courseStatuses]);

  const handleRowsChange = (
    currentRows: CoursePriorityRow[],
    { indexes, column }: { indexes: number[]; column: any }
  ) => {
    for (let i = 0; i < indexes.length; i++) {
      const index = indexes[i];
      const row = currentRows[index];
      if (row) {
        const memberId = getMemberIdFromPriorityKey(column.key);
        setModifiedItems((prev) => {
          let newValue = [...prev];
          const modifiedItem = newValue.find(
            (i) => i.memberId === memberId && i.courseId === row.courseId
          );
          const newPriority = parseInt(row[column.key] as string);
          if (modifiedItem) {
            modifiedItem.priority = newPriority;
          } else {
            newValue.push({
              memberId,
              courseId: row.courseId!,
              priority: newPriority,
            });
          }
          return newValue;
        });
      }
    }
    setRows(currentRows);
  };

  const handleSave = () => {
    if (!isDirty) return;
    const payload = modifiedItems.map((item) => {
      const courseStatus = courseStatuses.find(
        (sr) =>
          sr.course_id.toString() === item.courseId.toString() &&
          sr.member_id.toString() === item.memberId.toString()
      );
      return {
        course_status_id: courseStatus?.id,
        course_id: item.courseId,
        member_id: item.memberId,
        priority: item.priority,
      } as ISaveCourseStatus;
    });
    saveCourseStatuses(payload);
  };

  const handleOnFill = (event: FillEvent<CoursePriorityRow>) => {
    // handles on drag-fill to exclude cells that are not supposed to have values
    const value = event.sourceRow[event.columnKey];
    return event.targetRows.map((tr) => ({
      ...tr,
      ...(tr.isEditable ? { [event.columnKey]: value } : {}),
    }));
  };

  const columnsTree: Column<CoursePriorityRow>[] = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        width: 400,
        resizable: true,
        frozen: true,
        formatter: ({ row, isCellSelected }) => {
          const hasChildren = Boolean(row.children?.length!);
          const style = {
            paddingLeft: `${(row.level + 1) * 15}px`,
          };
          return (
            <>
              {hasChildren && (
                <CellExpanderFormatter
                  isCellSelected={isCellSelected}
                  expanded={row.isExpanded === true}
                  onCellExpand={() => {
                    const res = toggleSubRow(rows as TreeViewRow[], row.id);
                    setRows(res as CoursePriorityRow[]);
                  }}
                  onCellExpandAll={() => {
                    const res = toggleSubRow(
                      rows as TreeViewRow[],
                      row.id,
                      true
                    );
                    setRows(res as CoursePriorityRow[]);
                  }}
                />
              )}
              <div className="rdg-cell-value">
                <div style={style}>{row.name}</div>
              </div>
            </>
          );
        },
      },
      ...members.map((m) => ({
        key: formatMemberPriorityKey(m),
        name: getPersonNameInitials(m.name)!,
        headerRenderer: (
          props: HeaderRendererProps<CoursePriorityRow, any>
        ) => {
          const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
            useBoolean(false);
          const elementId = useId(`col-member-${m.id}`);

          const handleMouseEnter = () => {
            if (!isCalloutVisible) {
              toggleIsCalloutVisible();
            }
          };
          const handleMouseLeave = () => {
            if (isCalloutVisible) {
              toggleIsCalloutVisible();
            }
          };

          return (
            <div>
              <span
                id={elementId}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="member-column"
              >
                {props.column.name}
              </span>
              {isCalloutVisible && (
                <Callout
                  onDismiss={toggleIsCalloutVisible}
                  target={`#${elementId}`}
                  setInitialFocus
                  style={{ padding: 10 }}
                >
                  {m.name}
                </Callout>
              )}
            </div>
          );
        },
        resizable: true,
        editor: (props: any) => {
          const row = props.row as CoursePriorityRow;
          if (!row?.isEditable) return null;
          return <NumberEditor {...props} spinButtonProps={{ min: 0 }} />;
        },
      })),
    ];
  }, [rows, setRows, members]);

  const handleRowRenderer = (
    rendererProps: RowRendererProps<CoursePriorityRow>
  ) => {
    let dirty = false;
    const row = rendererProps.row;
    if (row.isEditable && row.courseId) {
      dirty = modifiedItems.some((i) => i.courseId === row.courseId);
    }
    return CustomRowFormatter(dirty, rendererProps);
  };

  return (
    <Page>
      <CommandBar noBreadcrumbs>
        <Spacer />
        <CommandButton
          iconProps={{ iconName: 'Save' }}
          text="Save Changes"
          onClick={handleSave}
          disabled={!isDirty || isSaving}
        />
      </CommandBar>
      <Container>
        <header>
          <h2>Course Priorities</h2>
        </header>
        {generalError && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false}>
            {generalError}
          </MessageBar>
        )}
        <div className="course-priorities-grid">
          <DataGrid
            columns={columnsTree}
            rows={rows}
            onRowsChange={handleRowsChange}
            onFill={handleOnFill}
            rowRenderer={handleRowRenderer}
            className="rdg-light"
          />
        </div>
      </Container>
    </Page>
  );
};

export default withAccess(manageSkillsPermission, CoursePrioritiesPage);
