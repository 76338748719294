import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Nav,
  INavLinkGroup,
  INavLink,
  Panel,
  PanelType,
} from '@fluentui/react';
import NavHeader from '../NavHeader';
import AppRoutes from './AppRoutes';
import { useCurrentUser } from '../../providers/CurrentUserProvider';
import { getNavigationLinks } from '../utils/NavigationHelper';
import {
  financialPermissions,
  manageCoursesPermission,
  manageSkillsPermission,
} from '../../enums/permissions';

const WebContainer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { currentUser, canViewOpportunity, canViewTimesheet } =
    useCurrentUser();

  const canViewFinancialAll = currentUser?.access_rules?.some(
    (r) => r.role === financialPermissions.editAll
  );
  const canManageSkills = currentUser?.access_rules?.some(
    (r) => r.role === manageSkillsPermission
  );
  const canManageCourses = currentUser?.access_rules?.some(
    (r) => r.role === manageCoursesPermission
  );

  const [navGroups, setNavGroups] = useState<INavLinkGroup[]>([
    {
      links: [],
    },
  ]);
  const [showNavPanel, setShowNavPanel] = useState(false);
  const [selectedNav, setSelectedNav] = useState<string | undefined>('');

  const onNavigate = useCallback(
    (e?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
      e?.preventDefault();
      if (item) {
        const searchParams = new URLSearchParams(location?.search);
        const themeParam = searchParams.get('theme');
        const url = themeParam ? `${item.url}?theme=${themeParam}` : item.url;
        history.push(url);
        setSelectedNav(item.key);
        setShowNavPanel(false);
      }
    },
    [history, setShowNavPanel, location?.search]
  );

  useEffect(() => {
    setSelectedNav(location.pathname.replace('/', ''));
  }, [location]);

  useEffect(() => {
    const links = getNavigationLinks({
      canViewTimesheet,
      canViewOpportunity,
      canViewFinancialAll,
      canManageSkills,
      canManageCourses,
    });
    setNavGroups([{ links }]);
  }, [
    canViewFinancialAll,
    canViewOpportunity,
    canViewTimesheet,
    canManageSkills,
    canManageCourses,
  ]);

  return (
    <div className="da-main">
      <Panel
        isLightDismiss
        isOpen={showNavPanel}
        onDismiss={() => setShowNavPanel(false)}
        closeButtonAriaLabel="Close"
        type={PanelType.smallFixedNear}
      >
        <Nav groups={navGroups} onLinkClick={onNavigate} />
      </Panel>
      <NavHeader onMenuClick={setShowNavPanel} />
      <main>
        <div className="da-sidebar">
          <Nav
            selectedKey={selectedNav}
            groups={navGroups}
            onLinkClick={onNavigate}
          />
        </div>
        <AppRoutes />
      </main>
    </div>
  );
};

export default WebContainer;
