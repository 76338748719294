import { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ICreateResult, IDeleteResult, IMutateOption, IQueryResult, IUpdateResult } from '../types/IQueryResult';
import { FEEDBACKS_KEYS } from '../constants/queries';
import { getFeedbacks } from '../resources/getFeedbacks';
import { getFeedback } from '../resources/getFeedback';
import { IFeedback, IFeedbackView } from '../types/IFeedback';
import { createFeedback } from '../resources/createFeedback';
import { updateFeedback } from '../resources/updateFeedback';
import { deleteFeedback } from '../resources/deleteFeedback';

import { FeedbackStatus } from '../enums/feedbackStatus';
import { useMembers } from './useMembers';



export function useFeedbackList(): IQueryResult & {
  feedbacks: IFeedback[] | undefined;
} {
  const {
    members = [],
    isLoading: membersLoading,
    error: membersError,
  } = useMembers(false);

  const {
    data,
    isLoading: feedbackLoading,
    error: feedbackError,
  } = useQuery<IFeedback[], Error>(FEEDBACKS_KEYS.getAll(), () =>
    getFeedbacks()
  );

  const isLoading = useMemo(
    () => membersLoading || feedbackLoading,
    [membersLoading, feedbackLoading]
  );

  const error = useMemo(
    () => membersError || feedbackError,
    [membersError, feedbackError]
  );

  const getInitials = (name: string) => {
    return name
      .replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      ?.join('');
  };

  const feedbacks = useMemo(() => {
    if (!data) return [];
    return data.map((item: IFeedback) => {
      let receipientFullName = '';
      // let accountManagerInitals = '';
      for (var k = 0; k < members.length; k++) {
        if (+item.recipient === members[k].id) {
          receipientFullName = members[k].name;
          // let initials = getInitials(members[k].name);
          // if (initials) {
          //   accountManagerInitals = initials;
          // }
        }
      }

      let providerFullName = '';
      // let accountManagerInitals = '';
      for (var k = 0; k < members.length; k++) {
        if (+item.provider === members[k].id) {
          providerFullName = members[k].name;
          // let initials = getInitials(members[k].name);
          // if (initials) {
          //   accountManagerInitals = initials;
          // }
        }
      }

      let requesterFullName = '';
      // let accountManagerInitals = '';
      for (var k = 0; k < members.length; k++) {
        if (+item.requester === members[k].id) {
          requesterFullName = members[k].name;
          // let initials = getInitials(members[k].name);
          // if (initials) {
          //   accountManagerInitals = initials;
          // }
        }
      }

      const status = FeedbackStatus[item.status].label;

      // const solutionNames: string[] = [];
      // const solutionFullNames: string[] = [];
      // item.solution.forEach((item) => {
      //   const name = solutionsInitals[parseInt(item, 10)];
      //   const fullName = solutions[parseInt(item, 10)];
      //   solutionFullNames.push(fullName);
      //   solutionNames.push(name);
      // });

      // const businessUnitName = businessUnitInitals[item.business_unit];
      // const businessUnitFullName = businessUnit[item.business_unit];
      // const industryName = client?.industry_id
      //   ? ClientIndustry[client.industry_id]?.shortName
      //   : '';
      // const industryFullname = client?.industry_id
      //   ? ClientIndustry[client.industry_id]?.label
      //   : '';

      return {
        ...item,
        recipient_name: receipientFullName,
        provider_name: providerFullName,
        requester_name: requesterFullName,
        status_name: status
      } as unknown as IFeedbackView;
    });
  }, [members, data]);

  return {
    feedbacks,
    isLoading,
    error,
  };
}


export const getFeedbacksContextualMenu = () => ({
  checkboxFilters: {},
  filters: {},
  sortStack: ['when'],
  menuProps: undefined,
});

export function useFeedbackSingle(id: number): IQueryResult & {
  feedback:  IFeedback | IFeedbackView;

} {
  const {
    data: feedback,
    isLoading,
    error,
  } = useQuery(
    FEEDBACKS_KEYS.getSingle(id?.toString()),
    () => getFeedback(id.toString()!),
    {
      enabled: !!id,
    }
  );
  return { feedback, isLoading, error };
}

export function useCreateFeedback(
  opts: IMutateOption<IFeedback>
): ICreateResult<{ payload: IFeedback }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { payload: IFeedback }) =>
      createFeedback(data.payload),
    {
      onSuccess: (data: IFeedback) => {
        queryClient.invalidateQueries(FEEDBACKS_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );
  return { create: mutate, isLoading, error };
}

export function useUpdateFeedback(
  opts?: IMutateOption<IFeedback>
): IUpdateResult<{ feedbackId: string; payload: IFeedback }> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    (data: { feedbackId: string; payload: IFeedback }) =>
      updateFeedback(data.feedbackId, data.payload),
    {
      onSuccess: (data: IFeedback) => {
        queryClient.invalidateQueries(
          FEEDBACKS_KEYS.getSingle(data.id.toString())
        );
        queryClient.invalidateQueries(FEEDBACKS_KEYS.getAll());
        if (opts?.onSuccess) opts.onSuccess(data);
      },
      onError: opts?.onError,
    }
  );

  return { update: mutate, isLoading, error };
}

export function useDeleteFeedback(opts: IMutateOption<IFeedback>): IDeleteResult<string> {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(deleteFeedback, {
    onSuccess: (data: IFeedback) => {
      queryClient.removeQueries(
        FEEDBACKS_KEYS.getSingle(data?.id?.toString())
      );
      queryClient.invalidateQueries(FEEDBACKS_KEYS.getAll());
      if (opts?.onSuccess) opts.onSuccess(data);
    },
    onError: opts?.onError,
  });

  return { delete: mutate, isLoading, error };
}