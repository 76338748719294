import { apiEndpoint } from './apiEndpoint';
import { standardHeaders } from './standardHeaders';
import { IClient } from '../types/IClient';

export async function createClient(client: Omit<IClient, 'id'>) {
	const response = await fetch(`${apiEndpoint}/clients`, {
		method: 'POST',
		headers: await standardHeaders(),
		mode: 'cors',
		body: JSON.stringify(client)
	});
	if (response.ok) {
		return await response.json();
	}
	return null;
}